import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import CarouselIconLeft from '../assets/img/svg/arrows/Carousel_Icon_Left.svg';
import CarouselIconRight from '../assets/img/svg/arrows/Carousel_Icon_Right.svg';

const Wrapper = styled.div`
  // Pad bottom for absolutely positioned dots
  padding-bottom: ${props => props.theme.spacing(6)}px;

  //Left and right arrows
  .slick-prev,
  .slick-next {
    width: 32px;
    height: 32px;
    opacity: 1;
  }

  .slick-prev  {

    background: url('${CarouselIconLeft}') no-repeat !important;
    // Position left width of arrow + spacing
    left: -48px;

    &:before {
      display: none;
   }
  }

  .slick-next {
    background: url('${CarouselIconRight}') no-repeat !important;
    right: -48px;

    &:before {
      display: none;
    }
  }

  // All styling for dots
  .slick-dots {
    text-align: left;
    position: relative;
    bottom: 0;
    margin-top: ${props => props.theme.spacing(3)}px;

    ${(props): any => `
      ${props.theme.breakpoints.up('md')} {
        text-align: center;
      }
    `};

    li {
      margin-left: 0;
      margin-right: ${props => props.theme.spacing(1)}px;

      &:last-child {
        margin-right: 0;
      }

      button {
        // For all dots
        &:before {
          font-size: 8px;
          color: ${props => props.theme.palette.grey[300]};
          opacity: 1;
        }
      }
    }

    // For active dot
    li.slick-active {
      button {
        &:before {
          color: ${props => props.theme.palette.semantic.colorPrimary};
          opacity: 1;
        }
      }
    }
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
  }

  .slick-initialized .slick-slide {
    margin-right: ${props => props.theme.spacing(2)}px;

    ${(props): any => `
      ${props.theme.breakpoints.up('md')} {
        margin-right: ${props.theme.spacing(3)}px;
      }
    `};
  }

  .slick-slide div {
    height: 100%;
  }
`;

type SliderProps = {
  settings: { [key: string]: any };
  children?: any;
};
const CustomerPortalSlider = (props: SliderProps) => (
  <Wrapper>
    <Slider {...props.settings}>{props.children}</Slider>
  </Wrapper>
);

export default CustomerPortalSlider;
