import {
  CaseRecordType,
  SUPPORTED_BASIC_CASE_TYPES,
} from '@customer-portal/constants';

export const AccountType = {
  UNKNOWN: '',
  STANDARD: 'Standard',
  PREMIUM: 'Premium',
  PREMIUM_PLUS: 'Premium Plus',
  PREMIUM_SUPPORT: 'Premium Support',
  ACTIVATE: 'Activate',
  ENTERPRISE: 'Enterprise',
};

export const KBVisibilityControls = {
  all_accounts: 'All Accounts',
  success_tier: 'Success Tier Accounts',
  activate: 'Success Tier - Activate Only',
};

export const isBasicCaseType = (recordTypeId?: CaseRecordType) => recordTypeId && SUPPORTED_BASIC_CASE_TYPES.includes(recordTypeId);
export const isPremiumCaseType = (recordTypeId?: CaseRecordType) => recordTypeId === CaseRecordType.PREMIUM;

export const AllowedHapoAccounts = [
  '0011Q00002BkScFQAV',
  '00136000016Ts3vAAC',
  '0011Q00002HCj6oQAD',
];

export const PREMIUM_ACCOUNT_TYPES = [
  AccountType.PREMIUM,
  AccountType.PREMIUM_PLUS,
  AccountType.PREMIUM_SUPPORT,
  AccountType.ACTIVATE,
  AccountType.ENTERPRISE,
];

export const PREMIUM_ACCOUNT_TYPE_TO_MAIN_MENU_TITLE: {
  [accountType: string]: {
    textKey: string;
    fallbackText: string;
  };
} = {
  [AccountType.PREMIUM]: {
    textKey: 'header_menu_premium_account_premium',
    fallbackText: 'Premium',
  },
  [AccountType.PREMIUM_PLUS]: {
    textKey: 'header_menu_premium_account_premium_plus',
    fallbackText: 'Premium Plus',
  },
  [AccountType.PREMIUM_SUPPORT]: {
    textKey: 'header_menu_premium_account_premium_support',
    fallbackText: 'Premium',
  },
  [AccountType.ACTIVATE]: {
    textKey: 'header_menu_premium_account_activate_success',
    fallbackText: 'Activate Success',
  },
  [AccountType.ENTERPRISE]: {
    textKey: 'header_menu_premium_account_enterprise_success',
    fallbackText: 'Enterprise Success',
  },
};
