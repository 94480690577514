import styled from 'styled-components';

export const Header = styled.div`
  margin-bottom: ${p => p.theme.spacing(4.5)}px;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
`;

export const Container = styled.div`
  margin: 0 auto;
  width: 88%;

  .Announcement__icon {
    width: 80px;
    height: 80px;
  }

  .Announcement__content {
    max-height: 62vh;
    overflow-y:auto;
    padding: 16px 18px 16px 18px;
    border-color: ${p => p.theme.palette.semantic.colorBorder};
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-radius: 6px;
    color: ${p => p.theme.palette.text.primary};

    /* Shadow when not-fully-scrolled to signify to user that the section is scrollable */
    background:
      linear-gradient(
        white 30%,
        ${p => p.theme.palette.semantic.colorBackgroundSecondary}
      ) center top,
      linear-gradient(
        ${p => p.theme.palette.semantic.colorBackgroundSecondary}
        white 70%
      ) center bottom,
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ) center top,
      radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      ) center bottom;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;

    a {
      color: ${p => p.theme.palette.semantic.colorPrimary};
    }
  }
  
  .Announcement__button_row {
    display: flex;
    justify-content: space-between;

    margin-top: ${p => p.theme.spacing(2)}px;

    button {
      display: inline-block;
      margin-top: ${p => p.theme.spacing(2)}px;
      height: ${p => p.theme.spacing(5)}px;
    }

    .Announcement__button--acknowledge {
      background-color: ${p => p.theme.palette.semantic.colorPrimary};
      color: ${p => p.theme.palette.semantic.colorBackground};
    }
  }
`;
