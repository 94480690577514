import styled from 'styled-components';

export const CustomHelpIcon = styled.span`
  .help-icon {
    outline: none;
    margin: 8px;
    padding-right: 16px;
  }
`;

export const HelpTextDiv = styled.span`
  padding: 8px 12px; 
  display: inline-block;
  position: absolute;
  width: 315px;
  z-index: 100;
  border-radius: 8px;
  background-color: ${p => p.theme.palette.semantic.colorBackgroundRaised};
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); 
  filter: drop-shadow(0px 0px 1px grey); 
  outline: none;

  &::before {
    content: "";
    position: absolute;
    height: 25px;
    width: 15px;
    background: inherit;
    top: 8px;
    right: 100%;
    clip-path: polygon(100% 0, 100% 100%,0% 50%);
    transform: translateX(2px)
  }

  .text {
    color: ${p => p.theme.palette.text.primary};
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
  }
`;
