import TableContainer from '@mui/material/TableContainer';
import styled from 'styled-components';

export const NewCPTableContainer = styled(TableContainer)`
  font-family: ${p => p.theme.typography.fontFamily};
  background: ${p => p.theme.palette.semantic.colorBackground};
  margin-bottom: 2rem;
  .MuiTableCell-body,
  .MuiTableCell-head {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7;
  }
  .MuiTableCell-head {
    color: ${p => p.theme.palette.text.secondary};
    background-color: ${p => p.theme.palette.semantic.colorBackgroundSecondary};
    line-height: 1.5rem;
  }
  .MuiTableCell-body {
    color: ${props => props.theme.palette.text.primary};
  }
  .MuiTableCell-root {
    border-bottom: 1px solid ${p => p.theme.palette.semantic.colorBorder};
  }
  .heading .MuiTableCell-head {
    font-weight: 600 !important;
  }
`;
