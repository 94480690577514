import styled, { css } from 'styled-components';

export const ContentWrapper = css`
  min-height: calc(100vh - 48px);
  padding-bottom: 48px;
  background: ${p => p.theme.palette.semantic.colorBackground};
`;

export const SectionContentWrapper = styled.section`
  ${ContentWrapper}
`;
