import React from 'react';

import CustomerPortalLanding from '../../components/CustomerPortal-Landing';
import { WebUnregisteredLandingPage } from '../../constants/support.constants';

const CustomerPortalUnregisteredLandingPage = (props: any) => (<CustomerPortalLanding
  text={WebUnregisteredLandingPage}
  isPublicLandingPage={false}
/>
);

export default CustomerPortalUnregisteredLandingPage;
