import TableContainer from '@mui/material/TableContainer';
import styled from 'styled-components';

export const CPTableContainer = styled(TableContainer)`
  font-family: ${p => p.theme.typography.fontFamily};
  background: ${p => p.theme.palette.semantic.colorBackground};
  -webkit-box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1) !important;
  -moz-box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1) !important;
  box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1) !important;
  .MuiTableCell-body,
  .MuiTableCell-head {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7;
  }
  .MuiTableCell-head {
    color: ${props => props.theme.palette.text.secondary};
  }
  .MuiTableCell-body {
    color: ${props => props.theme.palette.text.primary};
  }
  .MuiTableCell-root {
    border-color: ${props => props.theme.palette.semantic.colorBorder};
  }

  .MuiTableRow-root:hover {
    background-color: ${props => props.theme.palette.semantic.colorBackgroundHover};
  }

  a {
    color: ${props => props.theme.palette.semantic.colorPrimary};
  }
`;
