import React from 'react';

import * as styles from '../../../assets/css/HAPO/RequestHistory/OrderStatus';
import {
  CANCELED,
  DISPLAY_STATUSES,
  PENDING,
  PROVISIONED,
} from '../../../constants/hapo.constants';
import ApolloIcon from '../../ApolloIcon';
import { UserPermissionsHelper } from './../../../lib/UserPermissions';

const OrderStatus = (props: any) => {
  const orderId = props.orderId;
  const orderStatus = props.status;
  const isCancelHAPOOrderAllowed = UserPermissionsHelper.isCancelHAPOOrderAllowed();
  const isCompleteHAPOOrderAllowed = UserPermissionsHelper.isCompleteHAPOOrderAllowed();

  /**
   * There are only two options atm. Do not show the kebab if no options to choose from
   */
  let displayMenu = false;
  if ((orderStatus === PENDING && isCancelHAPOOrderAllowed) ||
    (orderStatus === PENDING && isCompleteHAPOOrderAllowed) ||
    (orderStatus === PROVISIONED && isCancelHAPOOrderAllowed) ||
    ((orderStatus === PENDING || orderStatus === PROVISIONED) && isCompleteHAPOOrderAllowed)
  ) {
    displayMenu = true;
  }

  const generateOrderStatus = () => {
    switch (orderStatus) {
      case PENDING:
        return (
          <>
            <span
              className="RequestHistoryListLabel RequestHistoryListLabel--inProgress"
              data-testid="Order_Status">
              {DISPLAY_STATUSES.pending}
            </span>
            {displayMenu && (
              <styles.MenuOpener
                data-testid="HapoOrderStatusMenuButton"
                onClick={(e: any) => {
                  props.handleAttributeParentClick(
                    e.target,
                    orderId,
                    orderStatus
                  );
                }}
              >
                <ApolloIcon
                  icon='more_vert'
                  fontSize='inherit'
                />
              </styles.MenuOpener>
            )}
          </>
        );

      case PROVISIONED:
        return (
          <>
            <span
              className="RequestHistoryListLabel RequestHistoryListLabel--provisioned"
              data-testid="Order_Status">
              {DISPLAY_STATUSES.provisioned}
            </span>
            {displayMenu && (
              <styles.MenuOpener
                data-testid="HapoOrderStatusMenuButton"
                className="MenuOpener"
                onClick={(e: any) => {
                  props.handleAttributeParentClick(
                    e.target,
                    orderId,
                    orderStatus
                  );
                }}
              >
                <ApolloIcon
                  icon='more_vert'
                  fontSize='inherit'
                />
              </styles.MenuOpener>
            )}
          </>
        );

      case CANCELED:
        return (
          <span
            className="RequestHistoryListLabel RequestHistoryListLabel--cancelled"
            data-testid="Order_Status">
            {DISPLAY_STATUSES.canceled}
          </span>
        );

      default:
        return (
          <span
            className="RequestHistoryListLabel RequestHistoryListLabel--cancelled"
            data-testid="Order_Status">
            Not Defined
          </span>
        );
    }
  };

  return (
    <styles.OrderStatus
      className="RequestHistoryListItemWrapper RequestHistoryListItemWrapper--requestStatus"
      data-testid="HapoOrderStatus_wrapper"
    >
      {generateOrderStatus()}
    </styles.OrderStatus>
  );
};

export default OrderStatus;
