export enum EntityType {
  SYSTEM = 'system',
  USER = 'user',
}

/**
 * Allow unicode characters in the range U+2E80 to U+9FFF and
 * allow whitespace, underscore, and hyphen between words
 */
export const ENTITY_NAME_REGEX = /^[\w\u00C0-\uFFFF\u2E80-\u9FFF\s_-]+$/;
export const ENTITY_NAME_MAX_LENGTH = 100;

export const UNASSIGNED_ENTITY_NAME = 'Unassigned';
