import { Tooltip } from '@mui/material';
import React from 'react';
// Translations
import { useTranslation } from 'react-i18next';

import * as styles from '../../../assets/css/Select';
import InfoIcon from '../../../assets/img/svg/status_icons/info_gray.svg';
import { PM_DEVELOPER } from '../../../constants/hapo.constants';

const ProductNameTooltip = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');
  const licenseName = props.licenseName ? props.licenseName : '';
  const licenseCode = props.licenseCode ? props.licenseCode : '';
  const productName = props.productName ? props.productName : '';
  const entityName = props.entityName ?? '';
  const pm_developer =
    props.consumedAgainst === PM_DEVELOPER ? props.consumedAgainst : '';

  const customPMDeveloperStyles = {
    marginTop: '8px',
    paddingTop: '8px',
    display: 'block',
    borderTop: '1px solid #f7f7f7',
    fontStyle: 'italic',
  };

  return (
    <Tooltip
      placement="bottom-start"
      title={
        <styles.PermissionsToolTipTitleContainer>
          <p>
            {t(
              'past_requests_hapo_product_tooltip_license_name',
              'License Name'
            )}
            :
            {' '}
            {licenseName}
          </p>
          <p>
            {t(
              'past_requests_hapo_product_tooltip_license_code',
              'License code'
            )}
            :
            {' '}
            {licenseCode}
          </p>
          {entityName && (
            <p>
              {t(
                'past_requests_hapo_product_tooltip_entity_name',
                'Entity that new license was applied to'
              )}
              :
              {' '}
              {entityName}
            </p>
          )}
          {pm_developer && (
            <p style={customPMDeveloperStyles}>
              {t(
                'past_requests_hapo_product_tooltip_pm_disclaimer',
                'This license is included with the Process Mining Developer User SKU'
              )}
            </p>
          )}
        </styles.PermissionsToolTipTitleContainer>
      }
    >
      <div className="RequestHistoryListItem__tooltip">
        <span>{productName}</span>
        <img
          src={InfoIcon}
          alt="React Logo" />
      </div>
    </Tooltip>
  );
};

export default ProductNameTooltip;
