import { PortalLoader } from '@uipath/portal-shell-react';
import React from 'react';
import styled from 'styled-components';

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.palette.semantic.colorBackground};

  .portal-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    alignitems: center;
  }
`;

const AppLoader = () => (
  <LoaderContainer>
    <PortalLoader
      className='portal-loader'
      size='70px' />
  </LoaderContainer>
);

export default AppLoader;
