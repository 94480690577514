import styled from 'styled-components';

export const GroupedLicense = styled.div`
  width: 100%;
  min-height: 80px;
  border-radius: ${p => p.theme.spacing(1)}px;
  background: ${p => p.theme.palette.semantic.colorBackground};
  border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
  align-items: center;
  margin-bottom: ${p => p.theme.spacing(2)}px;
  padding: ${p => p.theme.spacing(1)}px 0px ${p => p.theme.spacing(1)}px;
  box-sizing: border-box;
  &:last-child {
    margin-bottom: 0;
  }

  p {
    color: ${p => p.theme.palette.text.primary};
    font-size: 1.4rem;
    line-height: 20px;
  }

  .GroupedLicense__Header,
  .GroupedLicense__IndividualLicense {
    display: flex;
    flex-direction: row;
    padding: ${p => p.theme.spacing(1)}px 40px ${p => p.theme.spacing(1)}px;
  }

  .GroupedLicense__Header_Separator {
    border-top: 1px solid #CFD8DD;
  }

  .GroupedLicense__Header_Title {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.4rem;
  }

  .GroupedLicense__Name,
  .GroupedLicense__Header_Title {
    flex: 5;
  }

  .GroupedLicense__EmptyColum_3 {
    flex: 3;
  }
  
  .GroupedLicense__Progress {
    flex: 2;
  }

  .GroupedLicense__Stats, .GroupedLicense__EmptyColum_1 {
    flex: 1;
    text-align: center;
  }
`;
