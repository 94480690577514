import React from 'react';
import styled from 'styled-components';

import { CustomButton } from '../Button/Button';

const PremiumCallButton = styled(CustomButton).attrs(props => ({ disabled: props.disabled }))`
  background-color: ${p =>
    p.disabled ? p.theme.palette.semantic.colorBackgroundDisabled : p.theme.palette.semantic.colorBackground};
  color: ${p =>
    p.disabled ? p.theme.palette.text.disabled : p.theme.palette.semantic.colorPrimary};
  border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
  cursor: pointer;
  border-radius: 8px;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 8px 16px;
  outline: none;
  min-width: 120px;
  pointer-events: ${p => (p.disabled ? 'none' : 'auto')};

  .icon {
    font-size: 1.4rem;
    margin-right: ${p => p.theme.spacing(1)}px;
    transform: translateY(2px);
    color: ${p => p.theme.palette.semantic.colorPrimary};
  }
`;

type PremiumSupportButtonProps = {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  [key: string]: any;
};
const PremiumSupportButton = (props: PremiumSupportButtonProps) => {
  const {
    onClick: handleOnClick,
    className,
    disabled = true,
    children,
  } = props;
  return (
    <PremiumCallButton
      onClick={handleOnClick}
      className={className}
      disabled={disabled}
    >
      {children}
    </PremiumCallButton>
  );
};

export default PremiumSupportButton;
