import styled from 'styled-components';

export const CustomActionPanel = styled.span`
  display: flex;
  flex-direction: column;
  min-height: 90vh;
  background: ${p => p.theme.palette.semantic.colorBackground};

  .title {
    font-weight: 600;
    font-size: 18px;
  }

  .button-container {
    margin-top: auto;
  }

  .button {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  .cancelBtn, .submitBtn, .loaderBtn {
    width: 80px;
  }

  .submitBtn {
    background-color: ${p => p.theme.palette.semantic.colorPrimary};
    color: ${p => p.theme.palette.semantic.colorBackgroundSecondary};
  }

  .submitBtn:focus {
    box-shadow: none !important;
  }

  .loaderBtn:focus {
    box-shadow: none !important;
  }

  .cancelBtn {
    margin-right: 10px
  }

  .loader {
    color: ${p => p.theme.palette.text.primary}
  }

  .CaseActions__Case-Field {
    width: 100%;
    margin-bottom: 10px;
  }

  .selectReason {
    padding: 10px;
  }

  .options {
    padding: 0px;
  }

  .businessJustification textarea{
    color: ${p => p.theme.palette.text.primary};
    line-height: 20px;
    height: 200px !important;
  }

  .Custom-Select_Reason {
    background: none;
    border-radius: 3px;
    .Custom-Select__control {
      background: ${p => p.theme.palette.semantic.colorBackground};
    }
    .Custom-Select__placeholder {
      color: ${p => p.theme.palette.text.primary};
    }
  }

`;
