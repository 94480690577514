import Alert from '@mui/lab/Alert';
import Snackbar from '@mui/material/Snackbar';
import styled from 'styled-components';

import { transitionStandard } from '../js_mixins/transitions';

export const CustomSnackbar = styled(Snackbar)`
`;

export const CustomAlert = styled(Alert)`
  height: auto;

  &.AlertWithActions {
    padding-top: 0;
    padding-bottom: 0;
    background-color: ${p => p.theme.palette.common.black};
    color: ${p => p.theme.palette.common.white};
    font-size: 14px;

    .MuiAlert-action {
      margin-top: 0;
      height: auto;
    }

    .MuiAlert-message {
      margin-top: auto;
      margin-bottom: auto;
      max-width: 450px;
    }

    .icon {
      margin-top: auto;
      margin-bottom: auto;
      font-size: 28px;
    }
  }

  .ActionButtons {
    border-left: 1px solid ${p => p.theme.palette.divider};

    button {
      display: block;
      padding: 14px 10px;
      width: 100%;

      color: ${p => p.theme.palette.common.white};
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      text-align: center;

      border: none;
      border-bottom: 1px solid ${p => p.theme.palette.divider};
      background: transparent;

      cursor: pointer;
      outline: none;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        color: ${p => p.theme.palette.primary.main};
      }
    }
  }
`;

export const SuccessOrErrorAlert = styled.div`
  .alert {
    color: ${p => p.theme.palette.ink[100]};
    background-color: ${p => p.theme.palette.common.black};
    font-size: 14px;
  }

  .icon {
    margin-top: auto;
    margin-bottom: auto;
    font-size: 28px;
  }
`;

export const NotificationBanner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${p => p.theme.spacing(2)}px;
  align-items: center;
  position: absolute;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
  transform: translateY(10px);
  opacity: 0.5;
  ${transitionStandard('all')};
  .NotificationBanner__Close {
    width: ${p => p.theme.spacing(2)}px;
    height: ${p => p.theme.spacing(2)}px;
  }
  &.Open {
    opacity: 1;
    transform: translateY(0);
  }
  &.NotificationBanner__Error {
    background: ${p => p.theme.palette.red[200]};
  }
  &.NotificationBanner__Info {
    background: ${p => p.theme.palette.light_blue[200]};
  }
  &.NotificationBanner__Success {
    background: ${p => p.theme.palette.green[200]};
  }
`;
