import styled from 'styled-components';

export const OrchestratorProducts = styled.section`
  .RequestForQuote__BrowseProducts__Title {
    color: ${p => p.theme.palette.text.primary};
    padding-top: 32px;

    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .RequestForQuote__BrowseProducts__Search {
    width: 240px;
    margin-top: 16px;

    display: flex;
    align-items: center;

    background-color: ${p => p.theme.palette.semantic.colorBackground};
    border: 1px solid ${p => p.theme.palette.ink[400]};
    box-sizing: border-box;
    border-radius: 2px;

    svg {
      flex-basis: 14px;
      flex-shrink: 0;
      padding-left: 8px;

      width: 14px;
      height: 16px;
    }

    .RequestForQuote__BrowseProducts__clearSearch {
      flex-basis: 10px;
      width: 10px;

      visibility: hidden;
      opacity: 0;
      padding-right: 8px;
      transition: all 0.3s ease;
      cursor: pointer;

      &--active {
        visibility: visible;
        opacity: 1;

        transition: all 0.3s ease;
      }
    }

    input {
      background-color: ${p => p.theme.palette.semantic.colorBackground};
      overflow: hidden;

      padding: 8px;

      color: ${p => p.theme.palette.text.primary};
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;

      border: none;
      outline: none;
    }
  }

  ${'' /* Tabs */}
  .RequestForQuote__BrowseProducts__Tabs {
    margin-top: 16px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    .RequestForQuote__BrowseProducts__NoTab {
      padding: 16px 0 50px 0;

      p {
        color: ${p => p.theme.palette.text.primary};

        font-size: 16px;
        font-weight: normal;
        line-height: 24px;

        em {
          font-weight: 800;
        }
      }
    }

    .RequestForQuote__BrowseProducts__Tab {
      position: relative;
      cursor: pointer;

      width: 120px;
      text-align: center;

      p {
        padding-bottom: 12px;

        color: ${p => p.theme.palette.text.secondary};

        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }

      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;

        background: transparent;

        width: 100%;
        height: 3px;
      }

      &--active {
        p {
          color: #0067df;
        }

        &:before {
          content: '';
          background: #0067df;

          position: absolute;
          bottom: 0;
          left: 0;

          width: 100%;
          height: 3px;
        }
      }
    }
  }

  ${'' /* Product List */}
  .RequestForQuote__BrowseProducts__List {
    margin-top: 16px;

    .RequestForQuote__BrowseProducts__ProductCategory {
      &--hide {
        display: none;
      }

      p {
        color: ${p => p.theme.palette.text.secondary};
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        text-transform: uppercase;
      }
    }

    .RequestForQuote__BrowseProducts__Products {
      margin-top: 16px;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;

      &--hide {
        display: none;
      }

      .RequestForQuote__BrowseProducts__NotFound {
        margin-bottom: 16px;

        font-size: 16px;
        font-weight: normal;
        line-height: 24px;

        em {
          font-weight: 800;
        }
      }

      .RequestForQuote__BrowseProducts__Product {
        position: relative;
        cursor: pointer;

        margin-bottom: 16px;

        flex: 0 0 auto;

        width: 100%;
        height: 200px;
        overflow-y: hidden;

        background-color: ${p => p.theme.palette.semantic.colorBackground};
        border: 1px solid #cfd8dd;
        box-sizing: border-box;
        border-radius: 3px;

        display: flex;
        flex-direction: column;

        &:hover {
          .RequestForQuote__BrowseProducts__ProductTitle {
            color: #0067df;
          }
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;

          background-color: transparent;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;

          width: 100%;
          height: 4px;
        }

        &--active {
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;

            background-color: #fa4616;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;

            width: 100%;
            height: 4px;
          }
        }

        .RequestForQuote__BrowseProducts__ProductTitle {
          padding: 16px 16px 8px 16px;
          color: ${p => p.theme.palette.text.primary};
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }

        .RequestForQuote__BrowseProducts__AddToCart {
          flex-shrink: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          background-color: ${p => p.theme.palette.semantic.colorBackgroundSecondary};

          .RequestForQuote__BrowseProducts__AvailableQty {
            margin-left: ${p => p.theme.spacing(3)}px;
            font-size: 1.4rem;
            color: ${p => p.theme.palette.text.secondary};
          }

          .RequestForQuote__BrowseProducts__AddToCartIcon {
            padding: 16px;

            width: 14px;
            height: 14px;

            fill: #0067df;

            cursor: pointer;

            &--disabled {
              fill: #e3e3e3;
            }
          }

          .RequestForQuote__BrowseProducts__CartProductOptions {
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-right: 0;
          }

          .RequestForQuote__BrowseProducts__QuantityLabel {
            color: ${p => p.theme.palette.text.secondary};
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
          }

          .RequestForQuote__BrowseProducts__QuantityInput {
            padding: 6px 0;
            margin: 0 10px;
            width: 74px;

            outline: none;
          }

          .RequestForQuote__BrowseProducts__CloseQuantityInput {
            padding: 10px 10px 10px 10px;
            margin: -10px 0 -10px -10px;
          }
        }

        @media screen and (min-width: 480px) {
          width: calc(50% - 8px);

          &:not(:nth-child(odd)) {
            margin-left: 16px;
          }
        }

        @media screen and (min-width: 768px) {
          width: calc(50% - 8px);

          &:not(:nth-child(odd)) {
            margin-left: 16px;
          }
        }

        @media screen and (min-width: 1024px) {
          width: calc(33% - 12px);

          &:not(:nth-child(odd)) {
            margin-left: 0;
          }

          &:not(:nth-child(3n + 1)) {
            margin-left: 16px;
          }
        }

        ${'' /* Product Item Modifier */}
        &--horizontal {
          padding: 16px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          height: auto;

          &--active {
            &:before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;

              background-color: #fa4616;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;

              width: 4px;
              height: 100%;
            }

            .RequestForQuote__BrowseProducts__AddToCart {
              display: flex;
              flex-direction: column-reverse;
              align-items: flex-end;

              .RequestForQuote__BrowseProducts__AvailableQty {
                margin-left: unset;
                margin-top: ${p => p.theme.spacing(0.5)}px;
              }
            }
          }

          .RequestForQuote__BrowseProducts__ProductTitle {
            padding-top: 0;
            padding-bottom: 0;
          }

          .RequestForQuote__BrowseProducts__AddToCart {
            position: relative;

            background-color: ${p => p.theme.palette.semantic.colorBackground};

            .RequestForQuote__BrowseProducts__AddToCartIcon {
              padding: 16px 0 16px 16px;
            }

            .RequestForQuote__BrowseProducts__CloseQuantityInput {
              padding: 0;
              margin: 0;
            }
          }

          @media screen and (min-width: 768px) {
            &:not(:nth-child(odd)) {
              margin-left: 16px !important;
            }

            width: calc(50% - 8px);

            &:not(:nth-child(3n + 1)) {
              margin-left: 0;
            }

            &:not(:nth-child(4n + 1)) {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
`;
