import { TextField } from '@mui/material';
import styled from 'styled-components';

// Images
import { ContentWrapper } from '../CustomerPortalContentWrapper';

export const PageWrapper = styled.div`
  ${ContentWrapper}

  .CustomerPortalCollab__Upload-Container {
    position: relative;
    top: ${p => p.theme.spacing(-9)}px;
    margin-bottom: ${p => p.theme.spacing(-9)}px;

    ${p => p.theme.breakpoints.down('lg')} {
      top: ${p => p.theme.spacing(-5)}px;
      margin-bottom: ${p => p.theme.spacing(-5)}px;
    }

    &.CustomerPortalCollab__Upload-Container--Folder {
      top: ${p => p.theme.spacing(-3)}px;
    margin-bottom: ${p => p.theme.spacing(-3)}px;
    }

    .CustomerPortalCollab__Upload {
      width: 32px;
      height: 32px;
    }

    .CustomerPortalUploadForm {
      display: none;
    }
  }

  .CustomerPortalCollabResults {
    margin-top: ${p => p.theme.spacing(5)}px;

    .CustomerPortalCollabResults__Header {
      font-size: 2.4rem;
      line-height: 1.5;
      font-weight: normal;
      color: ${p => p.theme.palette.text.secondary};
      margin-bottom: 2 ${p => p.theme.spacing(5)}px;
    }

    // Layout of table
    .CustomerPortalCollabResults__Table-Header,
    .CustomerPortalCollabDoc {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 425px) {
        flex-direction: column;
      }
      // ${p => p.theme.breakpoints.down('xs')} {
      //   flex-direction: column;
      // }

      > :nth-child(1) {
        flex-basis: 59.5%;
        max-width: 59.5%;
      }

      > :nth-child(2) {
        flex-basis: 17%;
        max-width: 17%;
      }

      > :nth-child(3) {
        flex-basis: 23.5%;
        max-width: 23.5%;
      }

      & > * {
        @media only screen and (max-width: 425px) {
          width: 100%;
          flex-basis: 100% !important;
          max-width: 100% !important;
        }
      }
    }

    .CustomerPortalCollabResults__Table-Header {
      padding: ${p => p.theme.spacing(1)}px 0;
      margin-bottom: ${p => p.theme.spacing(3)}px;

      @media only screen and (max-width: 425px) {
        display: none;
      }

      .CustomerPortalCollabResults__Table-Label {
        font-size: 1.6rem;
        line-height: 1.25;
        color: #858f95; // TODO: Replace when in Design System
        font-weight: normal;
      }
    }

    .CustomerPortalCollabDoc {
      background: ${p => p.theme.palette.semantic.colorBackground};
      border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
      margin-bottom: ${p => p.theme.spacing(3)}px;
      border-radius: 8px;
      position: relative;

      &--Folder {
        background: ${p => p.theme.palette.semantic.colorBackground};
        border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
      }

      > :nth-child(1),
      > :nth-child(2),
      > :nth-child(3) {
        box-sizing: border-box;
        padding-top: ${p => p.theme.spacing(2)}px;
        padding-bottom: ${p => p.theme.spacing(2)}px;

        @media only screen and (max-width: 425px) {
          padding: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(2)}px;
        }
      }

      > :nth-child(1) {
        padding-left: ${p => p.theme.spacing(2)}px;
      }
      > :nth-child(1),
      > :nth-child(2) {
        padding-right: ${p => p.theme.spacing(3)}px;
      }
      > :nth-child(2) {
        flex: 2;
        max-width: 50%;
      }
    }

    // Styling
    .CustomerPortalCollabDocFile {
      display: flex;
      align-items: center;
      cursor: pointer;

      .CustomerPortalCollabDoc__Icon-Container {
        padding: ${p => p.theme.spacing(1)}px;
        background: ${p => p.theme.palette.semantic.colorBackground};
        border-radius: 8px;
        margin-right: ${p => p.theme.spacing(2)}px;
        color: ${p => p.theme.palette.text.primary};
      }

      .CustomerPortalCollabDoc__Name {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.7;
        color: ${p => p.theme.palette.text.primary};
        font-weight: normal;
        word-break: break-all;
      }
    }

    .CustomerPortalCollabDocAuthor {
      display: flex;
      align-items: center;

      .CustomerPortalCollabDocAuthor__Avatar {
        margin-right: ${p => p.theme.spacing(1)}px;
        border-radius: 50%;
        width: 32px;
        height: 32px;

        &--UiPath {
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            background-color: ${p => p.theme.palette.primary.main};
          }
        }
      }

      .CustomerPortalCollabDocAuthor__Name {
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.7;
        color: ${p => p.theme.palette.text.primary};
        font-weight: normal;
      }
    }

    .CustomerPortalCollabDocDate {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.7;
      color: ${p => p.theme.palette.text.primary};
      font-weight: normal;
      padding-right: ${p => p.theme.spacing(5)}px; // For the options icon
    }

    .CustomerPortalCollabDocOptions {
      border: none;
      outline: none;
      background: inherit;
      cursor: pointer;
      position: absolute;
      right: 0;
      margin-right: ${p => p.theme.spacing(2)}px;
      transform-origin: 50% 50%;
      transform: rotate(90deg);

      @media only screen and (max-width: 425px) {
        width: auto;
        top: calc(50% - 14px);
      }

      i {
        width: 3px;
        height: 2.68px;
        border-radius: 50%;
        display: inline-block;
        margin-right: ${p => p.theme.spacing(0.5)}px;
      }
    }

    .CustomerPortalPagination {
      margin-left: 0;
      padding: ${p => p.theme.spacing(5)}px 0;
    }
  }
`;

interface UploadContainerInnerProps {
  disabled?: boolean;
}

export const UploadContainerInner = styled.div<UploadContainerInnerProps>`
  cursor: pointer;
  padding: 16px;
  display: inline-block;
  border-radius: 50%;
  pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
  opacity: ${p => (p.disabled ? 0.5 : 1)};
  background-color: ${p =>
    p.disabled ? p.theme.palette.grey[300] : p.theme.palette.semantic.colorPrimary};
`;

export const CollabResultsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${p => p.theme.spacing(2)}px;

  ${p => p.theme.breakpoints.down('xs')} {
    flex-direction: column;
  }

  .CustomerPortalCollabResults__clientsActions {
    display: flex;

    ${p => p.theme.breakpoints.down('xs')} {
      margin-top: ${p => p.theme.spacing(4)}px;
      flex-direction: column;
      justify-content: space-between;
    }

    .CustomerPortalClientsSelection__clientsSort {
      margin-right: 16px;
      min-width: 180px;

      ${p => p.theme.breakpoints.down('xs')} {
        margin-right: 0;
        margin-bottom: ${p => p.theme.spacing(2)}px;
      }

      .Custom-Select__control {
        ${p => p.theme.breakpoints.down('xs')} {
          height: 48px;
        }
      }
    }

    .CustomerPortalClientsSelection__clientsSearch {
      padding: ${p => p.theme.spacing(1.25)}px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: ${p => p.theme.palette.semantic.colorBackground};
      border-radius: ${p => p.theme.spacing(1)}px;
      border: 1px solid ${p => p.theme.palette.semantic.colorBorder};

      ${p => p.theme.breakpoints.down('xs')} {
        margin-bottom: ${p => p.theme.spacing(2)}px;
      }
    }

    .add-icon {
      color: ${p => p.theme.palette.semantic.colorBackground};
    }

    .search-icon {
      color: ${p => p.theme.palette.text.primary};
    }

    .CustomerPortalClientsSelection__clientsSearchInput {
      display: none;

      &--Active {
        display: block;
      }

      input {
        background: ${p => p.theme.palette.semantic.colorBackground};
        width: auto;

        font-size: 1.4rem;

        outline: none;
        border: none;

        ${p => p.theme.breakpoints.up('sm')} {
          width: ${p => p.theme.spacing(25)}px;
        }

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: ${p => p.theme.palette.text.primary};
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: ${p => p.theme.palette.text.primary};
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: ${p => p.theme.palette.text.primary};
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: ${p => p.theme.palette.text.primary};
        }
      }
    }
  }
`;

export const CreateFolderButton = styled.button.attrs(p => ({ disabled: p.disabled || false }))`
  font-weight: bold !important;
  font-size: 1.6rem !important;
  line-height: 2rem !important;
  background-color: ${p =>
    p.disabled ? p.theme.palette.semantic.colorBackgroundDisabled : p.theme.palette.semantic.colorPrimary};
  pointer-events: ${p => (p.disabled ? 'none' : 'auto')};
  color: ${p =>
    p.disabled ? p.theme.palette.text.disabled : p.theme.palette.semantic.colorBackground
};
  margin-left: ${p => p.theme.spacing(2)}px;
  cursor: pointer;
  font-weight: bold;
  line-height: 2rem;
  text-align: center;
  border-radius: 8px;
  border: none;
  min-width: 160px;
  padding-bottom: 8px;

  ${p => p.theme.breakpoints.down('xs')} {
    margin-left: 0;
    height: 40px;
  }

  .add-icon {
    margin-right: ${p => p.theme.spacing(1)}px;
    transform: translateY(6px);
  }
`;

export const FormWrapper = styled.form`
  margin-top: ${p => p.theme.spacing(2)}px;

  .NewFolderButton {
    margin-top: ${p => p.theme.spacing(4)}px;
    color: ${p => p.theme.palette.semantic.colorBackground};
  }
`;

export const TextInput = styled(TextField)`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
  border-radius: ${p => p.theme.spacing(1)}px;
  
  .MuiInput-underline {
    &:after {
      display: none;
    }
  }
  .MuiInputBase-root {
    font-size: 1.4rem;
    &:before {
      display: none;
    }
  }
  .MuiFormHelperText-root {
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0.5px;
    color: ${p => p.theme.palette.error.main};
  }
  .MuiInputBase-input {
    font-size: 1.4rem;
    line-height: 2.4rem;
    height: auto;
    background: ${p => p.theme.palette.semantic.colorBackground};
    color: ${p => p.theme.palette.text.primary};
    font-weight: 400;
    padding: 8px 16px;
    min-height: 48px;
    box-sizing: border-box;
    border-radius: 8px;
  }
`;
