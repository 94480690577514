import {
  Button,
  Popover,
} from '@mui/material';
import styled from 'styled-components';

export const MainButton = styled(Button)`
  && {
    background-color: ${p => p.theme.palette.semantic.colorBackground};
    color: ${p => p.theme.palette.semantic.colorPrimary};
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
    cursor: pointer;
    border-radius: ${p => p.theme.spacing(1)}px;
    font-weight: 600;
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    height: 40px;
    width: 170px;

    &.MuiButton-text.MuiButton-text:hover {
      background-color: ${p => p.theme.palette.semantic.colorBackgroundHover};
      cursor: pointer;
    }

    .icon {
      margin-left: ${p => p.theme.spacing(0.5)}px;
    }
  }
`;

export const Dropdown = styled(Popover)`
  text-align: center;
  margin-top: ${p => p.theme.spacing(0.25)}px;
  font-size: 1.4rem;
`;

export const DropdownOption = styled.div`
  justify-content: flex-start;
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  color: ${p => p.theme.palette.text.primary};
  cursor: pointer;
  padding: 8px 16px;
  position: relative;
  z-index: 99;
  width: 140px;

  .icon {
    margin-right: ${p => p.theme.spacing(0.5)}px;
    font-size: 1.6rem !important;
  }

  .container {
    justify-content: center;
  }

  &:hover {
    background-color: ${p => p.theme.palette.semantic.colorBackgroundSecondary};
    color: ${p => p.theme.palette.semantic.colorPrimary};
  }

  &.disabled {
    background-color: ${p => p.theme.palette.semantic.colorBackgroundDisabled};
    color:  ${p => p.theme.palette.text.disabled};
    cursor: not-allowed;
  }
`;
