import styled from 'styled-components';

export const LabelContainer = styled.div`
  width: 120px;
  height: 16px;
  text-align: center;
  border-radius: 8px;
  &.Label__Active {
    background: ${p => p.theme.palette.semantic.colorSuccessBackground};
    .Label__Text {
      color: ${p => p.theme.palette.semantic.colorSuccessText};
    }
  }
  &.Label__Disabled {
    background: ${p => p.theme.palette.semantic.colorBackgroundDisabled};
    .Label__Text {
      color: #858f95;
    }
  }
  &.Label__Expired {
    background: ${p => p.theme.palette.semantic.colorErrorBackground};
    .Label__Text {
      color: ${p => p.theme.palette.semantic.colorErrorText};
    }
  }
  &.Label__Pending {
    background: ${p => p.theme.palette.semantic.colorWarningBackground};
    .Label__Text {
      color: ${p => p.theme.palette.semantic.colorWarningText};
    }
  }
  .Label__Text {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 16px;
    letter-spacing: 0.5px;
  }
  &.Label--Compact {
    width: 64px;
    height: 24px;
    border-radius: 12px;
    .Label__Text {
      line-height: 24px;
    }
  }
`;
