import Popover from '@mui/material/Popover';
import styled from 'styled-components';

import { transitionStandard } from '../../js_mixins/transitions';

export const DocumentAdminWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const AdminOptionsMenu = styled.button`
  border: none;
  color: ${p => p.theme.palette.text.primary};
  background: none !important;

  &:hover {
    cursor: pointer;
  }
`;

export const DocInfoWrapper = styled.div`
  flex-basis: 100%;
  cursor: pointer;

  ${props => `
    ${props.theme.breakpoints.up('md')} {
      display: flex;
      justify-content: space-between;
    }
  `};

  .CustomerPortalKBDocument__KnowledgeBaseTitle {
    word-break: break-word;
    color: ${p => p.theme.palette.semantic.colorPrimary};
  }

  &:hover {
    cursor: pointer;

    .CustomerPortalKBDocument__KnowledgeBaseTitle {
      text-decoration: underline;
    }

    .CustomerPortalDownloadIcon__ImageContainer {
      background: ${props =>
    props['data-documenttype'] === 'file'
      ? props.theme.palette.semantic.colorPrimary
      : props['data-documenttype'] === 'video'
        ? props.theme.palette.red[500]
        : props['data-documenttype'] === 'link'
          ? props.theme.palette.green[500]
          : 'inherit'};
    }
  }
`;

export const DocumentInfo = styled.div`
  ${props => props.theme.breakpoints.up('md')} {
    width: 65.53%;
    margin-right: ${props => props.theme.spacing(3)}px;
  }
`;

export const SubCategoryText = styled.p`
  // TODO: Extend p-small when in theme
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.35;
  text-transform: uppercase;
  color: ${props => props.theme.palette.text.secondary};
  margin-bottom: ${props => props.theme.spacing(1)}px;
`;

export const KBTitle = styled.h3`
  // TODO: Replace when in theme
  font-size: 2rem;
  line-height: 1.2;
  color: ${props => props.theme.palette.semantic.colorPrimary};
  margin-bottom: ${props => props.theme.spacing(2)}px;
`;

// Download Icon
export const DownloadIconWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  border-radius: 8px;

  ${props => `
    ${props.theme.breakpoints.up('md')} {
      justify-content: flex-end;
      flex-shrink: 0;
      align-self: center;
      flex-basis: 30.37%;
      max-width: 30.37%;
    }
  `};
`;

export const DownloadIconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${p => p.theme.palette.semantic.colorBackground};
  border-radius: 8px;
  margin-right: ${props => props.theme.spacing(1)}px;
  ${transitionStandard('all')};
`;

export const DocInfoContainer = styled.div`
  flex-basis: 62.92%;
  max-width: 62.92%;
  flex-grow: 0;
`;

export const DocExtension = styled.p`
  /* TODO: Replace when in design system */
  color: ${p => p.theme.palette.text.primary};
`;

export const DocFileSize = styled.p`
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  /* TODO: Replace when in design system */
  color: ${p => p.theme.palette.text.primary};
`;

export const AdminPopover = styled(Popover)`
  cursor: pointer;

  .MuiPopover-paper {
    background-color: ${p => p.theme.palette.semantic.colorBackgroundSecondary};
  }
`;

export const AdminPopoverOption = styled.div`
  button {
    color: ${p => p.theme.palette.text.primary};
  }
  .ExpandOptions__Button {
    padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(3)}px;
    cursor: pointer;
    outline: none;
    border: 0;
    background-color: inherit;
    font-size: 1.4rem;
    font-weight: 400 !important;
    line-height: 1.7;
    width: 100%;
    text-align: left;

    &:hover {
      background: ${p => p.theme.palette.semantic.colorBackgroundHover};
    }
  }
`;

export const DeleteFormWrapper = styled.form`
  margin-top: ${p => p.theme.spacing(2)}px;
`;
