import styled from 'styled-components';

export const consentTitle = styled.h1`
  color: ${p => p.theme.palette.text.primary};
  font-size: 16px;
  font-weight: bold;
  height: 20px;
  letter-spacing: 0px;
  line-height: 20px;
  width: 80%;
  display: inline-table;
`;

export const consentDescription = styled.h1`
  color: ${p => p.theme.palette.text.secondary};
  font-size: 14px;
  font-weight: normal;
  height: 72px;
  letter-spacing: 0px;
  line-height: 24px;
  width: 100%;
  overflow: auto;
`;

export const notificationsRow = styled.div`
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding:10px;
`;

export const notificationsColumnLeft = styled.div`
  display:flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 0.5;
`;

export const notificationsColumnRight = styled.div`
  display:flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 3;
`;

export const notificationsLoader = styled.div`
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
