import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ErrorPage from '../../components/ErrorPage/ErrorPage';
import { CLOUD_SERVICE_NAME } from '../../constants/auth.constants';
import {
  SWITCH_ENVIRONMENT,
  SWITCH_ENVIRONMENT_GO_TO_CLOUD_MIGRATION,
  SWITCH_ENVIRONMENT_REDIRECT_TO_CLOUD,
  SWITCH_ENVIRONMENT_REDIRECT_TO_CP,
  SWITCH_ENVIRONMENT_REDIRECT_TO_DIFFERENT_CLOUD_ORG,
} from '../../constants/telemetry.constants';
import {
  useTrackPageViewEvent,
  useTriggerTrackEventWithStateData,
} from '../../lib/AppInsights/AppInsights';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { StoreContext } from '../../store';
import {
  getIdentityAuthRedirectOrigin,
  getUrlWithAllParams,
  getWebRedirectOrigin,
  isCloudEnv,
} from '../../utils/cloud';
import { clearSession } from '../../utils/session';

/** This page is rendered when the user is on the wrong environment for their account's Cloud configuration. */
const CustomerPortalSwitchEnvironment = (props: any) => {
  const { t } = useTranslation('common');
  const {
    state, dispatch,
  } = useContext(StoreContext);

  const isCloud = isCloudEnv();

  const currentCompanyCloudOrgId = state.currentUserCompany?.additionalCompanyInfo?.cloudOrgId;
  const currentCompanyCloudOrgName = state.currentUserCompany?.additionalCompanyInfo?.cloudOrgName;

  const shouldSwitchToWeb = !currentCompanyCloudOrgId && isCloud;

  const showMoveToCloudButton = isCloud && UserPermissionsHelper.isMoveToCloudAllowed();

  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();
  useTrackPageViewEvent(SWITCH_ENVIRONMENT);

  const trackRedirectToCloudMigration = () => {
    triggerTrackEventWithStateData(SWITCH_ENVIRONMENT_GO_TO_CLOUD_MIGRATION);
  };

  const getErrorHeader = () =>
    shouldSwitchToWeb ?
      t('switch_environment_switch_to_web_header', 'Leave Automation Cloud?') :
      (isCloud ?
        t('switch_environment_switch_from_cloud_org_to_cloud_org_header', 'Switch Automation Cloud Organization?') :
        t('switch_environment_switch_from_web_to_cloud_org_header', 'Visit Automation Cloud?'));

  const getErrorDescription = () =>
    shouldSwitchToWeb ?
      t('switch_environment_switch_to_web_description', 'The {{cpAccountName}} account is not connected to Automation Cloud.', { cpAccountName: state.companyName }) :
      (isCloud ?
        t('switch_environment_switch_from_cloud_org_to_cloud_org_description', 'The {{cpAccountName}} account is connected to a different Automation Cloud organization {{cloudOrgName}}, and must be accessed from there.', {
          cpAccountName: state.companyName,
          cloudOrgName: currentCompanyCloudOrgName,
        }) :
        t('switch_environment_switch_from_web_to_cloud_org_description', 'The {{cpAccountName}} account is connected to the Automation Cloud organization {{cloudOrgName}}, and must be accessed from there.', {
          cpAccountName: state.companyName,
          cloudOrgName: currentCompanyCloudOrgName,
        }));

  const getWebURL = () => `${getWebRedirectOrigin()}${getUrlWithAllParams()}`;
  const getCloudOrgURL = () => `${getIdentityAuthRedirectOrigin()}/${currentCompanyCloudOrgName}/${CLOUD_SERVICE_NAME}${getUrlWithAllParams()}`;

  const getSwitchEnvironmentLinkTo = () => shouldSwitchToWeb ? getWebURL() : getCloudOrgURL();

  const getSwitchEnvironmentLinkText = () =>
    shouldSwitchToWeb ? t('switch_environment_switch_to_web_link', 'Proceed to {{webURL}}', { webURL: getWebURL() }) :
      (isCloud ?
        t('switch_environment_switch_from_cloud_org_to_cloud_org_link', 'Proceed to the other Automation Cloud Organization at {{cloudURL}}', { cloudURL: getCloudOrgURL() }) :
        t('switch_environment_switch_from_web_to_cloud_org_link', 'Proceed to Automation Cloud at {{cloudURL}}', { cloudURL: getCloudOrgURL() }));

  const onEnvironmentSwitch = () => {
    clearSession();

    if (shouldSwitchToWeb) {
      triggerTrackEventWithStateData(SWITCH_ENVIRONMENT_REDIRECT_TO_CP);
    } else if (isCloud) {
      triggerTrackEventWithStateData(SWITCH_ENVIRONMENT_REDIRECT_TO_DIFFERENT_CLOUD_ORG);
    } else {
      triggerTrackEventWithStateData(SWITCH_ENVIRONMENT_REDIRECT_TO_CLOUD);
    }
  };

  const getRedirectToCloudMigrationLinkText = () =>
    currentCompanyCloudOrgId ?
      t('switch_environment_connect_cloud_org_account_to_cloud_org_link', 'Connect {{cpAccountName}} to this Automation Cloud organization instead?', { cpAccountName: state.companyName }) :
      t('switch_environment_connect_web_account_to_cloud_org_link', 'Connect {{cpAccountName}} to this Automation Cloud organization?', { cpAccountName: state.companyName });

  const handleSwitchAccount = async (e: any) => {
    e.preventDefault();
    dispatch({
      type: 'setShowSwitchAccountModal',
      payload: true,
    });
  };

  return (
    <>
      <Helmet>
        <title>
          {t(
            'switch_environment_page_title',
            'Switch Environment | Customer Portal'
          )}
        </title>
      </Helmet>

      <ErrorPage
        errorType='403'
        header={getErrorHeader()}
        description={getErrorDescription()}
      >
        <a
          data-testid='SwitchEnvironment_SwitchEnvironment__CTA'
          className='link'
          onClick={onEnvironmentSwitch}
          href={getSwitchEnvironmentLinkTo()}
        >
          {getSwitchEnvironmentLinkText()}
        </a>

        {showMoveToCloudButton && (
          <Link
            data-testid='SwitchEnvironment_ConnectToCloudOrg__CTA'
            className='link'
            onClick={trackRedirectToCloudMigration}
            to='/cloud-migration'
          >
            {getRedirectToCloudMigrationLinkText()}
          </Link>
        )}

        {state.userCompanies && state.userCompanies.length > 1 && (
          <div
            data-testid='SwitchEnvironment_SwitchAccount__CTA'
            className='link'
            onClick={handleSwitchAccount}
          >
            {t('switch_environment_switch_account_cta', 'Switch Account')}
          </div>
        )}
      </ErrorPage>
    </>
  );
};

export default CustomerPortalSwitchEnvironment;
