import styled from 'styled-components';

// Pagination Assets

export const PaginationWrapper = styled.div`
  & {
    display: flex;

    padding-bottom: ${p => p.theme.spacing(5)}px;
    justify-content: space-between;
    align-items: center;

    ${p => p.theme.breakpoints.up('md')} {
      justify-content: flex-start;
      margin-left: ${p => p.theme.spacing(5)}px;
      max-width: auto;

      &.CustomerPortalPagination--noLeftMargin {
        margin-left: 0;
      }
    }

    #LeftButton,
    #RightButton {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.7;
      color: #3a464f;
      background-color: ${p => p.theme.palette.semantic.colorBackground};
      width: ${p => p.theme.spacing(5)}px;
      height: ${p => p.theme.spacing(5)}px;
      vertical-align: middle;
      position: relative;
      border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
      border-radius: 2px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
      outline: none;

      ${p => p.theme.breakpoints.up('md')} {
        width: ${p => p.theme.spacing(4)}px;
        height: ${p => p.theme.spacing(4)}px;
      }

      &:before {
        position: absolute;
        content: '';
        width: ${p => p.theme.spacing(2)}px;
        height: ${p => p.theme.spacing(2)}px;
      }

      &:hover:enabled {
        background: ${p => p.theme.palette.semantic.colorBackgroundHover};
      }
    }

    #LeftButton {
      margin-right: ${p => p.theme.spacing(2.5)}px;
      color: ${p => p.theme.palette.text.primary};

      ${p => p.theme.breakpoints.up('md')} {
        margin-right: ${p => p.theme.spacing(2)}px;
      }

      &:disabled {
        color: ${p => p.theme.palette.text.disabled};
      }
    }

    #RightButton {
      margin-left: ${p => p.theme.spacing(2.5)}px;
      color: ${p => p.theme.palette.text.primary};

      ${p => p.theme.breakpoints.up('md')} {
        margin-left: ${p => p.theme.spacing(2)}px;
      }

      &:disabled {
        color: ${p => p.theme.palette.text.disabled};
      }
    }
  }

  .CustomerPortalPagination__NumberedButtonContainer {
    display: flex;
    align-items: center;

    .CustomerPortalPagination__Ellipses {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.7;
      color: #3a464f;
      margin-right: ${p => p.theme.spacing(0.5)}px;
    }

    .CustomerPortalPagination__NumberedButton {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.7;
      color: ${p => p.theme.palette.text.primary};
      margin-right: ${p => p.theme.spacing(0.5)}px;
      width: ${p => p.theme.spacing(3)}px;
      height: ${p => p.theme.spacing(3)}px;
      background: inherit;
      border: none;
      border-radius: 2px;
      transition-duration: 0.4s;
      cursor: pointer;
      outline: none;

      ${p => p.theme.breakpoints.up('md')} {
        width: ${p => p.theme.spacing(4)}px;
        height: ${p => p.theme.spacing(4)}px;
      }

      &:hover {
        ${p => p.theme.breakpoints.up('md')} {
          background: ${p => p.theme.palette.semantic.colorBackgroundHover};
        }
      }



      ${'' /* Use more spacing for screens larger than medium */}
      ${p => p.theme.breakpoints.up('md')} {
        width: ${p => p.theme.spacing(5)}px;
        height: ${p => p.theme.spacing(5)}px;
      }

      &--Active {
        color: ${p => p.theme.palette.semantic.colorPrimary};
      }
    }
  }
`;
