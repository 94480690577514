import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

// Styles
import * as styles from '../../assets/css/CustomerPortalBasicHero';
// Components
import Container from '../CustomerPortal-New-Container';
// Assets
import defaultHeroBg from './../../assets/img/Hero-Header.png';

type BasicHeroProps = {
  cssClass?: string;
  heroBg?: string;
  title: string;
  subtitle?: string;
  backButton?: boolean;
  backButtonHref?: string;
  smallFont?: boolean;
  onClick?: (event: React.MouseEvent) => void;
};

const CustomerPortalBasicHero = (props: BasicHeroProps) => {
  const cssClass = props.cssClass ? props.cssClass : '';
  const title = props.title ? props.title : '';
  const subtitle = props.subtitle ? props.subtitle : 'Hero subtitle...';
  const heroBg = props.heroBg ? props.heroBg : defaultHeroBg;
  const backButton = props.backButton ? props.backButton : false;
  const backButtonHref = props.backButtonHref ? props.backButtonHref : '';
  const smallFont = props.smallFont ? props.smallFont : false;
  const [ back, setBack ] = useState(false);

  return (
    <styles.Hero
      className={cssClass}
      heroBg={heroBg}
      title={title}
      data-testid="BasicHero">
      {back && <Redirect
        push
        to={backButtonHref} />}
      <Container cssClass="CustomerPortalClientsSelection__heroContent">
        {backButton && (
          <div
            className="heroBackButton"
            onClick={props.onClick ? props.onClick : () => {
              setBack(true);
            }}
            data-testid="BasicHero_BackButton"
          >
            Go Back
          </div>
        )}
        <styles.Title smallFont={smallFont}>{title}</styles.Title>
        {props.subtitle && <styles.Subtitle>{subtitle}</styles.Subtitle>}
      </Container>
    </styles.Hero>
  );
};

export default CustomerPortalBasicHero;
