import React, { useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Styling
import * as styles from '../../assets/css/Knowledge/SearchResults';
// Image assets
import DownArrow from '../../assets/img/svg/kb/Arrow_Down.svg';
import Button from '../../components/Button/Button';
import UploadModal from '../../components/category/KB-Upload-Modal';
import SelectWithSearch from '../../components/Select-With-Search';
// Interfaces
import type { IDataObject } from '../../interfaces/dataObject.interface';
import type { IKBSearchState } from '../../interfaces/knowledgeBase.interface';
import ApolloIcon from '../ApolloIcon';
// Layout
import Container from '../CustomerPortal-New-Container';
// Components
import CustomerPortalPagination from '../CustomerPortal-Pagination';
import KBSearchNoResults from './KB-NoResults';

type KBSearchResultsProps = {
  handleCategoryFilterClick: (name: string) => void;
  handleSearchSortClick: (
    filter: { path: string; direction: number } | null,
    element?: HTMLElement
  ) => void;
  renderKBCard: Function;
  searchPaginationPage: number;
  searchDocsPerPage: number;
  searchNumPagesBeforeEllipses: number;
  handleSearchPaginateClick: (event: any) => void;
  searchState: IKBSearchState;
  handleMiniSearchFormSubmit?: (keywords: string) => void;
  isDisplayMiniSearchForm?: boolean;
  isDisplaySearchResultCount?: boolean;
  isGlobalSearch?: boolean;
  handleUploadFormSubmit?: Function;
  availableSubcategoriesList?: any[];
  uploadModalCategoryName: string;
  isDisplayAddButton?: boolean;
  isSupportKB?: boolean;
  handleDeleteFormSubmit?: Function;
  [key: string]: any;
};

/**
 *
 * Container that holds the KB search results
 *
 */
const CustomerPortalKBSearchResults = ({
  handleCategoryFilterClick,
  handleSearchSortClick,
  renderKBCard,
  searchPaginationPage,
  searchDocsPerPage,
  searchNumPagesBeforeEllipses,
  handleSearchPaginateClick,
  searchState,
  isDisplayMiniSearchForm = false,
  handleMiniSearchFormSubmit,
  isDisplaySearchResultCount = true,
  isGlobalSearch = true,
  handleUploadFormSubmit,
  availableSubcategoriesList = [],
  uploadModalCategoryName,
  isDisplayAddButton = false,
  isSupportKB = false,
  handleDeleteFormSubmit,
}: KBSearchResultsProps) => {
  // Translate method
  const { t } = useTranslation('common');

  // Grab from props
  searchState = searchState || {
    keyword: null,
    keywordInputField: '',
    isSearching: false,
    isCompleteSearching: false,
    filterCategories: [],
    kbDocuments: [],
    currentPage: 1,
    kbTotalDocs: 0,
    kbTotalPages: 0,
    sortBy: null,
    sortDirection: -1,
    skipResult: 0,
    filterParam: null,
    filterSubcategoriesParam: null,
  };

  /* State */
  const [ isFilterActive, setFilterActive ] = useState(false);
  const [ isSortActive, setSortActive ] = useState(false);
  const [ isAddUploadModalOpen, setIsUploadModalOpen ] = useState(false);
  const [ miniSearchText, setMiniSearchText ] = useState(searchState.keyword ?? '');
  const [ isMiniSearchActive, setMiniSearchActive ] = useState(!!miniSearchText);

  /* Events */

  const handleMiniSearchActive = () => {
    if (isMiniSearchActive) {
      // Means closing - empty the field
      setMiniSearchText('');
      if (typeof handleMiniSearchFormSubmit === 'function') {
        handleMiniSearchFormSubmit('');
      }
    }
    setMiniSearchActive(!isMiniSearchActive);
  };

  const handleMiniSearchInput = (val: string) => {
    setMiniSearchText(val);
  };

  const handleMiniFormSubmit = (e: any) => {
    if (!e || typeof handleMiniSearchFormSubmit !== 'function') {
      return;
    }
    e.preventDefault();

    handleMiniSearchFormSubmit(miniSearchText);
  };

  // Event when filter select is clicked on mobile
  const handleFilterActiveClick = () => {
    if (
      (!isFilterActive && !isSortActive) ||
      (isFilterActive && !isSortActive)
    ) {
      setFilterActive(!isFilterActive);
      return;
    }

    setFilterActive(!isFilterActive);
    setSortActive(!isSortActive);
  };

  // Event when sort select is clicked on mobile
  const handleSortActiveClick = () => {
    if (
      (!isSortActive && !isFilterActive) ||
      (isSortActive && !isFilterActive)
    ) {
      setSortActive(!isSortActive);
      return;
    }
    setFilterActive(!isFilterActive);
    setSortActive(!isSortActive);
  };

  // Desktop sort option
  const handleSortOptionClick = async (selectedOption: any) => {
    let type;

    switch (selectedOption.value) {
      case 1:
        type = 'name';
        break;
      case -1:
        type = 'created_on';
        break;
      default:
        type = isGlobalSearch ? 'matched_keywords' : 'sort_ranking';
    }

    const direction = selectedOption.value;

    handleSearchSortClick({
      path: type,
      direction,
    });
  };

  // When Add resource button is clicked, open modal
  const handleAddAssetButtonClick = () => {
    setIsUploadModalOpen(true);
  };

  const sortByText = (sortBy: string | null | undefined) => {
    let text: string;

    switch (sortBy) {
      case 'name':
        text = t('knowledge_base_search_sort_by_name', 'Name');
        break;
      case 'created_on':
        text = t('knowledge_base_search_sort_by_date', 'Date');
        break;
      case 'priority':
      default:
        text = t('knowledge_base_search_sort_by_default', 'Default');
        break;
    }

    return text;
  };

  const renderSearchResults = () => {
    // If this is global search and no results, simply display No Results component since global search bar exists
    if (isGlobalSearch && searchState.kbDocuments.length === 0) {
      return <KBSearchNoResults />;
    }

    // Otherwise, render normally. Only display the "Add New Resource" button if they are admin (isDisplayAddButton is true)
    // Note that if this is category search and no results, we still show the mini search bar, sort, and add resource buttons
    return (
      <styles.Wrapper className="CustomerPortalKBWrapper__KBResults">
        <styles.CategoriesContainer
          className={`CustomerPortalKBSearchResults__CategoriesContainer ${isFilterActive
            ? 'CustomerPortalKBSearchResults__CategoriesContainer--Active'
            : ''
          }`}
        >
          {/* Hide subcategory filter when category search but no results */}
          {searchState.kbDocuments.length > 0 &&
            <>
              <styles.FilterNameText>
                {t('knowledge_base_search_filter_sidebar_title', 'Filter by')}
                {' '}
                {isGlobalSearch
                  ? t(
                    'knowledge_base_search_filter_sidebar_title_by_category',
                    'category'
                  )
                  : t(
                    'knowledge_base_search_filter_sidebar_title_by_subcategory',
                    'subcategory'
                  )}
              </styles.FilterNameText>
              <styles.FilterContainer>
                {searchState.filterCategories.map((elem: IDataObject) => (
                  <styles.CategoriesFilterItem
                    key={elem._id}
                    data-testid="search-result-filter-item"
                    className={`CustomerPortalKBSearchResults__CategoryFilterItem ${((searchState.filterParam &&
                        searchState.filterParam.has(elem._id)) ||
                        (searchState.filterSubcategoriesParam &&
                          searchState.filterSubcategoriesParam.has(elem._id)) ||
                        (searchState.filterSubcategoriesParam &&
                          searchState.filterSubcategoriesParam.has(elem._id))) &&
                        'CustomerPortalKBSearchResults__CategoryFilterItem--Active'}`}
                    onClick={() => {
                      handleCategoryFilterClick(elem._id);
                      return;
                    }}
                  >
                    <styles.CategoryFilterItemSubcategoryName className="CategoryFilterItem__SubCategoryName">
                      {elem.category_name}
                    </styles.CategoryFilterItemSubcategoryName>
                    <styles.CategoryFilterItemCount className="CategoryFilterItem__Count">
                      {elem.count}
                    </styles.CategoryFilterItemCount>
                  </styles.CategoriesFilterItem>
                ))}
              </styles.FilterContainer>
            </>}
        </styles.CategoriesContainer>
        <styles.SearchResultsInner>
          <div className="SearchResultsInner__Heading">
            <styles.SearchResultsTitle>
              {isDisplaySearchResultCount &&
                t(
                  'knowledge_base_search_result_title',
                  `We found ${searchState.kbTotalDocs} result(s).`,
                  { kbTotalDocs: searchState.kbTotalDocs }
                )}
            </styles.SearchResultsTitle>
            <styles.SearchResultsDesktopActions>
              <SelectWithSearch
                value={searchState.sortBy}
                className="Custom-Select--White CustomerPortalClientsSelection__clientsSort"
                onChange={handleSortOptionClick}
                options={[
                  {
                    label: t(
                      'knowledge_base_search_sort_by_default',
                      'Default'
                    ),
                    value: 0,
                  },
                  {
                    label: t('knowledge_base_search_sort_by_name', 'Name'),
                    value: 1,
                  },
                  {
                    label: t('knowledge_base_search_sort_by_date', 'Date'),
                    value: -1,
                  },
                ]}
                searchable={false}
                placeholder={
                  <div data-testid="sort-by">
                    {t('sort_by', 'Sort by')}
:
                    {' '}
                    <span className="Custom-Select__Placeholder-Value">
                      {sortByText(searchState.sortBy)}
                    </span>
                  </div>
                }
              />
              {isDisplayMiniSearchForm && (
                <>
                  <div className="CustomerPortalClientsSelection__clientsSearch">
                    <div
                      className={`CustomerPortalClientsSelection__clientsSearchInput${isMiniSearchActive
                        ? ' CustomerPortalClientsSelection__clientsSearchInput--Active'
                        : ''
                      }`}
                    >
                      <form onSubmit={handleMiniFormSubmit}>
                        {isMiniSearchActive && (
                          <input
                            autoFocus
                            placeholder={t(
                              'knowledge_base_search_mini_placeholder',
                              'Keyword'
                            )}
                            type="text"
                            value={miniSearchText}
                            onChange={e => {
                              handleMiniSearchInput(e.target.value);
                            }}
                            data-testid="mini-search-input"
                          />
                        )}
                      </form>
                    </div>
                    <div
                      className={`CustomerPortalClientsSelection__clientsSearchIcon${isMiniSearchActive
                        ? ' CustomerPortalClientsSelection__clientsSearchIcon--Close'
                        : ''
                      }`}
                      onClick={handleMiniSearchActive}
                      data-testid="search-icon"
                    >
                      <ApolloIcon
                        icon={isMiniSearchActive ? 'close' : 'search'}
                        fontSize="small"
                        className="icon"
                      />
                    </div>
                  </div>
                  {isDisplayAddButton && (
                    <Button
                      className="CustomerPortalClientsSelection__createButton"
                      onClick={handleAddAssetButtonClick}
                      text={t(
                        'knowledge_base_category_page_add_new_resource_btn',
                        'Add New Resource'
                      )}
                    />
                  )}
                </>
              )}
            </styles.SearchResultsDesktopActions>
          </div>

          <styles.MobileFilters>
            <div
              className={`CustomerPortalKBSearchResults__FiltersHeader${isFilterActive
                ? ' CustomerPortalKBSearchResults__FiltersHeader--Active'
                : ''
              }`}
              onClick={handleFilterActiveClick}
            >
              <span>
                {t(
                  'knowledge_base_search_filter_sidebar_title_mobile',
                  'Filter'
                )}
              </span>
              <img
                src={DownArrow}
                alt="Downard arrow"
                className="CustomerPortalPageKnowledgeBase__DownwardArrow"
              />
            </div>

            <div
              className={`CustomerPortalKBSearchResults__FilterContainer${isFilterActive
                ? ' CustomerPortalKBSearchResults__FilterContainer--Active'
                : ''
              }`}
            >
              {searchState.filterCategories.map((elem: IDataObject) => (
                <div
                  key={elem._id}
                  className={`CustomerPortalKBSearchResults__SortItem CustomerPortalKBSearchResults__FilterItem--Mobile ${((searchState.filterParam &&
                      searchState.filterParam.has(elem._id)) ||
                      (searchState.filterSubcategoriesParam &&
                        searchState.filterSubcategoriesParam.has(
                          elem._id
                        ))) &&
                      ' CustomerPortalKBSearchResults__SortItem--Active'}`}
                  onClick={() => {
                    handleCategoryFilterClick(elem._id);
                  }}
                >
                  <span className="CategoryFilterItem__Count">
                    {elem.count}
                  </span>
                  <span className="CustomerPortalKBSearchResults__SortText CategoryFilterItem__SubCategoryName">
                    {elem.category_name}
                  </span>
                </div>
              ))}
            </div>

            <div
              className={`CustomerPortalKBSearchResults__FiltersHeader${isSortActive
                ? ' CustomerPortalKBSearchResults__FiltersHeader--Active'
                : ''
              }`}
              onClick={handleSortActiveClick}
            >
              <span>
                {t('knowledge_base_search_sort_by_label_mobile', 'Sort')}
              </span>
              <img
                src={DownArrow}
                alt="Downward arrow"
                className="CustomerPortalPageKnowledgeBase__DownwardArrow"
              />
            </div>
            <div
              className={`CustomerPortalKBSearchResults__SortContainer${isSortActive
                ? ' CustomerPortalKBSearchResults__SortContainer--Active'
                : ''
              }`}
            >
              <div
                className={`CustomerPortalKBSearchResults__SortItem${searchState.sortBy === 'name' &&
                  searchState.sortDirection === 1
                  ? ' CustomerPortalKBSearchResults__SortItem--Active'
                  : ''
                }`}
                onClick={e => {
                  handleSearchSortClick({
                    path: 'name',
                    direction: 1,
                  });
                }}
              >
                <p className="CustomerPortalKBSearchResults__SortText">
                  {t('knowledge_base_search_sort_by_a_to_z', 'A to Z')}
                </p>
              </div>
              <div
                className={`CustomerPortalKBSearchResults__SortItem${searchState.sortBy === 'name' &&
                  searchState.sortDirection === -1
                  ? ' CustomerPortalKBSearchResults__SortItem--Active'
                  : ''
                }`}
                onClick={e => {
                  handleSearchSortClick({
                    path: 'name',
                    direction: -1,
                  });
                }}
              >
                <p className="CustomerPortalKBSearchResults__SortText">
                  {t('knowledge_base_search_sort_by_z_to_a', 'Z to A')}
                </p>
              </div>
              <div
                className={`CustomerPortalKBSearchResults__SortItem${searchState.sortBy === 'created_on' &&
                  searchState.sortDirection === -1
                  ? ' CustomerPortalKBSearchResults__SortItem--Active'
                  : ''
                }`}
                onClick={e => {
                  handleSearchSortClick({
                    path: 'created_on',
                    direction: -1,
                  });
                }}
              >
                <p className="CustomerPortalKBSearchResults__SortText">
                  {t('knowledge_base_search_sort_by_newest', 'Newest')}
                </p>
              </div>
              <div
                className={`CustomerPortalKBSearchResults__SortItem${searchState.sortBy === 'created_on' &&
                  searchState.sortDirection === 1
                  ? ' CustomerPortalKBSearchResults__SortItem--Active'
                  : ''
                }`}
                onClick={e => {
                  handleSearchSortClick({
                    path: 'created_on',
                    direction: 1,
                  });
                }}
              >
                <p className="CustomerPortalKBSearchResults__SortText">
                  {t('knowledge_base_search_sort_by_oldest', 'Oldest')}
                </p>
              </div>
            </div>
          </styles.MobileFilters>
          {/* Show results if exist, otherwise show No Results component */}
          {searchState.kbDocuments.length > 0 ? (
            <>
              <styles.SearchResultsContainer data-testid="FoundSearchResults">
                {searchState.kbDocuments.map((doc: IDataObject, i: number) => renderKBCard(
                  {
                    ...doc,
                    dataTestId: `KB_Document_${i}`,
                  },
                  true,
                  handleDeleteFormSubmit
                ))}
              </styles.SearchResultsContainer>

              <CustomerPortalPagination
                className="KBPagination"
                activePage={searchPaginationPage}
                numResults={searchState.kbTotalDocs}
                numResultsPerPage={searchDocsPerPage}
                numPagesBeforeEllipses={searchNumPagesBeforeEllipses}
                handleClick={handleSearchPaginateClick}
              />
            </>) : <KBSearchNoResults />}
        </styles.SearchResultsInner>
      </styles.Wrapper>
    );
  };
  return (
    <Container dataTestId="KBSearchResultsContainer">
      {renderSearchResults()}

      {isAddUploadModalOpen && (
        <UploadModal
          isUploadModalOpen={isAddUploadModalOpen}
          categoryName={uploadModalCategoryName}
          handleFormSubmit={handleUploadFormSubmit}
          handleModalClose={() => {
            setIsUploadModalOpen(false);
          }}
          availableSubcategoriesList={availableSubcategoriesList}
        />
      )}
    </Container>
  );
};

export default CustomerPortalKBSearchResults;
