import styled from 'styled-components';

export const CustomerPortalFooterContainer = styled.div`
  padding-top: 47px;
  border-top: 1px solid ${p => p.theme.palette.semantic.colorBackgroundGray};
  min-height: 175px;
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  width: 100%;
  ${p => p.theme.breakpoints.down('sm')} {
    height: unset;
  }
  .CustomerPortalFooter__Inner-Container {
    width: 100%;
    ${p => p.theme.breakpoints.down('md')} {
      margin-bottom: 47px;
    }
    ${p => p.theme.breakpoints.down('sm')} {
      position: relative;
      top: 0;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
      padding: 36px 0;
    }
  }

  .CustomerPortalFooter__Logo-Column {
    width: 100%;
    max-width: 320px;
    margin-right: 124px;
    ${p => p.theme.breakpoints.down('md')} {
      margin-right: 0;
    }
    ${p => p.theme.breakpoints.down('sm')} {
      order: 2;
    }
  }

  .CustomerPortalFooter__Links-Copy-Col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .CustomerPortalFooter__Links-Row {
    display: flex;
    flex-direction: row;
    margin-bottom: ${p => p.theme.spacing(1)}px;
    ${p => p.theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  }

  .CustomerPortalFooter__Grid-Container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    ${p => p.theme.breakpoints.down('md')} {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    ${p => p.theme.breakpoints.down('sm')} {
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .CustomerPortalFooter__Link {
    cursor: pointer;
    text-decoration: none;
    color: ${p => p.theme.palette.semantic.colorPrimary};
    display: block;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 24px;
    margin-right: 24px;
    &:last-child {
      margin-right: 0px;
    }
    ${p => p.theme.breakpoints.down('sm')} {
      margin-bottom: 16px;
    }
    &.Disabled {
      pointer-events: none;
      opacity: 0.1;
    }
  }

  .Footer__Desk-Copy {
    ${p => p.theme.breakpoints.down('sm')} {
      display: none;
    }
  }

  .Footer__Mobile-Copy {
    display: none;
    ${p => p.theme.breakpoints.down('sm')} {
      display: block;
      margin-top: ${p => p.theme.spacing(4)}px;
    }
  }
`;

export const FooterLink = styled.a`
  color: ${p => p.theme.palette.semantic.colorPrimary};
  text-decoration: none;
  ${p => p.theme.breakpoints.down('sm')} {
    display: inline-block;
    margin-top: ${p => p.theme.spacing(1)}px;
  }
`;

export const FooterCopyText = styled.p`
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  color: ${p => p.theme.palette.text.primary};
  ${p => p.theme.breakpoints.down('sm')} {
    order: 3;
  }
`;
