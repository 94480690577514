import styled from 'styled-components';

export const PrimaryInfoBanner = styled.div<{
  bannerState?: boolean;
  showMultiAccountTutorial?: boolean;
}>`
  position: relative;
  padding: ${p => p.theme.spacing(2.5)}px 0;
  z-index: 5;
  background-color: ${p => p.theme.palette.type === 'light'
    ? p.theme.palette.semantic.colorBackgroundInverse
    : p.theme.palette.semantic.colorBackgroundSecondary};
  width: 100%;
  display: ${p => (p.bannerState ? 'block' : 'none')};

  ${(p): string =>
    p.showMultiAccountTutorial
      ? `
    &:before {
      content: '';

      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;

      background: ${p.theme.palette.text.primary};
      opacity: 0.3;

      width: 100vw;
      height: 100vh;
    };
    `
      : ``}

  .CustomerPortalInfoBanner__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${p => p.theme.breakpoints.up('md')} {
      flex-direction: row;
      align-items: center;
    }

    height: 100%;

    .CustomerPortalInfoBanner__body {
      flex: 0 0 auto;
      width: 100%;
      color: ${p => p.theme.palette.common.white};
      font-weight: normal;
      font-size: 1.2rem;
      letter-spacing: 0.5px;

      &--highlight {
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
      }

      ${p => p.theme.breakpoints.up('md')} {
        flex: 0 0 auto;
        width: auto;
      }
    }

    .CustomerPortalInfoBanner__ctaContainer {
      position: relative;
      margin-top: ${p => p.theme.spacing(2)}px;

      ${p => p.theme.breakpoints.up('md')} {
        margin-top: 0;
      }

      .CustomerPortalInfoBanner__cta {
        position: relative;
        padding: ${p => p.theme.spacing(1)}px;
        color: ${p => p.theme.palette.semantic.colorPrimary};

        font-size: 1rem;
        font-weight: bold;

        text-transform: uppercase;
        cursor: pointer;

        ${p =>
    p.showMultiAccountTutorial
      ? `
          border: 1px solid ${p.theme.palette.semantic.colorPrimary};
          border-radius: 4px;
          z-index: 2;
          `
      : ``}

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;

          transform: translateY(-32%);

          width: 16px;
          height: 16px;
        }
      }

      ${p =>
    p.showMultiAccountTutorial
      ? `.CustomerPortalInfoBanner__ctaTutorial {
            padding: ${p.theme.spacing(2)}px;

            position: absolute;
            top: 30px;
            left: 0px;

            z-index: 2;
            width: 300px;

            ${p.theme.breakpoints.up('md')} {
              left: 30px;
              width: 400px;
            }

            background: ${p.theme.palette.semantic.colorBackground};

            p {
              color: ${p.theme.palette.text.primary};

              font-size: 1.4rem;
              line-height: 20px;
            }

            .closeIcon {
              position: absolute;
              top: 50%;
              right: 14px;

              transform: translateY(-50%);

              width: 10px;
              height: 10px;

              cursor: pointer;
            }
          }`
      : `.CustomerPortalInfoBanner__ctaTutorial {
            display: none;
          }`}

      ${p => p.theme.breakpoints.up('md')} {
        padding-top: 0;

        &:before {
          content: '|';
          padding: 0 ${p => p.theme.spacing(1.5)}px;
          color: ${p => p.theme.palette.common.white};
          cursor: default;
        }
      }
    }
  }
`;
