import styled from 'styled-components';

import ArrowRight from '../img/svg/arrows/ArrowLong_Right__grey.svg';

export const SwitchAccountContainer = styled.div`

  .AccountList__loader {
    text-align: center;

    div {
      margin-top: ${p => p.theme.spacing(5)}px;
      display: inline-block;

      width: 24px;
      height: 24px;
    }
  }

  h2 {
    padding-bottom: 16px;
    color: ${p => p.theme.palette.text.primary};
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 24px;
  }

  p {
    color: ${p => p.theme.palette.text.primary};
    font-size: 1.4rem;
    line-height: 20px;
  }

  .AccountList {
    padding-top: ${p => p.theme.spacing(4)}px;
  }

  .AccountList__header,
  .AccountList__item,
  .AccountList__additionalCloudOrgAccountItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .AccountList__header {
    margin-bottom: ${p => p.theme.spacing(1)}px;
    padding: 0 ${p => p.theme.spacing(3)}px;

    color: ${p => p.theme.palette.text.primary};
    font-size: 1.2rem;
    line-height: 16px;

    .AccountList__team {
      width: 60%;
    }

    .AccountList__teamSize {
      flex: 0 0 auto;
      width: 20%;
    }
  }

  .AccountList__body {
    max-height: 30vh;
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      background-color: ${p => p.theme.palette.semantic.colorBackground};
    }

    &::-webkit-scrollbar {
      width: ${p => p.theme.spacing(0.5)}px;
      background-color: ${p => p.theme.palette.semantic.colorBackground};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${p => p.theme.spacing(1)}px;
      background-color:  ${p => p.theme.palette.semantic.colorBackground};
    }
  }

  .AccountList__item {
    position: relative;

    margin-right: ${p => p.theme.spacing(1)}px;
    margin-bottom: ${p => p.theme.spacing(2)}px;
    padding: ${p => p.theme.spacing(2.5)}px ${p => p.theme.spacing(3)}px;

    background: rgba(245, 245, 246, 0.5);
    border-radius: ${p => p.theme.spacing(1)}px;

    font-size: 1.4rem;
    line-height: 20px;

    .AccountList__team {
      width: 60%;
      font-weight: bold;
    }

    .AccountList__teamSize {
      flex: 0 0 auto;
      width: 20%;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 16px;

      transform: translateY(-50%);

      background: url(${ArrowRight});
      background-repeat: no-repeat;

      width: 10px;
      height: 10px;
    }

    &:hover {
      background: rgba(245, 245, 246, 1);
    }

    &:last-child {
      margin-bottom: 0;
    }

    cursor: pointer;
  }

  .AccountList__CTAs {
    text-align: right;
  }

  .AccountList__additionalCloudOrgAccountsContainer {
    margin-top: ${p => p.theme.spacing(8)}px;
  }

  .AccountList__additionalCloudOrgAccountsDescription {
    margin-bottom: ${p => p.theme.spacing(2)}px;
  }

  .AccountList__additionalCloudOrgAccountItem {
    position: relative;

    margin-right: ${p => p.theme.spacing(1)}px;
    margin-bottom: ${p => p.theme.spacing(2)}px;
    padding: ${p => p.theme.spacing(2.5)}px ${p => p.theme.spacing(3)}px;

    background: rgba(245, 245, 246, 0.5);
    border-radius: ${p => p.theme.spacing(1)}px;

    font-size: 1.4rem;
    line-height: 20px;

    .AccountList__team {
      width: 60%;
      font-weight: bold;
    }

    .AccountList__teamSize {
      flex: 0 0 auto;
      width: 20%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  button.CancelButton {
    margin-top: 40px;
    padding: 12px 32px;

    border: 1px solid rgb(164, 177, 184);
    border-radius: 1px;

    color: ${p => p.theme.palette.semantic.colorPrimary};
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 20px;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
`;
