import Button from '@mui/material/Button';
import styled from 'styled-components';

export default styled(Button)`
  && {
    outline: none;
    border: none;
    background-color: ${p => p.theme.palette.semantic.colorBackgroundSecondary} !important;
    color: ${p => p.theme.palette.text.secondary};
    font-weight: normal;
    margin: 6px;
    border-radius: ${p => p.theme.spacing(1)}px;
    padding: 10px 16px;
  }
`;
