import { useAuth } from '../contexts/auth';
import { useTriggerTrackEventWithStateData } from '../lib/AppInsights/AppInsights';
import { clearSession } from '../utils/session';

const useLogout = () => {
  const { logout } = useAuth();
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();

  return async () => {
    try {
      triggerTrackEventWithStateData('User.Logout');
      clearSession();
    } catch (error) {
      console.error('Error occurred during logout:', error);
    } finally {
      await logout();
    }
  };
};

export default useLogout;
