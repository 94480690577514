import axios from 'axios';

import { axiosGet } from '../client/axios';
import { ACCOUNT_INFO_URL } from '../constants/network.constants';
import {
  getPortalAPIUrl,
  isCloudEnv,
} from '../utils/cloud';

class CompanyHelper {
  getCompanyData = async (companyId: string, token: string, cloudOrgId?: string) => {
    const companyData: {
      companyType: string;
      companyLogo: string;
      companyCountry: string;
      accountQuestionnaire: string | undefined;
      uiPathContacts: Array<{
        email: string;
        id: string;
        name: string;
        profilePhoto: string;
        roles: string;
      }>;
      teamUsers: Array<{
        Email: string;
        Name: string;
        cpRole: string;
        permissions: string[];
        photo: string;
        status: string;
        userAccounts: string[];
        cloudOrgProfile?: {
          email: string;
          source: string;
          displayName?: string;
          firstName?: string;
          lastName?: string;
        };
      }>;
      isHapoEnabled: boolean;
      isUtoEnabled: boolean;
      supportOnlyProvidedByCountry?: string;
    } = {
      companyType: '',
      companyLogo: '',
      companyCountry: '',
      accountQuestionnaire: undefined,
      uiPathContacts: [],
      teamUsers: [],
      isHapoEnabled: false,
      isUtoEnabled: false,
      supportOnlyProvidedByCountry: undefined,
    };
    try {
      const companyInfoRes = await axiosGet(
        `${ACCOUNT_INFO_URL}`,
        companyId,
        token
      );

      if (companyInfoRes.status === 200 && companyInfoRes.data) {
        const teamUsers = companyInfoRes.data.account?.contacts?.records || [];
        if (isCloudEnv() && cloudOrgId) {
          try {
            const teamUserEmails: string[] = teamUsers.map((user: { Email: 'string' }) => user.Email);

            // BulkResolveByNameAPI handles only 20 entityNames at a time
            const batchSize = 20;
            for (let i = 0; i < teamUserEmails.length; i += batchSize) {
              const teamUserEmailBatch = teamUserEmails.slice(i, i + batchSize);
              const bulkResolveByNameResponse = await axios.post(`${getPortalAPIUrl()}/identity/Directory/BulkResolveByName/${cloudOrgId}`,
                {
                  entityNames: teamUserEmailBatch,
                  entityType: 'user',
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                  },
                });

              if (bulkResolveByNameResponse.status === 200 && bulkResolveByNameResponse.data) {
                for (const user of teamUsers) {
                  const cloudOrgProfile = bulkResolveByNameResponse.data[user.Email];
                  if (cloudOrgProfile) {
                    user.cloudOrgProfile = cloudOrgProfile;
                  }
                }
              }
            }
          } catch (e) {
            console.error('An error occurred while fetching the Cloud membership of team-users:', e);
          }
        }

        companyData.companyType =
          companyInfoRes.data.account?.maintenanceFlag || '';
        companyData.accountQuestionnaire =
          companyInfoRes.data.account?.accountQuestionnaire;
        let companyLogo = '';
        if (
          companyInfoRes.data.account?.picture_storage_account_name &&
          companyInfoRes.data.account?.logo
        ) {
          companyLogo = `${companyInfoRes.data.account.picture_storage_account_name}/${companyInfoRes.data.account.logo}`;
        }
        companyData.companyLogo = companyLogo;
        companyData.companyCountry = companyInfoRes.data.account.country || '';
        companyData.uiPathContacts = companyInfoRes.data.uipathTeam || [];
        companyData.teamUsers = teamUsers;
        companyData.isHapoEnabled =
          companyInfoRes.data.account?.hapo_feature_enabled;
        companyData.isUtoEnabled =
          companyInfoRes.data.account?.uto_feature_enabled;
        companyData.supportOnlyProvidedByCountry = companyInfoRes.data.account?.supportOnlyProvidedByCountry;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
    return companyData;
  };
}

export default new CompanyHelper();
