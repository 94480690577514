import MenuItem from '@mui/material/MenuItem';
// Components
import Popover from '@mui/material/Popover';
import styled from 'styled-components';

import DownArrowHover from '../img/svg/arrows/ArrowSmall_Down_Blue.svg';
// Images
import DownArrow from '../img/svg/arrows/Downward_Arrow.svg';
import { transitionStandard } from '../js_mixins/transitions';

export const SelectContainer = styled.div`
  position: relative;
  .Select__Helper-Text {
    font-family: ${p => p.theme.typography.fontFamily};
    font-size: 1rem;
    line-height: 1.6rem;
    letter-spacing: 0.5px;
    margin-top: 8px;
    min-height: 1em;
    text-align: left;
    font-weight: 400;
    color: ${p => p.theme.palette.error.main};
  }
`;

export const SelectTrigger = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: ${p => p.theme.palette.semantic.colorBackground} !important;
  border: 1px solid ${p => p.theme.palette.semantic.colorBorder} !important;
  border-radius: 8px;
  cursor: pointer;
  position: relative;

  .Select__Trigger-Placeholder-Title {
    color: #9AA4A9;
    ${transitionStandard('all')};
    &.MuiMenuItem-root {
      font-size: 1.4rem;
      line-height: 2.4rem;
      font-family: ${p => p.theme.typography.fontFamily};
      padding: 0;
      color: ${p => p.theme.palette.text.primary};
      background: ${p => p.theme.palette.semantic.colorBackground};
      &:hover {
        background: none;
      }
    }
    &.Selected {
      color: ${p => p.theme.palette.text.primary};
    }
  }

  .Select__Trigger-Placeholder-Value {
    color: ${p => p.theme.palette.text.primary};
    margin-left: 8px;
    ${transitionStandard('all')};
  }

  .Select__Trigger-Arrow {
    width: 16px;
    height: 16px;
    background-image: url('${DownArrow}');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px 7px;
    ${transitionStandard('all')};
  }

  &:hover {
    .Select__Trigger-Placeholder-Title,.Select__Trigger-Placeholder-Value {
      color: ${p => p.theme.palette.semantic.colorPrimary};
    }
    .Select__Trigger-Arrow {
      background-image: url('${DownArrowHover}');
    }
  }

  &.CustomerPortal__Select-Small {
    .MuiPopover-paper {
      width: 180px !important;
    }
  }
`;

export const SelectOuter = styled(Popover)`
  .MuiPopover-paper {
    max-height: 200px;
    max-width: 500px;
    min-width: 0px;
    overflow: auto;
    border-radius: ${p => p.theme.spacing(1)}px;
    margin-top: 12px;
    -webkit-box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
    -moz-box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
    box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
  }

  &.Small {
    .MuiPopover-paper {
      width: 180px !important;
    }
  }

  .CustomerPortalSelect__Inner {
    padding: ${p => p.theme.spacing(1)}px 0;
  }

  &.Select-Permissions__Outer {
    .MuiPopover-paper {
      max-height: none;
      border-radius: 2px;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.14),
        0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14);
    }
  }
  .Select-Permissions__Inner {
    width: 450px;
    ${p => p.theme.breakpoints.down('sm')} {
      width: 325px;
    }
    .Select-Permissions__Permission-Items-Container {
      padding-bottom: 12px;
      background: ${p => p.theme.palette.semantic.colorBackgroundRaised};
    }
    .Select-Permissions__Column-Titles-Container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 9px 12px 0px 12px;
      border-top: 1px solid ${p => p.theme.palette.ink[300]};
      .Select-Permissions__Column-Title {
        &.Select-Permissions__Column--permissions {
          p {
            color: ${p => p.theme.palette.text.secondary};
          }
        }
        p {
          color: ${p => p.theme.palette.text.primary};
        }
      }
    }

    .Select-Permissions__Column--permissions-Title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      p {
        color: ${p => p.theme.palette.text.primary};
        margin-right: 5px;
      }
    }

    .Select-Permissions__Column {
      &--permissions {
        flex: 0 0 250px;
        ${p => p.theme.breakpoints.down('sm')} {
          flex: 0 0 155px;
        }
        p {
          color: ${p => p.theme.palette.text.primary};
        }
      }

      flex: 0 0 73px;
      text-align: center;
    }
    .Select-Permissions__Matrix-Checkboxes {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex: 1 1 auto;
      .MuiCheckbox-root {
        padding: 6px;
        &:not(.Mui-checked) {
          color: ${p => p.theme.palette.text.primary};
        }
        .MuiSvgIcon-root {
          width: 18px;
          height: 18px;
        }
      }
    }
    .Select-Permissions__Matrix-Item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      box-sizing: border-box;
    }
    .Select-Permissions__Radio-Container {
      padding: 6px 12px;
      background: ${p => p.theme.palette.semantic.colorBackgroundRaised};
      .MuiFormControlLabel-label {
        color: ${p => p.theme.palette.text.primary};
        font-size: 14px;
      }
      .MuiRadio-root {
        padding: 6px;
        .MuiSvgIcon-root {
          width: 20px;
          height: 20px;
        }
      }
      .MuiFormControlLabel-root {
        margin-left: -6px;
      }
    }
  }
`;

export const PermissionsToolTipTitleContainer = styled.div`
  p {
    color: ${p => p.theme.palette.ink[100]};
    font-size: 1.2rem;
    font-weight: 600;
  }

  > :not(:first-child) {
    margin-top: 12px !important;
  }
`;

export const SelectOption = styled(MenuItem)`
  font-family: ${p => p.theme.typography.fontFamily};
  color: ${p => p.theme.palette.text.primary};
  background: ${p => p.theme.palette.semantic.colorBackgroundRaised};
  &:hover {
    background: ${p => p.theme.palette.semantic.colorBackground};
  }

  &.MuiMenuItem-root {
    padding: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(2)}px;

    .Select__Option-Container {
      pointer-events: none;
      overflow: hidden;
      min-width: 300px;
    }

    .Select__Option-Label {
      font-size: 1.4rem;
      line-height: 2.4rem;
      color: ${p => p.theme.palette.text.primary};
      text-align: center
    }

    .Select__Option-Description {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: ${p => p.theme.palette.text.primary};
      white-space: normal;
    }
  }

  &.Select__Option--Active {
    &.MuiMenuItem-root {
      color: ${p => p.theme.palette.text.primary};
    }
  }
`;
