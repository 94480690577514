// Components
import Radio from '@mui/material/Radio';
import React from 'react';
import styled from 'styled-components';

import ThumbsDown from './ThumbDownFill';
import ThumbsDownOutlined from './ThumbDownOutline';
// Images
import ThumbsUp from './ThumbUpFill';
import ThumbsUpOutlined from './ThumbUpOutline';

const FeedbackFormRowInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  &:last-of-type {
    margin-bottom: 0;
  }
  .ReviewModal__Review-Item-Title {
    color: ${p => p.theme.palette.text.primary};
    margin-left: 16px;
  }
  .FeedbackFormRow__Thumb {
    padding: 6px;
  }
`;

interface FeedbackFormRowProps {
  onRatingChange: any;
  rating: {
    id: string;
    text: string;
    score: number;
    valid: boolean;
  };
  formPosition: number;
}

const FeedbackFormRow = (props: FeedbackFormRowProps) => (
  <FeedbackFormRowInputs>
    <Radio
      checked={props.rating.score === 1}
      checkedIcon={<ThumbsUp />}
      className="FeedbackFormRow__Thumb"
      icon={<ThumbsUpOutlined />}
      onChange={props.onRatingChange}
      id={`${props.formPosition}`}
      value={1}
      name="thumbs-up"
      inputProps={{ 'aria-label': 'Yes' }}
      data-testid="FeedbackFormRowInputs__thumbsUp"
    />
    <Radio
      checked={props.rating.score === 0}
      checkedIcon={<ThumbsDown />}
      className="FeedbackFormRow__Thumb"
      icon={<ThumbsDownOutlined />}
      onChange={props.onRatingChange}
      id={`${props.formPosition}`}
      value={0}
      name="thumbs-down"
      inputProps={{ 'aria-label': 'No' }}
      data-testid="FeedbackFormRowInputs__thumbsDown"
    />
    <p className="ReviewModal__Review-Item-Title Small">
      {props.rating.text}
    </p>
  </FeedbackFormRowInputs>
);

export default FeedbackFormRow;
