import type {
  CaseAssistEngine,
  DocumentSuggestionList,
} from '@coveo/headless/case-assist';
import { buildDocumentSuggestionList } from '@coveo/headless/case-assist';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from '../../assets/css/Support/Case';
import {
  axiosGet,
  axiosPublicGet,
} from '../../client/axios';
import BasicHero from '../../components/BasicHero/CustomerPortal-BasicHero';
import Loader from '../../components/CustomerPortal-Loader';
// Components
import Container from '../../components/CustomerPortal-New-Container';
import NewSupportCase from '../../components/support/NewSupportCase';
// Constants
import {
  PUBLIC_SUPPORT_DATA_URL,
  SUPPORT_DATA_URL,
} from '../../constants/network.constants';
import { ADD_CASE } from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import { usePublicAuth } from '../../contexts/public-auth';
// Interfaces
import type {
  DeploymentTypeWithProducts,
  DropDownItemData,
  ProductWithDependentFields,
  SupportFormData,
} from '../../interfaces/sfdc.interface';
import type { OptionalPublicSupportProps } from '../../interfaces/support.interface';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { StoreContext } from '../../store';
import { CPCaseAssistEngine } from '../../utils/caseAssist';

const CustomerPortalPageAddCase = (props: OptionalPublicSupportProps) => {
  const isPublic = props.isPublic ?? false;
  const { t } = useTranslation('common');
  const { state } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const {
    setIsCsrfTokenSet, setIsRateLimited,
  } = usePublicAuth();
  // When isPublic is true, the user's state always has isRegistered as false, but we add the first check to be explicit
  const isRegistered = !isPublic && UserPermissionsHelper.isRegisteredUser();
  useTrackPageViewEvent(ADD_CASE);
  const caseAssistEngine = useRef<CaseAssistEngine>(
    CPCaseAssistEngine.getInstance(isPublic)
  );
  const caseAssistController = useRef<DocumentSuggestionList>(buildDocumentSuggestionList(caseAssistEngine.current));
  const noneEntry = {
    _id: '0',
    label: t('support_form_dropdown_item_none', '--- None ---'),
    value: 'None',
  };
  const formData = {
    countries: [ noneEntry ],
    environments: [ noneEntry ],
    issueCategories: [ noneEntry ],
    licenseCategories: [ noneEntry ],
    noOfRobotsImpacted: [ noneEntry ],
    orchestratorVersions: [ noneEntry ],
    priorities: [ noneEntry ],
    productRelatedFeatures: [ noneEntry ],
    deploymentTypes: [ noneEntry ],
    studioVersions: [ noneEntry ],
    supportRequestRelatedProducts: [ noneEntry ],
    timezones: [ noneEntry ],
    deprecatedProductComponentVersions: [],
    supportFormFieldMappings: [],
    automationSuiteVersions: [ noneEntry ],
    airgapped: [ noneEntry ],
    architectures: [ noneEntry ],
    packageNames: [ noneEntry ],
    issueTypes: [ noneEntry ],
  };
  const [ sfdcData, setSfdcData ] = useState<any>(null);
  const [ showPublicCaseConfirmation, setShowPublicCaseConfirmation ] = useState<boolean>(false);
  const [ showCancelConfirmation, setShowCancelConfirmation ] = useState<boolean>(false);
  const [ currentPage, setCurrentPage ] = useState(1);
  const totalPages = 3;

  const getSFDCResults = async () => {
    try {
      if (isPublic) {
        const response = await axiosPublicGet(
          PUBLIC_SUPPORT_DATA_URL,
        );
        setSfdcData(response);
      } else {
        const response = await axiosGet(
          SUPPORT_DATA_URL,
          isRegistered ? state.companyId : '',
          await getAccessToken()
        );
        setSfdcData(response);
      }
    } catch (e) {
      if (e.response?.status === 401 && setIsCsrfTokenSet) {
        setIsCsrfTokenSet(false);
        return;
      }
      if (e.response?.status === 429 && setIsRateLimited) {
        setIsRateLimited(true);
        return;
      }
      console.log(e);
    }
  };

  useEffect(() => {
    getSFDCResults();
  }, []);

  const sortDropdownItemsByProperty = (property: string) => <
    T extends DropDownItemData & { [key: string]: any },
  >(
    a: T,
    b: T
  ) => {
    if (a[property] > b[property]) {
      return 1;
    } else if (a[property] < b[property]) {
      return -1;
    }
    return 0;
  };

  const sanitizeChildrenWithNoneEntry = <
    T extends DropDownItemData & { [key: string]: any },
  >(
    parent: T,
    childFieldName: string,
    children: T[] = []
  ) => {
    const sanitizedChildren = children.length
      ? children
      : parent[childFieldName] ?? [];
    return {
      ...parent,
      ...(sanitizedChildren.length && { [childFieldName]: [ noneEntry, ...sanitizedChildren ] }),
    };
  };

  const getSFDCData = () => {
    if (typeof window !== 'undefined') {
      try {
        if (sfdcData?.data?.data) {
          const { data } = sfdcData.data;
          const newFormData: SupportFormData = { ...formData };
          Object.keys(data).forEach((key: string) => {
            if (
              [ 'products', 'productComponentVersions', 'packageNames' ].includes(
                key
              )
            ) {
              return;
            }

            // Add noneEntry before all products inside each deploymentType and
            // all productComponentVersions inside each product, if there's any product
            // or any productComponentVersions for that product to begin with
            if (key === 'deploymentTypes') {
              const deploymentTypes: DeploymentTypeWithProducts[] = data[key]
                .sort(sortDropdownItemsByProperty('sfdcIndex'))
                .map((deploymentType: DeploymentTypeWithProducts) => {
                  const products = (deploymentType.products ?? [])
                    .sort(sortDropdownItemsByProperty('sfdcIndex'))
                    .map((product: ProductWithDependentFields) => {
                      let sanitizedData = sanitizeChildrenWithNoneEntry(
                        product,
                        'productComponentVersions'
                      );
                      sanitizedData = sanitizeChildrenWithNoneEntry(
                        sanitizedData,
                        'packageNames'
                      );
                      return sanitizedData;
                    });

                  if (deploymentType.automationSuiteVersions) {
                    const automationSuiteVersions = deploymentType.automationSuiteVersions.sort(sortDropdownItemsByProperty('sfdcIndex'));
                    deploymentType = sanitizeChildrenWithNoneEntry(
                      deploymentType,
                      'automationSuiteVersions',
                      automationSuiteVersions
                    );
                  }

                  return sanitizeChildrenWithNoneEntry(
                    deploymentType,
                    'products',
                    products
                  );
                });

              newFormData['deploymentTypes'].push(...deploymentTypes);
              return;
            }

            newFormData[key as keyof SupportFormData].push(...data[key]);
          });
          return newFormData;
        }
      } catch (err) {
        console.log(err);
      }
    }
    return formData;
  };

  return (
    <>
      <Helmet>
        <title>
          {t(
            'add_support_request_page_title',
            'Add Support Request | Customer Portal'
          )}
        </title>
      </Helmet>
      {showPublicCaseConfirmation && (
        <BasicHero
          title={t('support_form_new_technical_support_request_title', 'New Technical Support Request')}
          backButton
          backButtonHref={!isPublic ? '/' : ''}
          onClick={isPublic ? () => window.location.reload() : undefined}
          smallFont
        />
      )}
      <styles.SupportCasePageContainer
        isPublic={isPublic}
      >
        <Container cssClass='NewSupportCasePage__container'>
          {
            !sfdcData?.data ? (
              <styles.loader>
                <Loader />
              </styles.loader>
            ) : (
              <div data-testid="CustomerPortalPageAddCase">
                <div>
                  {!showPublicCaseConfirmation && (
                    <styles.heroTitle>
                      {t('support_form_new_technical_support_request_title', 'New Technical Support Request')}
                    </styles.heroTitle>
                  )}
                  <NewSupportCase
                    {...getSFDCData()}
                    engine={caseAssistEngine.current}
                    controller={caseAssistController.current}
                    isLoading={!sfdcData?.data}
                    currentPage={currentPage}
                    totalPages={totalPages}
                    setCurrentPage={setCurrentPage}
                    showCancelConfirmation={showCancelConfirmation}
                    setShowCancelConfirmation={setShowCancelConfirmation}
                    isPublic={isPublic}
                    showPublicCaseConfirmation={showPublicCaseConfirmation}
                    setShowPublicCaseConfirmation={setShowPublicCaseConfirmation}
                  />
                </div>
              </div>
            )
          }
        </Container>
      </styles.SupportCasePageContainer>
    </>
  );
};

export default CustomerPortalPageAddCase;
