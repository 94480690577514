import {
  UserRole,
  UserStatus,
} from '@customer-portal/constants';
import axios from 'axios';
import React, {
  useContext,
  useState,
} from 'react';
// Translations
import {
  Trans,
  useTranslation,
} from 'react-i18next';

// Styles
import * as styles from '../../assets/css/AddUser';
// Images
import BluePlus from '../../assets/img/Blue_Add.svg';
import GreyPlus from '../../assets/img/Grey_Add.svg';
// Clients
import { axiosPost } from '../../client/axios';
// Constants
import {
  BACKEND_HOST_NAME,
  INVALID_USER_EMAIL_DOMAIN_URL,
} from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
import { CloudOrgContext } from '../../contexts/cloud-org';
// Interface
import type { IDataObject } from '../../interfaces/dataObject.interface';
// Utils
import { removeDuplicatesInArrayOfObjects } from '../../lib/array.utils';
// Context
import { StoreContext } from '../../store';
import {
  getPortalAPIUrl,
  isCloudEnv,
} from '../../utils/cloud';
// Components
import Button from '../Button/Button';
import AddUserRow from './Company-User-Form-Row';

interface IUsersToAdd {
  email: string;
  cpRole: string;
  permissions: any[];
  emailValidation: { isValid: boolean; cloudOrgSource: string; errorMsg: string };
  showInviteToCloudOrgCheckbox: boolean;
  checkedInviteToCloudOrg: boolean;
  permissionsValid: boolean;
}

export const AddUserForm = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');
  // Constants
  const initUsers: IUsersToAdd[] = [
    {
      email: '',
      cpRole: '',
      permissions: [],
      emailValidation: {
        isValid: true,
        cloudOrgSource: '',
        errorMsg: '',
      },
      showInviteToCloudOrgCheckbox: false,
      checkedInviteToCloudOrg: false,
      permissionsValid: false,
    },
  ];
  // Global state
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  // Local state
  const [ users, setUsers ] = useState<IUsersToAdd[]>(initUsers);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ addedUsers, setAddedUsers ] = useState(1);

  const isCloud = isCloudEnv();
  const {
    cloudOrgId, cloudOrgName, isCloudOrgAdmin,
  } = useContext(CloudOrgContext);

  // Methods
  const handleEmailChange = (e: any) => {
    const value = e.target.value;
    const userId = e.target.id;

    setUsers((prevState: any) => {
      const usersToInvite = [ ...prevState ];
      usersToInvite[userId].email = value.toLowerCase();
      return usersToInvite;
    });
  };

  const handleInviteToCloudCheckboxChange = (e: any) => {
    const userId = e.target.id;
    const checked = e.target.checked;

    setUsers((prevState: any) => {
      const usersToInvite = [ ...prevState ];
      usersToInvite[userId].checkedInviteToCloudOrg = !!checked;
      return usersToInvite;
    });
  };

  const handlePermissionsChange = (
    selectedUserType: string,
    permissionItems: any[],
    userId: number
  ) => {
    const permissionsArray: any = [];

    for (const permissionsItem of permissionItems) {
      for (const permission of permissionsItem.permissions) {
        if (permission.value) {
          permissionsArray.push(`${permissionsItem.id}${permission.name}`);
        }
      }
    }

    setUsers((prevState: IUsersToAdd[]) => {
      const usersToInvite: IUsersToAdd[] = [ ...prevState ];
      usersToInvite[userId].permissions = permissionsArray;
      usersToInvite[userId].cpRole = selectedUserType;
      usersToInvite[userId].permissionsValid = true;
      return usersToInvite;
    });
  };

  const handleEmailValidation = async (e: any) => {
    const userId: number = Number(e.target.id);
    const email: string = e.target.value;
    const result: {
      isValid: boolean;
      cloudOrgSource: string;
      errorMsg: string;
    } = {
      isValid: true,
      cloudOrgSource: '',
      errorMsg: '',
    };
    let showInviteToCloudOrgCheckbox = false;
    let checkedInviteToCloudOrg = false;

    // Test for regular email if company domain does not exist:
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const existingTeamUsers = state.teamUsers?.map((teamUser: any) => teamUser.Email) ?? [];
    const usersToBeInvited = users?.map((user: IUsersToAdd) => user.email) ?? [];
    const duplicateUserEmails = usersToBeInvited.filter((item, index) => usersToBeInvited.indexOf(item) != index);

    if (!emailRegex.test(email)) {
      result.isValid = false;
      result.errorMsg = t(
        'company_team_users_email_validation_invalid_email_msg',
        'This email is invalid!'
      );
    } else if (email === state.userEmail) {
      result.isValid = false;
      result.errorMsg = t(
        'company_team_users_email_validation_self_email_msg',
        'You cannot invite yourself!'
      );
    } else if (duplicateUserEmails.includes(email)) {
      result.isValid = false;
      result.errorMsg = t(
        'company_team_users_email_validation_already_inviting_msg',
        'Duplicate email listed, please remove one entry.'
      );
    } else if (existingTeamUsers.includes(email)) {
      result.isValid = false;
      result.errorMsg = t(
        'company_team_users_email_validation_already_exists_msg',
        'This user already exists and can be found in the Team Users list'
      );
    } else {
      const body = { email };
      try {
        const emailResult = await axiosPost(
          INVALID_USER_EMAIL_DOMAIN_URL,
          state.companyId,
          await getAccessToken(),
          body
        );

        if (emailResult?.data?.isInvalid) {
          result.isValid = false;
          result.errorMsg = t(
            'company_team_users_email_validation_business_email_msg',
            'Email must be a business email!'
          );
        }
      } catch (err) {
        console.log('Error checking email domain:', err);
        result.isValid = false;
        result.errorMsg = t(
          'company_team_users_check_email_domain_error',
          'Something went wrong while checking email domain!'
        );
      }

      try {
        // For non-Cloud org admins, check that email belongs to the Cloud org
        if (result.isValid && isCloud) {
          const bulkResolveByNameResponse = await axios.post(`${getPortalAPIUrl()}/identity/Directory/BulkResolveByName/${cloudOrgId}`,
            {
              entityNames: [ email ],
              entityType: 'user',
            },
            {
              headers: {
                Authorization: `Bearer ${await getAccessToken()}`,
                'Content-Type': 'application/json',
              },
            });

          if (bulkResolveByNameResponse.data?.[email]) {
            result.cloudOrgSource = bulkResolveByNameResponse.data[email].source;
          } else {
            if (isCloudOrgAdmin) {
              showInviteToCloudOrgCheckbox = true;
              checkedInviteToCloudOrg = true;
            } else {
              result.isValid = false;
              result.errorMsg = t(
                'company_team_users_email_validation_not_in_cloud_org_msg',
                'This user is not a member of the Cloud Organization! You must also be an administrator of the Cloud Organization to invite users from outside of the Cloud Organization.'
              );
            }
          }
        }
      } catch (err) {
        console.error('Error checking if user is a member of the Cloud Organization:', err);
        result.isValid = false;
        result.errorMsg = t(
          'company_team_users_email_validation_not_in_cloud_org_error',
          'An error occurred while checking if the user is a member of the Cloud Organization!'
        );
      }
    }

    setUsers((prevState: IUsersToAdd[]) => {
      const usersToInvite: IUsersToAdd[] = [ ...prevState ];
      usersToInvite[userId].emailValidation = { ...result };
      usersToInvite[userId].showInviteToCloudOrgCheckbox = showInviteToCloudOrgCheckbox;
      usersToInvite[userId].checkedInviteToCloudOrg = checkedInviteToCloudOrg;
      return usersToInvite;
    });
  };

  const handleRowDelete = (e: any) => {
    const userId = e.target.id;

    setAddedUsers((prevState: number) => prevState - 1);
    setUsers((prevState: IUsersToAdd[]) => {
      const usersToInvite: IUsersToAdd[] = [ ...prevState ];
      usersToInvite.splice(userId, 1);
      return usersToInvite;
    });
  };

  const handleAddFields = () => {
    const newUser: IUsersToAdd = {
      email: '',
      cpRole: '',
      permissions: [],
      emailValidation: {
        isValid: true,
        cloudOrgSource: '',
        errorMsg: '',
      },
      showInviteToCloudOrgCheckbox: false,
      checkedInviteToCloudOrg: false,
      permissionsValid: false,
    };

    if (addedUsers < 5) {
      setAddedUsers((prevState: number) => prevState + 1);
      setUsers((prevState: IUsersToAdd[]) => {
        const usersToInvite: IUsersToAdd[] = [ ...prevState ];
        return [ ...usersToInvite, newUser ];
      });
    }
  };

  const isValidForm = () => {
    let errors: number = 0;

    users.forEach((user: IUsersToAdd) => {
      if (user.email.toLowerCase() !== '') {
        if (
          !user.emailValidation.isValid ||
          !user.permissionsValid
        ) {
          errors++;
        }
      } else {
        errors++;
      }
    });

    return errors === 0 ? true : false;
  };

  // Send invite to users
  const sendInvite = async () => {
    const newTeamUsers: any[] = [];
    let inviteResults: any;
    const body: any = { users: [] };

    setIsLoading(true);

    users.forEach((user: IUsersToAdd) => {
      // Format the user object that will be added to the users array
      const userItem = {
        email: user.email.toLowerCase(),
        cpRole: user.cpRole, // CP Admin can invite a regular or admin user, so the role can be either "cp-admin" or "cp-user"
        permissions: user.permissions, // the permissions should be set based on the user input
        invitedToCloudOrg: user.checkedInviteToCloudOrg,
      };

      // Format the user object that will be added to the global state
      const teamUsersItem = {
        Name: '.....',
        Email: userItem.email,
        status: UserStatus.PENDING,
        cpRole: userItem.cpRole,
        permissions: userItem.permissions,
        CP_User__c: true,
        CP_Admin__c: userItem.cpRole === UserRole.CP_ADMIN ? true : false,
        userAccounts: [ state.companyId ],
        createdBy: state.userName,
        createdOn: new Date().toISOString(),
        // Either use existing Cloud org source, or set to 'local' if they will now be invited to the Cloud org
        ...(isCloud && (user.emailValidation?.cloudOrgSource || userItem.invitedToCloudOrg)) &&
          { cloudOrgProfile: { source: user.emailValidation?.cloudOrgSource || 'local' } },
      };

      body.users.push(userItem);

      newTeamUsers.push(teamUsersItem);
    });

    const teamUserState: any = state.teamUsers
      ? [ ...state.teamUsers, ...newTeamUsers ]
      : newTeamUsers;

    // Send user invites
    try {
      inviteResults = await axiosPost(
        `${BACKEND_HOST_NAME}/api/v1/user/invite`,
        state.companyId,
        await getAccessToken(),
        body
      );
    } catch (err) {
      console.log(err);
    }

    const invites = users.length;

    const validResponses: string[] = [];
    const invalidResponses: string[] = [];
    const usersNotInvited: string[] = [];

    if (inviteResults?.data?.data) {
      inviteResults.data.data.forEach((response: IDataObject, i: number) => {
        if (response.status === 'success') {
          validResponses.push(response.email);
        } else {
          invalidResponses.push(response.message);
          usersNotInvited.push(response.email);
        }
      });

      // Send Cloud org invites to successfully-invited users who are not in the Cloud org and selected to be invited
      if (isCloud && isCloudOrgAdmin) {
        try {
          const usersToInviteToCloudOrg = validResponses.filter((email: string) => {
            const user = users.find((u: IUsersToAdd) => u.email === email);
            return user?.checkedInviteToCloudOrg;
          });

          if (usersToInviteToCloudOrg.length) {
            await axios.post(`${getPortalAPIUrl()}/identity/User/InviteUsers`,
              {
                inviteData: usersToInviteToCloudOrg.map((email: string) => ({
                  name: email.split('@')[0],
                  email,
                })),
              },
              {
                headers: {
                  Authorization: `Bearer ${await getAccessToken()}`,
                  'Content-Type': 'application/json',
                },
              });
          }
        } catch (e) {
          console.error('Error inviting users to Cloud org:', e);
        }
      }

      if (validResponses.length === invites) {
        const bannerMsg: string =
          invites > 1
            ? t(
              'company_team_users_invite_sent_more_invites_success_msg',
              'Awesome! The invites have been sent.'
            )
            : t(
              'company_team_users_invite_sent_one_invite_success_msg',
              'Awesome! The invite has been sent.'
            );

        dispatch({
          type: 'setBannerType',
          payload: 'success',
        });
        dispatch({
          type: 'setBannerMsg',
          payload: bannerMsg,
        });

        dispatch({
          type: 'setTeamUsers',
          payload: teamUserState,
        });
      } else {
        if (invalidResponses.length > 0 && validResponses.length > 0) {
          dispatch({
            type: 'setShowInviteResultsModal',
            payload: true,
          });

          dispatch({
            type: 'setShowInviteResultsMessage',
            payload: t(
              'company_team_users_invite_sent_error_some_users_not_invited_msg',
              `Some users ${usersNotInvited.toString()} was not invited. Error: ${invalidResponses}`,
              {
                users_not_invited: usersNotInvited.toString(),
                errors: invalidResponses,
              }
            ),
          });

          dispatch({
            type: 'setTeamUsers',
            payload: removeDuplicatesInArrayOfObjects(teamUserState, 'Email'),
          });
        } else if (invalidResponses.length > 0 && validResponses.length === 0) {
          dispatch({
            type: 'setShowInviteResultsModal',
            payload: true,
          });

          dispatch({
            type: 'setShowInviteResultsMessage',
            payload: invalidResponses.toString(),
          });
        } else {
          const bannerMsg: string =
            invites > 1
              ? t(
                'company_team_users_invite_sent_one_invite_error_some_users_not_invited_msg_case2',
                'Something went wrong while saving the invites.'
              )
              : t(
                'company_team_users_invite_sent_more_invites_error_some_users_not_invited_msg_case2',
                'Something went wrong while saving the invite.'
              );

          dispatch({
            type: 'setBannerType',
            payload: 'error',
          });
          dispatch({
            type: 'setBannerMsg',
            payload: bannerMsg,
          });
        }
      }
    } else if (inviteResults?.data && inviteResults.data.status === 'failed') {
      dispatch({
        type: 'setBannerType',
        payload: inviteResults.data.type,
      });
      dispatch({
        type: 'setBannerMsg',
        payload: inviteResults.data.message,
      });
    } else {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });

      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_team_users_invite_sent_error_msg',
          'Something went wrong, and the invitation was not sent!'
        ),
      });
    }

    setIsLoading(false);
    props.handleModalClose();
  };

  return (
    <styles.AddUserFormContainer data-testid="CompanyAddUserForm__wrapper">
      {users.map((userObj: IUsersToAdd, i: number) => (
        <AddUserRow
          onEmailChange={handleEmailChange}
          onPermissionsChange={handlePermissionsChange}
          onEmailBlur={handleEmailValidation}
          handleRowDelete={handleRowDelete}
          emailValidation={userObj.emailValidation}
          validUserCheckbox={
            userObj.showInviteToCloudOrgCheckbox ? {
              checked: userObj.checkedInviteToCloudOrg,
              label: t('company_team_users_email_validation_cloud_org_admin_not_in_cloud_org_msg', 'This user is not a member of your Cloud Organization. Invite them to the \'Everyone\' group in the Cloud Organization?'),
              onChange: handleInviteToCloudCheckboxChange,
            } :
              undefined
          }
          permissionsValid={userObj.permissionsValid}
          email={userObj.email.toLowerCase()}
          permissions={userObj.permissions}
          key={i}
          user={i}
        />
      ))}
      <div
        className={
          addedUsers < 5
            ? 'AddUserForm__Add-Another'
            : 'AddUserForm__Add-Another AddUserForm__Add-Another--disabled'
        }
        onClick={handleAddFields}
        data-testid="CompanyAddUserForm__addUserRow"
      >
        <img
          src={addedUsers < 5 ? BluePlus : GreyPlus}
          alt="Plus Icon"
          className="AddUserForm__Plus-Icon"
        />
        <p className="Bold">
          {t('company_team_invite_user_modal_add_user', 'Add Another')}
        </p>
      </div>

      <Button
        className="AddUserForm__Button"
        isValid={isValidForm()}
        isLoading={isLoading}
        text={t(
          'company_team_invite_user_modal_send_invite_btn',
          'Send Invite'
        )}
        onClick={sendInvite}
      />
      {isCloud && !isCloudOrgAdmin &&
        <p className="AddUserForm__CloudOrgInviteReminder">
          <Trans
            t={t}
            i18nKey='company_team_invite_user_modal_non_cloud_org_admin_invite_msg'
            defaults="Note: You must also be an administrator of your Cloud Organization <b>{{ cloudOrgName }}</b> to invite users from outside of the Cloud Organization."
            components={{ b: <b /> }}
            values={{ cloudOrgName }}
          />
        </p>}
    </styles.AddUserFormContainer>
  );
};

export default AddUserForm;
