import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
// Components
import { Redirect } from 'react-router-dom';

import { axiosGet } from '../../client/axios';
import { fetchCompanyLicenses } from '../../client/licenses';
import ConfirmationModal from '../../components/ConfirmationModal';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import Loader from '../../components/CustomerPortal-Loader';
import RequestForQuoteBundleOptions from '../../components/requestForQuote/RequestForQuoteBundleOptions';
import {
  DEAL_TYPE_ELA,
  DEAL_TYPE_HAPO,
  DEDICATED_AUTOMATION_CLOUD,
} from '../../constants/hapo.constants';
import {
  HAPO_CLICK_LICENSE_IN_CART_VIEW,
  HAPO_CONTINUE_SHOPPING,
  HAPO_DISPLAY_ANNOUNCEMENT_MODAL,
  HAPO_LICENSE_NICKNAMES_LOADED,
  HAPO_REMOVE_1ST_LEVEL_LICENSE,
  HAPO_REMOVE_2ND_LEVEL_LICENSE,
  HAPO_UPDATE_1ST_LEVEL_LICENSE_QUANTITY,
  HAPO_UPDATE_2ND_LEVEL_LICENSE_QUANTITY,
} from '../../constants/hapoActions.constants';
import { HAPO_PRODUCTS_URL } from '../../constants/network.constants';
import { ENTERPRISE_AGREEMENT_PROVISION_LICENSES } from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import type { IHAPODataConfig } from '../../interfaces/hapo.interface';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import {
  getInvalidSKUsFromCart,
  getLicenseAvailableQuantity,
  handleAddLicenseToCart,
  handleAddOrchestratorToCart,
  handleOnPremOrchestratorChange,
  handleProductTypeChange,
  handleQuantityUpdate,
  handleRemoveLicenseFromCart,
  handleResetNewOrchestrator,
  handleSelectExistingOrchestratorDropdown,
  isCartInvalidAgainstConfig,
} from '../../lib/hapo.utils';
import {
  canShowLicense,
  getActiveAutomationSuiteBundles,
  getActiveBundles,
  getActiveCloudBundles,
  getActiveDedicatedCloudBundles,
  getActiveProcessMiningBundles,
  getAvailableNewLicensesArr,
  getHAPOELAUTOBundles,
  getStandAloneOrchestratorBundles,
  isCartProductsInvalid,
  isProcessMiningServer,
  trimSKUName,
} from '../../lib/requestForQuote.utils';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import type { HapoSkuType } from '../../types/hapo.types';
import { featureFlag } from '../../utils/featureFlag';
// styles
import * as styles from './../../assets/css/RequestForQuote/RequestForQuote';
import Container from './../../components/CustomerPortal-New-Container';
import NoCompanyId from './../../components/CustomerPortal-NoCompanyId';
import BrowseLicenseContainer from './../../components/requestForQuote/BrowseProducts/CustomerPortal-RequestForQuote-BrowseLicenseContainer';
import CartPanel from './../../components/requestForQuote/CartPanel';
import Header from './../../components/requestForQuote/CustomerPortal-RequestForQuote-Header';
import RFQ from './../../constants/requestForQuote.constants';
// Data
import { StoreContext } from './../../store';

const CustomerPortalPageHapoShop = (props: any) => {
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const { t } = useTranslation('common');

  const isViewLicenseAllowed = UserPermissionsHelper.isViewHAPOLicenseAllowed();
  const isAddToCartAllowed = UserPermissionsHelper.isEditHAPOLicenseAllowed();

  // State
  const isCartEmpty = !(state.hapoProducts?.length ?? 0);
  const availableLicensesIds: string[] = getActiveBundles(
    state.companyLicenses?.boonLicense?.licenses || []
  ).map(license => license.licenseCode);
  const [ redirectToCart, setRedirectToCart ] = useState<boolean>(false);
  const [ pageLoading, setPageLoading ] = useState(true);
  const [ buttonLoading, setButtonLoading ] = useState(false);
  const [ invalidProducts, setInvalidProducts ] = useState<any[]>([]);
  const [ continueButtonDisabled, setContinueButtonDisabled ] = useState(
    isCartEmpty || !isAddToCartAllowed
  );
  const [ licenseInfo, setLicenseInfo ] = useState<{
    licenses: HapoSkuType[];
    config: IHAPODataConfig;
    dealType: string;
    isFlex: boolean;
  }>({
    licenses: [],
    config: {
      individual_skus: null,
      groups: null,
    },
    dealType: DEAL_TYPE_HAPO,
    isFlex: false,
  });
  const [ insufficientHapoQuantities, setInsufficientHapoQuantities ] = useState(
    false
  );
  const [ isEntityManagementEnabled, setIsEntityManagementEnabled ] = useState<boolean>(false);

  const confirmationModalInfo = state.hapoAnnouncementModalInfo;
  const confirmationModalMessage =
    confirmationModalInfo?.action === 'add_product'
      ? t(confirmationModalInfo?.message || '')
      : '';
  const openConfirmationModal = Boolean(confirmationModalMessage);

  useTrackPageViewEvent(ENTERPRISE_AGREEMENT_PROVISION_LICENSES);

  const fetchFeatureFlags = async () => {
    setIsEntityManagementEnabled(
      await featureFlag.isEntityManagementEnabled(
        state.companyId,
        await getAccessToken()
      )
    );
  };

  const handleCloseAnnouncementModal = () => {
    dispatch({
      type: HAPO_DISPLAY_ANNOUNCEMENT_MODAL,
      payload: {},
    });
  };

  const handleConfirmAnnouncementModal = () => {
    const skuId = confirmationModalInfo?.sku_id;
    const skuName = confirmationModalInfo?.sku_name;
    const skuMinQty = confirmationModalInfo?.min_quantity;
    const skuProductCode = confirmationModalInfo?.product_code;
    if (skuId && skuName && skuMinQty && skuProductCode) {
      handleAddLicenseToCart(
        {
          _id: skuId,
          sku_name: skuName,
          min_quantity: skuMinQty,
          product_code: skuProductCode,
        },
        state.hapoSelectedCategory,
        dispatch,
        state.hapoLastSelectedOrchestrator,
        true
      );
      handleCloseAnnouncementModal();
    }
  };

  // Function to generate the pills
  const getProductTypes = (licenses: HapoSkuType[], contractDetails: any) => {
    // Decide if there are any process mining skus
    const hasProcessMiningSkuInCatalog = licenses.some(sku =>
      canShowLicense('ProcessMining', sku.display)
    );

    const hasAutomationCloudSkuInCatalog = licenses.some(sku =>
      canShowLicense('Cloud', sku.display)
    );

    const hasDedicatedAutomationCloudSkuInCatalog = licenses.some(sku =>
      canShowLicense('Dedicated Cloud', sku.display)
    );
    const hasAutomationSuiteSkuInCatalog = licenses.some(sku =>
      canShowLicense('Automation Suite', sku.display)
    );

    const hasOnPremSkuInCatalog = licenses.some(
      sku =>
        canShowLicense('Basic', sku.display) ||
        canShowLicense('NonProduction', sku.display) ||
        canShowLicense('Standard', sku.display)
    );

    const hasStandaloneSkuInCatalog = licenses.some(sku =>
      canShowLicense('No Orchestrator', sku.display)
    );

    const productTypes: Array<{
      productType: typeof RFQ.productTypes[keyof typeof RFQ.productTypes];
      labelText: string;
    }> = [];

    if (hasOnPremSkuInCatalog) {
      productTypes.push({
        productType: RFQ.productTypes.onPrem,
        labelText: RFQ.productLabelText.onPrem,
      });
    }

    if (hasAutomationSuiteSkuInCatalog) {
      productTypes.push({
        productType: RFQ.productTypes.automationSuite,
        labelText: RFQ.productLabelText.automationSuite,
      });
    }

    if (hasAutomationCloudSkuInCatalog) {
      productTypes.push({
        productType: RFQ.productTypes.cloud,
        labelText: RFQ.productLabelText.cloud,
      });
    }

    // Dedicated Automation Cloud is only available for certain ELA contracts
    if (hasDedicatedAutomationCloudSkuInCatalog &&
      contractDetails.dealType === DEAL_TYPE_ELA &&
      Object.keys(contractDetails.groups).includes(DEDICATED_AUTOMATION_CLOUD)) {
      productTypes.push({
        productType: RFQ.productTypes.dedicatedCloud,
        labelText: RFQ.productLabelText.dedicatedCloud,
      });
    }

    if (hasStandaloneSkuInCatalog) {
      productTypes.push({
        productType: RFQ.productTypes.standalone,
        labelText: RFQ.productLabelText.standalone,
      });
    }

    if (hasProcessMiningSkuInCatalog) {
      productTypes.push({
        productType: RFQ.productTypes.processMining,
        labelText: RFQ.productLabelText.processMining,
      });
    }

    return productTypes;
  };

  useEffect(() => {
    fetchFeatureFlags();
  }, []);

  useEffect(() => {
    const fetchCatalog = async () => {
      try {
        const licenseAndCatalog = await axiosGet(
          HAPO_PRODUCTS_URL,
          state.companyId,
          await getAccessToken()
        );

        // Return early if the format of data is not as expected
        if (!Array.isArray(licenseAndCatalog.data.licenses)) {
          return;
        }

        const {
          config: hapoConfig = {},
          deal_type: dealType = DEAL_TYPE_HAPO,
        } = licenseAndCatalog.data;

        let isFlex = false;
        licenseAndCatalog.data.licenses?.forEach((sku: HapoSkuType) => {
          if (/Flex/.test(sku.sku_name)) {
            isFlex = true;
          }
        });

        const licenses =
          licenseAndCatalog.data.licenses?.map((returnedSku: HapoSkuType) => ({
            ...returnedSku,
            sku_name: trimSKUName(returnedSku.sku_name),
            available_quantity: getLicenseAvailableQuantity(
              returnedSku.product_code,
              hapoConfig
            ),
            // Replace all display_hapo values into display
            display: { ...returnedSku.display_hapo },
          })) ?? [];

        setLicenseInfo({
          licenses,
          config: hapoConfig,
          dealType,
          isFlex,
        });
        const keys = Object.keys(hapoConfig.groups);
        // Figure out which pill should be highlighted first
        const provisionPills = getProductTypes(licenses, {
          ...hapoConfig,
          dealType,
        });
        if (provisionPills.length > 0) {
          handleProductTypeChange(dispatch, provisionPills[0].productType);
        }
      } catch (e) {
        console.log(e);
      }
    };

    const getCompanyLicenses = async (companyId: string) => {
      try {
        const resultData: any = await fetchCompanyLicenses(
          companyId,
          await getAccessToken(),
          state.userId,
          dispatch
        );
        return resultData.licenses ?? [];
      } catch (e) {
        console.log(`Unable to retrieve company licenses: ${e.message}`);
      }
    };

    CustomerPortalGoogleAnalyticsPageView(
      'Enterprise Agreement Provision Licenses'
    );

    window.addEventListener('beforeunload', handleCloseAnnouncementModal);

    Promise.all([ fetchCatalog(), getCompanyLicenses(state.companyId) ])
      .then(([ temp, customNamesAndNotes ]) => {
        if (
          Array.isArray(customNamesAndNotes) &&
          customNamesAndNotes.length > 0
        ) {
          // Update cart panel nickname
          dispatch({
            type: HAPO_LICENSE_NICKNAMES_LOADED,
            payload: customNamesAndNotes,
          });
        }
      })
      .finally(() => {
        setPageLoading(false);
      });

    // Cleanup - when unmount, reset lastSelectedOrch
    return () => {
      dispatch({
        type: HAPO_CONTINUE_SHOPPING,
        payload: null,
      });

      // Reset and close the announcement modal
      handleCloseAnnouncementModal();

      window.removeEventListener('beforeunload', handleCloseAnnouncementModal);
    };
  }, []);

  useEffect(() => {
    if (
      Array.isArray(licenseInfo.licenses) &&
      licenseInfo.licenses.length > 0
    ) {

      const checkForValidation = async () => {
        setButtonLoading(!continueButtonDisabled);
        const isHapoConfigInsufficient = isCartInvalidAgainstConfig(
          state.hapoProducts,
          licenseInfo.config,
          licenseInfo.dealType,
        );

        setInvalidProducts([]);
        const invalidSkus = isCartEmpty ? [] : await getInvalidSKUsFromCart(
          state.hapoProducts,
          state.companyId,
          await getAccessToken(),
        );

        setInsufficientHapoQuantities(isHapoConfigInsufficient);
        setInvalidProducts(invalidSkus);
        setButtonLoading(false);
        if (
          isCartEmpty ||
          !isAddToCartAllowed ||
          isCartProductsInvalid(
            state.hapoProducts,
            licenseInfo.licenses,
            availableLicensesIds
          ) ||
          isHapoConfigInsufficient ||
          invalidSkus.length > 0
        ) {
          setContinueButtonDisabled(true);
        } else {
          setContinueButtonDisabled(false);
        }
      };

      checkForValidation();
    }
  }, [ licenseInfo.licenses, state.hapoProducts ]);

  /**
   * ************
   *
   * Cart Panel
   *
   * ************
   *  */

  const handleCreateCartClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setButtonLoading(true);
    setRedirectToCart(true);
  };

  /**
   * @param parentOrchId: The generated _id of orch
   * @param productId: The actual _id of license
   */
  const handleRemoveChildItem = (parentOrchId: string, productId: string) => (
    event: React.MouseEvent<HTMLParagraphElement>
  ) => {
    event.stopPropagation();
    if (!parentOrchId || !productId) {
      return;
    }
    dispatch({
      type: HAPO_REMOVE_2ND_LEVEL_LICENSE,
      payload: {
        parent_id: parentOrchId,
        sku_id: productId,
      },
    });
  };

  /**
   *
   * @param cartProductId: The generated _id of the product in cart
   * @param productId : The sku_id of the product (actual license)
   */
  const handleRemoveTopLevel = (cartProductId?: string, productId?: string) => (
    event: React.MouseEvent<HTMLParagraphElement>
  ) => {
    event.stopPropagation();
    const payload = cartProductId
      ? { _id: cartProductId }
      : productId
        ? { sku_id: productId }
        : undefined;
    dispatch({
      type: HAPO_REMOVE_1ST_LEVEL_LICENSE,
      payload,
    });
  };

  const handlePanelLicenseClick = (cartProductId?: string | undefined) => (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    dispatch({
      type: HAPO_CLICK_LICENSE_IN_CART_VIEW,
      payload: { _id: cartProductId },
    });
  };

  /**
   * If changing quantity of 2nd level child, must pass in parentOrchId
   * @param productId: The sku_id of the product (actual license)
   * @param parentOrchId: The generated _id of orch in products array in cart
   */
  const handleQuantityChange = (productId: string, parentOrchId?: string) => (
    event: any // React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.stopPropagation();
    const quantity = parseInt(event.target.value) || 0;

    if (parentOrchId) {
      dispatch({
        type: HAPO_UPDATE_2ND_LEVEL_LICENSE_QUANTITY,
        payload: {
          parent_id: parentOrchId,
          sku_id: productId,
          quantity,
        },
      });
    } else {
      dispatch({
        type: HAPO_UPDATE_1ST_LEVEL_LICENSE_QUANTITY,
        payload: {
          sku_id: productId,
          quantity,
        },
      });
    }
    event.target.value = quantity;
  };

  if (redirectToCart) {
    return <Redirect to="/hapo/checkout" />;
  }

  /* Return if no company id selected */
  if (state.companyId === '') {
    return (
      <>
        <Helmet>
          <title>
            {t('hapo_page_title', 'Provision Licenses | Customer Portal')}
          </title>
        </Helmet>
        <NoCompanyId />
      </>
    );
  } else if (!isViewLicenseAllowed) {
    return <Redirect to="/unauthorized" />;
  } else if (pageLoading) {
    return (<styles.LoaderContainer>
      <Loader />
    </styles.LoaderContainer>);
  }

  return (
    <>
      <Helmet>
        <title>
          {t('hapo_page_title', 'Provision Licenses | Customer Portal')}
        </title>
      </Helmet>

      <styles.RequestForQuotePageContainer data-testid="HAPOPageContainer">
        <styles.RequestForQuote>
          <Header
            pageTitle={t('hapo_hero_title', 'Provision Licenses')}
            selectedProductType={state.hapoSelectedCategory}
            productTypesDisplayed={getProductTypes(licenseInfo.licenses, {
              ...licenseInfo.config,
              dealType: licenseInfo.dealType,
            })}
            handleProductTypeChange={handleProductTypeChange}
          />

          {/* Select existing orchestrator */}
          {state.hapoSelectedCategory === RFQ.productTypes.onPrem && (
            <RequestForQuoteBundleOptions
              addToExistingBundleText={t(
                'rfq_add_existing_orchestrator_label',
                'Add products to one of your existing Orchestrators'
              )}
              newBundleRadioLabelText={t(
                'rfq_new_orchestrator_label',
                'Add new products to a new Orchestrator'
              )}
              noExistingBundlesText={t(
                'rfq_no_available_orchestrator_label',
                'No available orchestrators!'
              )}
              selectBundlePromptText={t(
                'rfq_select_orchestrator_label',
                'Select an Orchestrator'
              )}
              bundleType="orchestrator"
              availableNewBundles={licenseInfo.licenses.filter(license => {
                const availableQuantity = getLicenseAvailableQuantity(
                  license.product_code,
                  licenseInfo.config
                );

                return (
                  license.tag === RFQ.productTags.orchestrators.fallbackText &&
                  (licenseInfo.isFlex ? true : availableQuantity > 0)
                );
              })}
              existingBundles={getHAPOELAUTOBundles(
                getStandAloneOrchestratorBundles(
                  state.companyLicenses?.boonLicense?.licenses
                )
              )}
              cartProducts={state.hapoProducts}
              lastSelectedOrchestrator={state.hapoLastSelectedOrchestrator}
              handleOnPremBundleChange={handleOnPremOrchestratorChange}
              handleAddBundleToCart={handleAddOrchestratorToCart}
              handleResetNewBundle={handleResetNewOrchestrator}
              handleSelectExistingBundleDropdown={
                handleSelectExistingOrchestratorDropdown
              }
            />
          )}

          {/* Select existing Automation Suite */}
          {state.hapoSelectedCategory === RFQ.productTypes.automationSuite && (
            <RequestForQuoteBundleOptions
              addToExistingBundleText={t(
                'hapo_add_existing_automation_suite_label',
                'Add products to one of your existing Automation Suites'
              )}
              newBundleRadioLabelText={t(
                'hapo_new_automation_suite_label',
                'Buy products for a new Automation Suite'
              )}
              noExistingBundlesText={t(
                'hapo_no_available_automation_suite_label',
                'No available Automation Suite!'
              )}
              selectBundlePromptText={t(
                'hapo_select_automation_suite_label',
                'Select an Automation Suite'
              )}
              bundleType="automation suite"
              // Temporarily only empty array since no new automation suites are allowed
              availableNewBundles={[]}
              existingBundles={getHAPOELAUTOBundles(
                getActiveAutomationSuiteBundles(
                  state.companyLicenses?.boonLicense?.licenses
                )
              )}
              cartProducts={state.hapoProducts}
              lastSelectedOrchestrator={state.hapoLastSelectedOrchestrator}
              handleOnPremBundleChange={handleOnPremOrchestratorChange}
              handleAddBundleToCart={handleAddOrchestratorToCart}
              handleResetNewBundle={handleResetNewOrchestrator}
              handleSelectExistingBundleDropdown={
                handleSelectExistingOrchestratorDropdown
              }
            />
          )}

          {/* Select existing Automation Cloud */}
          {state.hapoSelectedCategory === RFQ.productTypes.cloud && (
            <RequestForQuoteBundleOptions
              addToExistingBundleText={t(
                'hapo_add_existing_cloud_label',
                'Add products to one of your existing Automation Clouds'
              )}
              newBundleRadioLabelText={t(
                'hapo_new_cloud_label',
                'Buy products for a new Automation Cloud'
              )}
              noExistingBundlesText={t(
                'hapo_no_available_cloud_label',
                'No available Automation Clouds!'
              )}
              selectBundlePromptText={t(
                'hapo_select_cloud_label',
                'Select an Automation Cloud'
              )}
              bundleType="cloud"
              // Temporarily only empty array since no new clouds are allowed
              availableNewBundles={[]}
              existingBundles={getHAPOELAUTOBundles(
                getActiveCloudBundles(state.companyLicenses?.boonLicense?.licenses)
              )}
              cartProducts={state.hapoProducts}
              lastSelectedOrchestrator={state.hapoLastSelectedOrchestrator}
              handleOnPremBundleChange={handleOnPremOrchestratorChange}
              handleAddBundleToCart={handleAddOrchestratorToCart}
              handleResetNewBundle={handleResetNewOrchestrator}
              handleSelectExistingBundleDropdown={
                handleSelectExistingOrchestratorDropdown
              }
            />
          )}

          {/* Select Automation Cloud - Dedicated */}
          {state.hapoSelectedCategory === RFQ.productTypes.dedicatedCloud && (
            <RequestForQuoteBundleOptions
              addToExistingBundleText={t(
                'hapo_add_existing_dedicated_cloud_label',
                'Add products to one of your existing Automation Clouds'
              )}
              newBundleRadioLabelText={undefined}
              noExistingBundlesText={t(
                'hapo_no_available_dedicated_cloud_label',
                'No available Dedicated Automation Clouds!'
              )}
              selectBundlePromptText={t(
                'hapo_select_dedicated_cloud_label',
                'Select a Dedicated Automation Cloud'
              )}
              bundleType="dedicated automation cloud"
              // Temporarily only empty array since no new clouds are allowed
              availableNewBundles={[]}
              existingBundles={getHAPOELAUTOBundles(
                getActiveDedicatedCloudBundles(state.companyLicenses?.boonLicense?.licenses)
              )}
              cartProducts={state.hapoProducts}
              lastSelectedOrchestrator={state.hapoLastSelectedOrchestrator}
              handleOnPremBundleChange={handleOnPremOrchestratorChange}
              handleAddBundleToCart={handleAddOrchestratorToCart}
              handleResetNewBundle={handleResetNewOrchestrator}
              handleSelectExistingBundleDropdown={
                handleSelectExistingOrchestratorDropdown
              }
            />
          )}

          {/* Select Process Mining Servers */}
          {state.hapoSelectedCategory === RFQ.productTypes.processMining && (
            <RequestForQuoteBundleOptions
              addToExistingBundleText={t(
                'hapo_add_existing_pm_server_label',
                'Add products to one of your existing Process Mining Servers'
              )}
              newBundleRadioLabelText={t(
                'hapo_new_pm_server_label',
                'Provision products for a New Process Mining Server'
              )}
              noExistingBundlesText={t(
                'hapo_no_available_pm_server_label',
                'No available Process Mining Servers!'
              )}
              selectBundlePromptText={t(
                'hapo_select_pm_server_label',
                'Select a Process Mining Server'
              )}
              bundleType="pm server"
              availableNewBundles={licenseInfo.licenses.filter(license => {
                if (isProcessMiningServer(license.sku_name)) {
                  const availableQuantity = getLicenseAvailableQuantity(
                    license.product_code,
                    licenseInfo.config
                  );
                  return licenseInfo.isFlex ? true : availableQuantity > 0;
                }
                return false;
              })}
              existingBundles={getHAPOELAUTOBundles(
                getActiveProcessMiningBundles(
                  state.companyLicenses?.boonLicense?.licenses
                )
              )}
              cartProducts={state.hapoProducts}
              lastSelectedOrchestrator={state.hapoLastSelectedOrchestrator}
              handleOnPremBundleChange={handleOnPremOrchestratorChange}
              handleAddBundleToCart={handleAddOrchestratorToCart}
              handleResetNewBundle={handleResetNewOrchestrator}
              handleSelectExistingBundleDropdown={
                handleSelectExistingOrchestratorDropdown
              }
            />
          )}

          {/* Browse Products */}
          <div
            className="RequestForQuote__browseProductsWrapper"
            data-testid="RequestForQuote__browseProductsWrapper"
          >
            <Container>
              {state.hapoSelectedCategory && (
                <BrowseLicenseContainer
                  licenses={getAvailableNewLicensesArr(
                    state.hapoSelectedCategory,
                    licenseInfo.licenses,
                    state.hapoLastSelectedOrchestrator,
                    true,
                    true
                  )}
                  selectedCategory={state.hapoSelectedCategory}
                  lastSelectedOrchestrator={state.hapoLastSelectedOrchestrator}
                  cartProducts={state.hapoProducts}
                  isAddToCartAllowed={isAddToCartAllowed}
                  requiresAutomationCloudBundle
                  requiresAutomationSuiteBundle
                  handleQuantityUpdate={handleQuantityUpdate}
                  handleRemoveLicenseFromCart={handleRemoveLicenseFromCart}
                  handleAddLicenseToCart={handleAddLicenseToCart}
                />
              )}
            </Container>
          </div>
        </styles.RequestForQuote>
        <CartPanel
          isEntityManagementEnabled={isEntityManagementEnabled}
          licenses={licenseInfo.licenses}
          isCartEmpty={isCartEmpty}
          cartEmptyText={
            state.isHapoEnabled
              ? t(
                'hapo_cart_empty_label',
                `Your UiPath Enterprise Agreement Licenses cart is empty. Select a few products to
          fill it up.`
              )
              : t(
                'uto_cart_empty_label',
                `Your UiPath Tailored Offering cart is empty. Select a few products to
          fill it up.`
              )
          }
          cartProducts={state.hapoProducts}
          continueButtonDisabled={continueButtonDisabled}
          buttonLoading={buttonLoading}
          availableActiveOrchestratorsId={availableLicensesIds}
          isInvalidHapoConfig={insufficientHapoQuantities}
          invalidProducts={invalidProducts}
          handleCreateCartClick={handleCreateCartClick}
          handlePanelLicenseClick={handlePanelLicenseClick}
          handleRemoveTopLevel={handleRemoveTopLevel}
          handleRemoveChildItem={handleRemoveChildItem}
          handleQuantityChange={handleQuantityChange}
        />

        <ConfirmationModal
          modalHeading={t('warning', 'Warning')}
          primaryButtonText={t('confirm', 'Confirm')}
          secondaryButtontext={t('cancel', 'Cancel')}
          message={confirmationModalMessage}
          open={openConfirmationModal}
          handleConfirm={handleConfirmAnnouncementModal}
          handleClose={handleCloseAnnouncementModal}
        />
      </styles.RequestForQuotePageContainer>
    </>
  );
};

export default CustomerPortalPageHapoShop;
