import {
  UserRole,
  UserStatus,
} from '@customer-portal/constants';
import React, {
  useContext,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Styles
import AddIcon from '../../../assets/img/svg/action_icons/Plus.svg';
import { axiosPost } from '../../../client/axios';
import {
  BACKEND_HOST_NAME,
  VIEW_AVATAR_URL,
} from '../../../constants/network.constants';
// Constants
import { COMPANY_TEAM_USERS } from '../../../constants/telemetry.constants';
import {
  ACTIVATE_USER_CLOUD_TOOLTIP,
  ACTIVATE_USER_LABEL,
  DELETE_INVITE_CLOUD_TOOLTIP,
  DELETE_INVITE_LABEL,
  DISABLE_USER_CLOUD_TOOLTIP,
  DISABLE_USER_LABEL,
  EDIT_PERMISSIONS_CLOUD_TOOLTIP,
  EDIT_PERMISSIONS_LABEL,
  EDIT_PROFILE_LABEL,
  REMOVE_USER_CLOUD_TOOLTIP,
  REMOVE_USER_LABEL,
  RESEND_INVITE_CLOUD_TOOLTIP,
  RESEND_INVITE_LABEL,
} from '../../../constants/user.constants';
import { useAuth } from '../../../contexts/auth';
// Interfaces
import type { IDataObject } from '../../../interfaces/dataObject.interface';
import { useTrackPageViewEvent } from '../../../lib/AppInsights/AppInsights';
// Helpers
import { UserPermissionsHelper } from '../../../lib/UserPermissions';
// Contexts
import { StoreContext } from '../../../store';
import { isCloudEnv } from '../../../utils/cloud';
import ConfirmationModal from '../../ConfirmationModal';
import Loader from '../../CustomerPortal-Loader';
import Modal from '../../Modal';
import UserCard from '../../user_card/User-Card';
import AddUserForm from '../Company-Add-User-Form';
import EditUserForm from '../Company-Edit-UserPermissions-Form';
// Components
import CompanyBodyUnauthorized from './Company-Body-Unauthorized';

interface IEditingUserState {
  email: string;
  role: string;
  permissions: string[];
}

type CompanyBodyTeamUsersProps = {
  loading: boolean;
  showAddUser: boolean;
};

const CompanyBodyTeamUsers = ({
  loading = true,
  showAddUser,
}: CompanyBodyTeamUsersProps) => {
  // Translate method
  const { t } = useTranslation('common');
  // Global State
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const history = useHistory();
  const { getAccessToken } = useAuth();
  const isCloud = isCloudEnv();
  // Local State
  const [ addUserOpen, setAddUserOpen ] = useState(false);
  const [ editPermsOpen, setEditPermsOpen ] = useState(false);
  const [ editingUser, setEditUser ] = useState<IEditingUserState>({
    email: '',
    role: '',
    permissions: [],
  });
  const [ userToRemove, setUserToRemove ] = React.useState<{ email: string; name: string; inCloudOrg: boolean }>({
    email: '',
    name: '',
    inCloudOrg: false,
  });
  const [ openRemoveUserConfirmationModal, setOpenRemoveUserConfirmationModal ] = useState<boolean>(false);
  const teamUsers = state.teamUsers ?? [];
  const baseClass = 'MyCompanyBody';
  let menuOptions: any;
  let labelType: string;
  let labelText: string;
  // Permissions
  const isEditTeamUsersAllowed = UserPermissionsHelper.isEditTeamUsersAllowed();
  const isEditProfileAllowed = UserPermissionsHelper.isEditProfileAllowed();
  const isViewTeamUsersAllowed = UserPermissionsHelper.isViewTeamUsersAllowed();

  // Lifecycle
  useTrackPageViewEvent(COMPANY_TEAM_USERS);

  // Methods
  const setProfilePhoto = (profilePhoto: string) => {
    if (profilePhoto && profilePhoto !== '') {
      return `${VIEW_AVATAR_URL}/${profilePhoto}`;
    }
    return '';

  };

  const handleAddUserClose = () => {
    setAddUserOpen(false);
  };

  const handleEditUserOpen = (user: any) => {
    setEditPermsOpen(true);
    setEditUser(user);
  };

  const handleEditUserClose = () => {
    setEditPermsOpen(false);
  };

  const handleShowInviteResultsModalClose = () => {
    dispatch({
      type: 'setShowInviteResultsModal',
      payload: false,
    });

    dispatch({
      type: 'setShowInviteResultsMessage',
      payload: '',
    });
  };

  const handleUpdateUserStatus = async (
    email: string,
    userStatus: UserStatus
  ) => {
    const body: any = {
      status: userStatus,
      email: email?.toLowerCase(),
      companyId: state.companyId,
    };

    let updateUserStatusResult: any;

    try {
      updateUserStatusResult = await axiosPost(
        `${BACKEND_HOST_NAME}/api/v1/user/updateUserStatus`,
        state.companyId,
        await getAccessToken(),
        body
      );
    } catch (err) {
      console.log(err.message);
    }

    if (updateUserStatusResult && updateUserStatusResult.data.statusCode === 200) {
      const newTeamUsers =
        teamUsers.map((user: any) => user.Email === email ? {
          ...user,
          status: userStatus,
        } : user);

      dispatch({
        type: 'setTeamUsers',
        payload: newTeamUsers,
      });

      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload:
          t(
            'company_team_users_user_status_update_success_msg',
            'User status has been updated!'
          ),
      });
    } else {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_team_users_user_status_update_error_msg',
          'Something went wrong, and the user status was not updated! Please try again later!'
        ),
      });
    }
  };

  const handleResendInvite = async (email: string) => {
    const body: {
      email: string;
    } = { email: email?.toLowerCase() };

    let inviteResult: any;

    try {
      inviteResult = await axiosPost(
        `${BACKEND_HOST_NAME}/api/v1/user/resendUserInvite`,
        state.companyId,
        await getAccessToken(),
        body
      );
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_team_users_invite_user_error_msg',
          'Something went wrong, and the user invite was not sent!'
        ),
      });
    }

    if (inviteResult && inviteResult.status === 200) {
      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_team_users_invite_user_success_msg',
          'User invite has been sent!'
        ),
      });
    }
  };

  const handleDeleteUser = async (email: string) => {
    const body: {
      email: string;
    } = { email: email?.toLowerCase() };

    let deleteUserResult: any;

    try {
      deleteUserResult = await axiosPost(
        `${BACKEND_HOST_NAME}/api/v1/user/deleteUserCompany`,
        state.companyId,
        await getAccessToken(),
        body
      );
    } catch (e) {
      console.log(e.message);
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_team_users_delete_invite_error_msg',
          'Something went wrong, and the user was not removed!'
        ),
      });
    }

    if (
      deleteUserResult?.data?.statusCode &&
      deleteUserResult.data.statusCode === 200
    ) {
      dispatch({
        type: 'setTeamUsers',
        payload: teamUsers.filter((el: any) =>
          el.Email !== email ? true : false
        ),
      });

      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_team_users_delete_invite_success_msg',
          'User has been deleted!'
        ),
      });
    } else {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_team_users_delete_invite_error_msg',
          'Something went wrong, and the invitation was not deleted!'
        ),
      });
    }
  };

  const handleRedirectToProfilePage = () => {
    history.push('/profile');
  };

  const setUserToRemoveAndOpenModal = (email: string, name: string, inCloudOrg: boolean) => {
    setUserToRemove({
      email,
      name,
      inCloudOrg,
    });
    setOpenRemoveUserConfirmationModal(true);
  };

  const handleConfirmUserRemoval = async () => {
    handleCloseConfirmationModal();
    dispatch({
      type: 'setBannerMsg',
      payload: t(
        'company_team_users_user_status_removing_user_msg',
        'Removing user from team users...'
      ),
    });
    await handleDeleteUser(userToRemove.email);
  };

  const handleCloseConfirmationModal = () => {
    setOpenRemoveUserConfirmationModal(false);
    setUserToRemove({
      email: '',
      name: '',
      inCloudOrg: false,
    });
  };

  const getConfirmationMessage = (userInCloudOrg: boolean) =>
    (isCloud && userInCloudOrg) ? t(
      'company_team_users_delete_user_confirmation_cloud_msg',
      `Are you sure that you wish to remove the user "${userToRemove.email}" and their settings from the Customer Portal account? This will not remove the user from the Automation Cloud Organization.<br/><br/>This action cannot be undone!`,
      { email: userToRemove.email }
    ) :
      t(
        'company_team_users_delete_user_confirmation_msg',
        `Are you sure that you wish to remove the user "${userToRemove.email}" and their settings?<br/><br/>This action cannot be undone!`,
        { email: userToRemove.email }
      );

  const generateUsers = () => {
    if (teamUsers.length > 0) {
      return teamUsers.map((contact: IDataObject, i: number) => {
        const cloudUserSource = contact.cloudOrgProfile?.source;
        const isCPAdmin: boolean = contact.cpRole === UserRole.CP_ADMIN;
        const isContactSameAsUser: boolean = contact.Email === state.userEmail;

        switch (contact.status) {
          case UserStatus.ACTIVE: {
            labelText = UserStatus.ACTIVE;
            labelType = 'active';
            menuOptions = null;

            if (isEditTeamUsersAllowed) {
              // Check if the contact is the logged in account admin
              if (isContactSameAsUser && isEditProfileAllowed) {
                menuOptions = [
                  {
                    label: t(
                      EDIT_PROFILE_LABEL.keyText,
                      EDIT_PROFILE_LABEL.fallbackText
                    ),
                    onClick: handleRedirectToProfilePage,
                  },
                ];
              } else {
                menuOptions = [
                  {
                    label: t(
                      EDIT_PERMISSIONS_LABEL.keyText,
                      EDIT_PERMISSIONS_LABEL.fallbackText
                    ),
                    ...isCloud && {
                      tooltip: t(
                        EDIT_PERMISSIONS_CLOUD_TOOLTIP.keyText,
                        EDIT_PERMISSIONS_CLOUD_TOOLTIP.fallbackText
                      ),
                    },
                    onClick: () => handleEditUserOpen(contact),
                  },
                  {
                    label: t(
                      DISABLE_USER_LABEL.keyText,
                      DISABLE_USER_LABEL.fallbackText
                    ),
                    ...isCloud && {
                      tooltip: t(
                        DISABLE_USER_CLOUD_TOOLTIP.keyText,
                        DISABLE_USER_CLOUD_TOOLTIP.fallbackText
                      ),
                    },
                    onClick: () =>
                      handleUpdateUserStatus(
                        contact.Email,
                        UserStatus.DISABLED
                      ),
                  },
                  {
                    label: t(
                      REMOVE_USER_LABEL.keyText,
                      REMOVE_USER_LABEL.fallbackText
                    ),
                    ...isCloud && {
                      tooltip: t(
                        REMOVE_USER_CLOUD_TOOLTIP.keyText,
                        REMOVE_USER_CLOUD_TOOLTIP.fallbackText
                      ),
                    },
                    onClick: () => setUserToRemoveAndOpenModal(contact.Email, contact.Name, !!cloudUserSource),
                  },
                ];
              }
            }
            break;
          }
          case UserStatus.PENDING: {
            labelText = UserStatus.PENDING;
            labelType = 'pending';
            menuOptions = null;

            if (isEditTeamUsersAllowed) {
              menuOptions = [
                {
                  label: t(
                    RESEND_INVITE_LABEL.keyText,
                    RESEND_INVITE_LABEL.fallbackText
                  ),
                  ...isCloud && {
                    tooltip: t(
                      RESEND_INVITE_CLOUD_TOOLTIP.keyText,
                      RESEND_INVITE_CLOUD_TOOLTIP.fallbackText
                    ),
                  },
                  onClick: () => handleResendInvite(contact.Email),
                },
                {
                  label: t(
                    DELETE_INVITE_LABEL.keyText,
                    DELETE_INVITE_LABEL.fallbackText
                  ),
                  ...isCloud && {
                    tooltip: t(
                      DELETE_INVITE_CLOUD_TOOLTIP.keyText,
                      DELETE_INVITE_CLOUD_TOOLTIP.fallbackText
                    ),
                  },
                  onClick: () => handleDeleteUser(contact.Email),
                },
              ];
            }
            break;
          }
          case UserStatus.DISABLED: {
            labelText = UserStatus.DISABLED;
            labelType = 'disabled';
            menuOptions = null;

            if (isEditTeamUsersAllowed) {
              menuOptions = [
                {
                  label: t(
                    ACTIVATE_USER_LABEL.keyText,
                    ACTIVATE_USER_LABEL.fallbackText
                  ),
                  ...isCloud && {
                    tooltip: t(
                      ACTIVATE_USER_CLOUD_TOOLTIP.keyText,
                      ACTIVATE_USER_CLOUD_TOOLTIP.fallbackText
                    ),
                  },
                  onClick: () =>
                    handleUpdateUserStatus(contact.Email, UserStatus.ACTIVE),
                },
                {
                  label: t(
                    REMOVE_USER_LABEL.keyText,
                    REMOVE_USER_LABEL.fallbackText
                  ),
                  ...isCloud && {
                    tooltip: t(
                      REMOVE_USER_CLOUD_TOOLTIP.keyText,
                      REMOVE_USER_CLOUD_TOOLTIP.fallbackText
                    ),
                  },
                  onClick: () => setUserToRemoveAndOpenModal(contact.Email, contact.Name, !!cloudUserSource),
                },
              ];
            }
            break;
          }
          default:
            break;
        }

        return (
          <UserCard
            name={contact.Name?.trim() || '.....'}
            email={contact.Email}
            avatarUrl={setProfilePhoto(contact.photo)}
            labelType={labelType}
            labelText={labelText}
            isAdmin={isCPAdmin}
            key={i}
            isMenu
            menuItems={menuOptions}
            showRole={false}
            addedBy={contact.createdBy}
            joinDate={contact.createdOn}
            isSelfRegistered={contact.selfRegistered}
            isAutoInvited={contact.autoInvited}
            cloudUserSource={cloudUserSource}
          />
        );
      });
    }

    return (
      <p
        className={`${baseClass}__No-Licenses-Text`}
        data-testid="EmptyState"
      >
        {t(
          'company_team_users_no_results',
          `No Team users for ${state.companyName}`,
          { company_name: state.companyName }
        )}
      </p>
    );

  };

  return (
    <>
      {isViewTeamUsersAllowed ? (
        <div
          className={`${baseClass}__Users`}
          data-testid="CompanyBodyTeamUsers"
        >
          <ConfirmationModal
            modalHeading="Delete this user?"
            primaryButtonText="Confirm"
            secondaryButtontext="Cancel"
            message={getConfirmationMessage(userToRemove.inCloudOrg)}
            open={openRemoveUserConfirmationModal}
            handleConfirm={handleConfirmUserRemoval}
            handleClose={handleCloseConfirmationModal}
          />
          <h3
            className={`${baseClass}__Body-Heading Regular`}
            data-testid="Heading"
          >
            {t(
              'company_team_users_title_text',
              `${state.companyName} Team Users`,
              { company_name: state.companyName }
            )}
          </h3>
          <div
            className={`${baseClass}__Heading-Actions-Text`}
            data-testid="SubHeading"
          >
            <h5 className={`${baseClass}__Body-Subheading Regular`}>
              {t(
                'company_team_users_description',
                'All your company’s team members are listed below. Please contact your team admin for any questions.'
              )}
            </h5>
          </div>
          <div className={`${baseClass}__List`}>

            {isEditTeamUsersAllowed &&
              showAddUser && (
              <div
                className={`${baseClass}__Add-User`}
                data-testid="AddUser">
                <div
                  className={`${baseClass}__Add-User-Opener`}
                  onClick={() => {
                    setAddUserOpen(true);
                  }}
                  data-testid="OpenAddUserButton"
                >
                  <img
                    src={AddIcon}
                    alt="Plus Icon"
                    className={`${baseClass}__Add-User-Icon`}
                  />
                  <h6 className="Bold">
                    {t('company_team_users_add_new_user_btn', 'Add new user')}
                  </h6>
                </div>
                <Modal
                  modalTitle="Standard Modal"
                  modalDescription="Add User Modal"
                  modalHeading={t(
                    'company_team_users_add_user_modal_title',
                    'Invite new users to your team'
                  )}
                  innerClasses={`${baseClass}__Add-User-Modal`}
                  handleClose={handleAddUserClose}
                  open={addUserOpen}
                  testId="AddUserModal"
                >
                  <div className="Modal-Fields-Container">
                    <AddUserForm handleModalClose={handleAddUserClose} />
                  </div>
                </Modal>
                <Modal
                  modalTitle="Standard Modal"
                  modalDescription="Invitation not sent"
                  modalHeading="Invitation not sent"
                  innerClasses={`${baseClass}__Add-User-Modal`}
                  handleClose={handleShowInviteResultsModalClose}
                  open={state.showInviteResultsModal}
                  testId="InvitationNotSentModal"
                >
                  <div className="Modal-Fields-Container">
                    <p className="">{state.showInviteResultsMessage}</p>
                  </div>
                </Modal>
              </div>
            )}
            {loading ? (
              <div
                className={`${baseClass}__Loader`}
                data-testid="CompanyBodyTeamUsers__loader"
              >
                <Loader />
              </div>
            ) : (
              generateUsers()
            )}
            <Modal
              modalTitle="Standard Modal"
              modalDescription="Edit User Modal"
              modalHeading={t(
                'company_team_edit_permissions_modal_title',
                'Edit user'
              )}
              innerClasses={`${baseClass}__Add-User-Modal`}
              handleClose={handleEditUserClose}
              open={editPermsOpen}
              testId="EditUserModal"
            >
              <div className="Modal-Fields-Container">
                <EditUserForm
                  handleModalClose={handleEditUserClose}
                  userInfo={editingUser}
                />
              </div>
            </Modal>
          </div>
        </div>
      ) : (
        <CompanyBodyUnauthorized />
      )}
    </>
  );
};

export default CompanyBodyTeamUsers;
