import { Locale } from '@customer-portal/constants';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
} from '@mui/material';
import Popover from '@mui/material/Popover';
import React, {
  useContext,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import type { HeaderProps } from '../assets/css/CustomerPortal-Header';
import ArrowRight from '../assets/img/svg/arrows/ArrowLong_Right.svg';
import ArrowRightHover from '../assets/img/svg/arrows/ArrowLong_Right_Blue.svg';
import { transitionStandard } from '../assets/js_mixins/transitions';
import { mainMenuLinkAnalytics } from '../constants/header.constants';
import {
  getAllLanguageOptions,
  getValidLanguage,
  languageToLocale,
  localeToLanguage,
} from '../constants/localization.constants';
import { useAuth } from '../contexts/auth';
import useLogout from '../hooks/useLogout';
import type { IDataObject } from '../interfaces/dataObject.interface';
import { StoreContext } from '../store';
import ApolloIcon from './ApolloIcon';
import MainMenuNotifications from './CustomerPortal-MainMenu-Notifications';
import NotificationsModal from './CustomerPortal-NotificationsModal';

type MainMenuUserProps = {
  headerCount?: number;
  data: IDataObject[];
  isMobile?: boolean;
  handleDrawerClose?: any;
};

const Container = styled.div<HeaderProps>`
  .Settings-Button {
    background-color: inherit;
    color: ${p => p.theme.palette.text.primary};
    font-size: 1.4rem;
    cursor: pointer;
    padding: 0px 8px;
    &:hover {
      color: ${p => p.theme.palette.semantic.colorPrimary};
    }
    &.Active {
      color: ${p => p.theme.palette.semantic.colorPrimary};
      cursor: pointer;
    }
  }

  .Settings-Button__Icon {
    margin-left: -${p => p.theme.spacing(0.75)}px;
    font-size: 2.4rem !important;
    @media (max-width: 1393px) {
      font-size: 2rem !important;
      margin-top: 2px;
    }
  }

  .Settings-Button__Text {
    font-weight: 600;
    width: max-content;
    border: none;
    @media (max-width: 1393px) {
      font-size: ${p => p.headerCount && p.headerCount > 4 ? '1.1rem' : '1.4rem'};
    }
  }

  .dropdown-arrow {
    transition: transform 0.3s ease;
  }

  .dropdown-arrow.rotate {
    transform: rotate(-180deg);
  }
`;

const createMainMenuPopover = (width: number = 200) => styled(Popover)`
  & .MuiPopover-paper {
    top: 56px !important;
    min-width: ${width}px !important;
    -webkit-box-shadow: 0px 16px 24px 0px rgba(3,5,8,0.1);
    -moz-box-shadow: 0px 16px 24px 0px rgba(3,5,8,0.1);
    box-shadow: 0px 16px 24px 0px rgba(3,5,8,0.1);
  }
  .CustomerPortalMainMenuLink__Inner {
    padding: 4px 0;
    background: ${p => p.theme.palette.semantic.colorBackgroundRaised};
    .CustomerPortalMainMenuLink__Dropdown-Link {
      cursor: pointer;
      display: block;
      font-size: 1.4rem;
      line-height: 24px;
      color: ${props => props.theme.palette.text.primary};
      position: relative;
      padding: 8px 24px;
      background: ${p => p.theme.palette.semantic.colorBackgroundRaised};
      &:after {
        content: "";
        background-image: url("${ArrowRight}");
        position: absolute;
        right: 12px;
        width: 14px;
        height: 14px;
        top: 50%;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-position: center;
      }
      &:hover {
        text-decoration: none;
        color: ${props => props.theme.palette.semantic.colorPrimary};
        background: ${props => props.theme.palette.semantic.colorBackgroundHover};
        &:after {
          content: "";
          background-image: url("${ArrowRightHover}");
        }
      }
    }
  }
`;

const MainMenuUserOuter = createMainMenuPopover();
const LanguageSelectorMenu = createMainMenuPopover(120);
const AnalyticsSelectorMenu = createMainMenuPopover(160);
const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  max-width: 60px;
  border-radius: 16px;
  ${transitionStandard('all')};

  .CustomerPortalMainMenuUser__Name {
    color: ${props => props.theme.palette.text.primary};
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: bold;
    ${props => props.theme.breakpoints.down('md')} {
      max-width: 170px;
    }
  }

  &:hover,
  &--Open {
    ${props => props.theme.breakpoints.up('md')} {
      border: 1px solid ${props => props.theme.palette.grey[200]};
    }
    .CustomerPortalMainMenuUser__Name {
      color: ${props => props.theme.palette.semantic.colorPrimary};
    }
  }
  @media (max-width: 1105px) {
    border-radius: 0;
    border: none;
    padding: 8px 16px;
    max-width: 100%;
    width: 100%;
  }
`;

const ExpansionPanelUser = styled(Accordion)`
  border: 1px solid #E3E3E3;
  border-radius: 0 !important;
  margin-bottom: 16px !important;
  &:before {
    display: none;
  }
  &.Mui-expanded {
    border-bottom: none;
  }
  .MuiExpansionPanelSummary-root,.MuiExpansionPanelSummary-root {
    border-left: none;
    padding: 0 24px 0 0;
    &:hover {
      border-left: none;
    }
    &.Mui-expanded {
      border-left: none;
      min-height: 48px;
      &:hover {
        border-left: none;
      }
    }
  }

  .MuiIconButton-label {
    img {
      width: 14px;
    }
  }

  .CustomerPortalMainMenuLink__ExpansionPanel-Details {
    background: ${props => props.theme.palette.semantic.colorBackgroundHover};
    cursor: pointer;
    border-left: 4px solid ${props => props.theme.palette.semantic.colorBackgroundHover};
    ${transitionStandard('all')};
    position: relative;
    border-bottom: 1px solid ${props => props.theme.palette.grey[300]};
    &:not(:last-of-type) {
    }
    &:after {
      content: "";
      ${transitionStandard('background-image')};
      background-image: url("${ArrowRight}");
      position: absolute;
      right: 20px;
      width: 14px;
      height: 14px;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-position: center;
    }
    &:hover {
      border-left: 4px solid ${props => props.theme.palette.semantic.colorPrimary};
      .CustomerPortalMainMenuLink__ExpansionPanel-Dropdown-Link {
        color: ${props => props.theme.palette.semantic.colorPrimary};
      }
      &:after {
        content: "";
        background-image: url("${ArrowRightHover}");
      }
    }
  }

  .CustomerPortalMainMenuLink__ExpansionPanel-Summary--Child {
    background: ${props => props.theme.palette.semantic.colorBackgroundHover};
    border-left: 4px solid ${props => props.theme.palette.semantic.colorBackgroundHover};
    border-bottom: 1px solid ${props => props.theme.palette.grey[300]};
    padding: 0px 16px;
  }

  .MuiExpansionPanelDetails-root {
    padding: 18px 16px;
    background: ${props => props.theme.palette.grey[100]}
  }
  .MuiExpansionPanelSummary-content {
    padding: 0;
    margin: 0;
    &.Mui-expanded {
      margin: 0;
    }
  }
  .CustomerPortalMainMenuUser__Mobile-Container {
    display: flex;
    flex-direction: row;
  }
  .CustomerPortalHeader__Notifications {
    @media(max-width: 1023px) {
      flex: 0;
      margin-right: 0;
    }
  }
  .CustomerPortalMainMenuLink__ExpansionPanel-Dropdown-Link {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.70;
    text-decoration: none;
    color: ${props => props.theme.palette.text.primary};
  }
`;

const CustomerPortalMainMenuUser = (props: MainMenuUserProps) => {
  const userLinks = props.data;
  const hideSettings = userLinks?.length === 0;
  const headerCount = props.headerCount;
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const logout = useLogout();
  const [ userAnchorEl, setUserAnchorEl ] = useState(null);
  const [ open, setOpen ] = useState(false);
  const [ langAnchorEl, setLangAnchorEl ] = useState(null);
  const [ analyticsAnchorEl, setAnalyticsAnchorEl ] = useState(null);
  const {
    t, i18n,
  } = useTranslation('common');

  const handleModalOpen = (e: any) => {
    e.preventDefault();
    handleClose();
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
    setUserAnchorEl(null);
  };

  const handleSwitchAccount = (e: any) => {
    e.preventDefault();
    handleClose();
    dispatch({
      type: 'setShowSwitchAccountModal',
      payload: true,
    });
  };

  const handleUserLogout = async (e: any) => {
    e.preventDefault();
    handleClose();
    await logout();
  };

  const handleClick = (event: any) => {
    setUserAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setLangAnchorEl(null);
    setAnalyticsAnchorEl(null);
    setUserAnchorEl(null);
  };

  const handleError = () => {
    dispatch({
      type: 'setBannerType',
      payload: 'error',
    });
    dispatch({
      type: 'setBannerMsg',
      payload:
        'There was an error in saving your notification settings. Please try again.',
    });
  };

  const handleSuccess = () => {
    dispatch({
      type: 'setBannerType',
      payload: 'success',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: 'Your notification settings were successfully saved',
    });
  };

  const handleAnalyticsAnchorOpen = (e: any) => {
    setAnalyticsAnchorEl(e.currentTarget);
  };

  const handleLangAnchorOpen = (event: any) => {
    setLangAnchorEl(event.currentTarget);
  };

  const handleChangeLanguage = async (language?: string) => {
    await updateLocale(language ?? localeToLanguage.get(Locale.en)!);
  };

  const updateLocale = async (newLanguage: string) => {
    const accessToken = await getAccessToken();
    const locale = languageToLocale.get(newLanguage)?.toString() || Locale.en;
    const requestBody = { userLanguage: locale };
    i18n.changeLanguage(locale);

    if (props.isMobile) {
      props.handleDrawerClose();
    } else {
      handleClose();
    }
  };

  const generateModule = () => {
    if (!props.isMobile) {
      return (
        <Container headerCount={headerCount}>
          <Button
            className={`Settings-Button ${userAnchorEl ? 'Active' : ''}`}
            disableElevation
            disableRipple
            onClick={handleClick}
            endIcon={
              <ApolloIcon
                className={`Settings-Button__Icon dropdown-arrow ${!userAnchorEl ? 'rotate' : ''}`}
                icon='expand_less'
              />
            }
            data-testid="UserMenuDropDownButtonText"
          >
            <p className='Settings-Button__Text'>
              {t('header_menu_customer_portal_settings', 'Customer Portal Settings')}
            </p>
          </Button>
          <MainMenuUserOuter
            id="CustomerPortalMainMenuUser__Menu"
            className="CustomerPortalMainMenuUser__Outer"
            open={Boolean(userAnchorEl)}
            elevation={0}
            anchorEl={userAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            onClose={handleClose}
            disableRestoreFocus
          >
            <div
              className="CustomerPortalMainMenuLink__Inner"
              data-testid="ProfileDropDownOptions">
              {userLinks.map((listItem: IDataObject, i: number) => {
                if (listItem.link.href === 'notifications') {
                  return (
                    <a
                      key={i}
                      href="#"
                      className="CustomerPortalMainMenuLink__Dropdown-Link"
                      onClick={handleModalOpen}
                      data-testid="NotificationSettings"
                    >
                      {t(listItem.link.textKey, listItem.link.fallbackText)}
                    </a>
                  );
                } else if (listItem.link.href === '/logout') {
                  return (
                    <a
                      key={i}
                      href="#"
                      className="CustomerPortalMainMenuLink__Dropdown-Link"
                      onClick={handleUserLogout}
                      data-testid="LogOut"
                    >
                      {t(listItem.link.textKey, listItem.link.fallbackText)}
                    </a>
                  );
                } else if (listItem.link.href === 'switch-account') {
                  return (
                    <a
                      key={i}
                      href="#"
                      className="CustomerPortalMainMenuLink__Dropdown-Link"
                      onClick={handleSwitchAccount}
                      data-testid="SwitchAccount"
                    >
                      {t(listItem.link.textKey, listItem.link.fallbackText)}
                    </a>
                  );
                } else if (listItem.link.href === '/admin/analytics') {
                  return (
                    <React.Fragment key={i}>
                      <a
                        href="#"
                        className="CustomerPortalMainMenuLink__Dropdown-Link"
                        onClick={handleAnalyticsAnchorOpen}
                        data-testid="Analytics"
                      >
                        {t(listItem.link.textKey, listItem.link.fallbackText)}
                      </a>
                      <AnalyticsSelectorMenu
                        id="CustomerPortalMainMenuUser__Menu"
                        open={Boolean(analyticsAnchorEl)}
                        anchorEl={analyticsAnchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        onClose={handleClose}
                        disableRestoreFocus
                      >
                        <div className="CustomerPortalMainMenuLink__Inner">
                          {mainMenuLinkAnalytics.map(setting => (
                            <Link
                              key={setting.link.href}
                              to={setting.link.href}
                              onClick={handleClose}
                              className="CustomerPortalMainMenuLink__Dropdown-Link"
                              data-testid="AnalyticsOptions"
                            >
                              {t(
                                setting.link.textKey,
                                setting.link.fallbackText
                              )}
                            </Link>
                          ))}
                        </div>
                      </AnalyticsSelectorMenu>
                    </React.Fragment>
                  );
                } else if (listItem.link.href === '/language') {
                  return (
                    <React.Fragment key={i}>
                      <div
                        className="CustomerPortalMainMenuLink__Dropdown-Link"
                        onClick={handleLangAnchorOpen}
                        data-testid="LanguageSettings"
                      >
                        {localeToLanguage.get(
                          getValidLanguage(
                            i18n.language
                          )
                        )}
                      </div>
                      <LanguageSelectorMenu
                        id="CustomerPortalMainMenuUser__Menu"
                        open={Boolean(langAnchorEl)}
                        anchorEl={langAnchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        onClose={handleClose}
                        disableRestoreFocus
                      >
                        <div
                          className="CustomerPortalMainMenuLink__Inner"
                          data-testid="LanguageSelectorMenu">
                          {getAllLanguageOptions().map(
                            (language: string, idx: number) => (
                              <div
                                key={`lang-${idx}`}
                                className="CustomerPortalMainMenuLink__Dropdown-Link"
                                onClick={() => handleChangeLanguage(language)}
                                data-testid={`language-option-${language}`}
                              >
                                {language}
                              </div>
                            )
                          )}
                        </div>
                      </LanguageSelectorMenu>
                    </React.Fragment>
                  );
                }
                return (
                  <Link
                    key={i}
                    to={listItem.link.href}
                    onClick={handleClose}
                    className="CustomerPortalMainMenuLink__Dropdown-Link"
                    data-testid="UserMenuDropDownOption"
                  >
                    {t(listItem.link.textKey, listItem.link.fallbackText)}
                  </Link>
                );
              })}
            </div>
          </MainMenuUserOuter>
        </Container>
      );
    }

    return (
      <ExpansionPanelUser
        className="CustomerPortalMainMenuLink__ExpansionPanel CustomerPortalMainMenuLink__ExpansionPanel--User"
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ApolloIcon
            icon='expand_more'
            fontSize='small' />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="CustomerPortalMainMenuLink__ExpansionPanel-Summary"
        >
          <div className="CustomerPortalMainMenuUser CustomerPortalMainMenuUser__Mobile-Container">
            <div className="CustomerPortalHeader__Notifications">
              <MainMenuNotifications isMobile />
            </div>
            <UserInfoContainer>
              <Container>
                <Button
                  className='Settings-Button'
                  disableElevation
                  disableRipple
                >
                  <p className='Settings-Button__Text'>
                    {t('header_menu_customer_portal_settings', 'Customer Portal Settings')}
                  </p>
                </Button>
              </Container>
            </UserInfoContainer>
          </div>
        </AccordionSummary>
        {userLinks.map((listItem: IDataObject, i: number) => {
          if (listItem.link.href === 'notifications') {
            return (
              <AccordionDetails
                key={i}
                className="CustomerPortalMainMenuLink__ExpansionPanel-Details"
                onClick={handleModalOpen}
              >
                <div className="CustomerPortalMainMenuLink__Inner--Mobile-Drawer">
                  <p className="CustomerPortalMainMenuLink__ExpansionPanel-Dropdown-Link">
                    {t(listItem.link.textKey, listItem.link.fallbackText)}
                  </p>
                </div>
              </AccordionDetails>
            );
          } else if (listItem.link.href === '/profile') {
            return (
              <Link
                onClick={props.handleDrawerClose}
                to={listItem.link.href}>
                <AccordionDetails
                  key={i}
                  className="CustomerPortalMainMenuLink__ExpansionPanel-Details"
                >
                  <div className="CustomerPortalMainMenuLink__Inner--Mobile-Drawer">
                    <p className="CustomerPortalMainMenuLink__ExpansionPanel-Dropdown-Link">
                      {t(listItem.link.textKey, listItem.link.fallbackText)}
                    </p>
                  </div>
                </AccordionDetails>
              </Link>
            );
          } else if (listItem.link.href === '/logout') {
            return (
              <Link
                onClick={props.handleDrawerClose}
                to={listItem.link.href}>
                <AccordionDetails
                  key={i}
                  className="CustomerPortalMainMenuLink__ExpansionPanel-Details"
                  onClick={handleUserLogout}
                >
                  <div className="CustomerPortalMainMenuLink__Inner--Mobile-Drawer">
                    <p className="CustomerPortalMainMenuLink__ExpansionPanel-Dropdown-Link">
                      {t(listItem.link.textKey, listItem.link.fallbackText)}
                    </p>
                  </div>
                </AccordionDetails>
              </Link>
            );
          } else if (listItem.link.href === 'switch-account') {
            return (
              <Link
                onClick={props.handleDrawerClose}
                to={listItem.link.href}>
                <AccordionDetails
                  key={i}
                  className="CustomerPortalMainMenuLink__ExpansionPanel-Details"
                  onClick={handleSwitchAccount}
                >
                  <div className="CustomerPortalMainMenuLink__Inner--Mobile-Drawer">
                    <p className="CustomerPortalMainMenuLink__ExpansionPanel-Dropdown-Link">
                      {t(listItem.link.textKey, listItem.link.fallbackText)}
                    </p>
                  </div>
                </AccordionDetails>
              </Link>
            );
          } else if (listItem.link.href === '/admin/analytics') {
            return (
              <Accordion key={i}>
                <AccordionSummary
                  expandIcon={<ApolloIcon
                    icon='expand_more'
                    fontSize='small' />}
                  className="CustomerPortalMainMenuLink__ExpansionPanel-Summary CustomerPortalMainMenuLink__ExpansionPanel-Dropdown-Link CustomerPortalMainMenuLink__ExpansionPanel-Summary--Child"
                  aria-controls="panel1a-content"
                >
                  {t(listItem.link.textKey, listItem.link.fallbackText)}
                </AccordionSummary>
                {mainMenuLinkAnalytics.map(setting => (
                  <Link
                    key={setting.link.href}
                    to={setting.link.href}
                    onClick={props.handleDrawerClose}
                  >
                    <AccordionDetails
                      key={setting.link.href + 'expansion_details'}
                      className="CustomerPortalMainMenuLink__ExpansionPanel-Details"
                    >
                      <div className="CustomerPortalMainMenuLink__Inner--Mobile-Drawer">
                        <p className="CustomerPortalMainMenuLink__ExpansionPanel-Dropdown-Link">
                          {t(setting.link.textKey, setting.link.fallbackText)}
                        </p>
                      </div>
                    </AccordionDetails>
                  </Link>
                ))}
              </Accordion>
            );
          } else if (listItem.link.href === '/language') {
            return (
              <Accordion key={i}>
                <AccordionSummary
                  expandIcon={<ApolloIcon
                    icon='expand_more'
                    fontSize='small' />}
                  className="CustomerPortalMainMenuLink__ExpansionPanel-Summary CustomerPortalMainMenuLink__ExpansionPanel-Dropdown-Link CustomerPortalMainMenuLink__ExpansionPanel-Summary--Child"
                  aria-controls="panel1a-content"
                  data-testid="UserMenuDropDownOption_Language_Mobile"
                >
                  {localeToLanguage.get(
                    getValidLanguage(
                      i18n.language
                    )
                  )}
                </AccordionSummary>
                {getAllLanguageOptions().map((language: string, idx: number) => (
                  <div
                    key={`lang-${idx}`}
                    className="CustomerPortalMainMenuLink__Dropdown-Link"
                    onClick={() => handleChangeLanguage(language)}
                    data-testid="UserMenuLanguageDropDownOption_Mobile"
                  >
                    <AccordionDetails
                      key={'lang-' + language + 'expansion_details'}
                      className="CustomerPortalMainMenuLink__ExpansionPanel-Details"
                    >
                      <div className="CustomerPortalMainMenuLink__Inner--Mobile-Drawer">
                        <p className="CustomerPortalMainMenuLink__ExpansionPanel-Dropdown-Link">
                          {language}
                        </p>
                      </div>
                    </AccordionDetails>
                  </div>
                ))}
              </Accordion>
            );
          }
          return (
            <Link
              to={listItem.link.href}
              onClick={props.handleDrawerClose}
              key={i}
            >
              <AccordionDetails className="CustomerPortalMainMenuLink__ExpansionPanel-Details">
                <div className="CustomerPortalMainMenuLink__Inner--Mobile-Drawer">
                  <p className="CustomerPortalMainMenuLink__ExpansionPanel-Dropdown-Link">
                    {t(listItem.link.textKey, listItem.link.fallbackText)}
                  </p>
                </div>
              </AccordionDetails>
            </Link>
          );
        })}
      </ExpansionPanelUser>
    );
  };

  return (
    <>
      <NotificationsModal
        onClose={handleModalClose}
        onSuccess={handleSuccess}
        onError={handleError}
        open={open}
      />
      {!hideSettings && generateModule()}
    </>
  );
};

export default CustomerPortalMainMenuUser;
