import type { MouseEvent } from 'react';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Styles
import * as styles from '../../assets/css/Company/Page';
import MyCompanyAccessSettings from '../../components/company/Company-Access-Settings';
import MyCompanyHeader from '../../components/company/Company-Header';
import MyCompanyInfoBox from '../../components/company/Company-Info-Box';
import MyCompanyNav from '../../components/company/Company-Navigation';
// Components
import CompanyBodyContacts from '../../components/company/companyBody/Company-Body-Contacts';
import CompanyBodyEntityManagement from '../../components/company/companyBody/Company-Body-EntityManagement';
import CompanyBodyHAPODashboard from '../../components/company/companyBody/Company-Body-Hapo-Dashboard';
import CompanyBodyLicenses from '../../components/company/companyBody/Company-Body-Licenses';
import CompanyBodyTeamUsers from '../../components/company/companyBody/Company-Body-TeamUsers';
import Container from '../../components/CustomerPortal-Container';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import ScrollToTop from '../../components/CustomerPortal-ScrollToTop';
import { COMPANY } from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import { CloudOrgContext } from '../../contexts/cloud-org';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
// Utils
import CompanyHelper from '../../lib/company.utils';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import NoCompanyId from './../../components/CustomerPortal-NoCompanyId';
// Contexts
import { StoreContext } from './../../store';

const CustomerPortalPageCompany = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');
  // Global state
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const history = useHistory();
  // Local state
  const [ body, setBody ] = React.useState('Contacts');
  const [ edit, setEdit ] = React.useState(false);
  const [ showAddUser, setShowAddUser ] = useState(true);
  const [ lastLocation, setLastLocation ] = useState('');
  const {
    isLoadingCloudOrgDetails, cloudOrgId: currentCloudOrgId,
  } = useContext(CloudOrgContext);

  const shouldShowCompanyAccessSettings: boolean = body === 'users' &&
    UserPermissionsHelper.isEditCompanySettingsAllowed() || UserPermissionsHelper.isMoveToCloudAllowed();

  // Methods
  const getCompanyData = async () => {
    try {
      // Fetch CompanyData i.e. Team Users and UiPath Contacts
      const newCompanyData = await CompanyHelper.getCompanyData(
        state.companyId,
        await getAccessToken(),
        currentCloudOrgId ?? undefined
      );
      setShowAddUser(true);
      dispatch({
        type: 'setUiPathContacts',
        payload: newCompanyData.uiPathContacts,
      });
      dispatch({
        type: 'setTeamUsers',
        payload: newCompanyData.teamUsers,
      });
      dispatch({
        type: 'setIsHapoEnabled',
        payload: newCompanyData.isHapoEnabled,
      });
      dispatch({
        type: 'setIsUtoEnabled',
        payload: newCompanyData.isUtoEnabled,
      });
      dispatch({
        type: 'setLicenseManagementEnabled',
        payload: newCompanyData.isUtoEnabled || newCompanyData.isHapoEnabled,
      });
      dispatch({
        type: 'setSupportOnlyProvidedByCountry',
        payload: newCompanyData.supportOnlyProvidedByCountry,
      });
    } catch (e) {
      dispatch({
        type: 'setUiPathContacts',
        payload: [],
      });
      dispatch({
        type: 'setTeamUsers',
        payload: [],
      });
      setShowAddUser(false);
      dispatch({
        type: 'showSelectedCompanyBanner',
        payload: false,
      });
      console.log(e.message);
    }
  };

  useTrackPageViewEvent(COMPANY);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Company');
  }, []);

  useEffect(
    () => {
      if (isLoadingCloudOrgDetails) {
        return;
      }

      // this reduce the number of API calls
      if (props.location.pathname !== lastLocation) {
        fetchSFDCData();
      }

      if (props.location.search) {
        const navTab: string = props.location.search.replace('?section=', '');
        setBody(navTab.toLowerCase());
      }
    },
    // Compare the old route and the new one to prevent this from firing after every render
    [ props.location, isLoadingCloudOrgDetails ]
  );

  const fetchSFDCData = async () => {
    setLastLocation(props.location.pathname); // this reduce the number of API calls
    await getCompanyData();
  };

  const handleBodyNavClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const section = e.currentTarget.id.toLowerCase();

    switch (section) {
      case 'requestforquote':
        history.push('/request-quote');
        break;
      default:
        history.push(`/company?section=${section}`);
        break;
    }
  };

  const handleEditClick = (e?: MouseEvent) => {
    if (e) {
      if (e.currentTarget.id === 'edit') {
        setEdit(true);
      } else {
        setEdit(false);
      }
    } else {
      setEdit(!edit);
    }
  };

  const generateBody = () => {
    switch (body) {
      case 'users':
        return (
          <CompanyBodyTeamUsers
            loading={typeof state.teamUsers === 'undefined'}
            showAddUser={showAddUser}
          />
        );
      case 'licenses':
        return <CompanyBodyLicenses />;
      case 'hapo':
        return <CompanyBodyHAPODashboard />;
      case 'entities':
        return <CompanyBodyEntityManagement />;
      default:
        return (
          <CompanyBodyContacts
            loading={typeof state.uiPathContacts === 'undefined'}
          />
        );
    }
  };

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{t('company_page_title', 'Company | Customer Portal')}</title>
      </Helmet>

      {state.companyId === '' && <NoCompanyId />}

      {state.companyId !== '' && (
        <styles.MyCompanyPageWrapper data-testid="CompanyPage__pageWrapper">
          <MyCompanyHeader />
          <MyCompanyInfoBox handleEditClick={handleEditClick} />
          <Container>
            <styles.MyCompanyBody data-testid="CompanyPage__companyBody">
              <styles.CompanyBody>{generateBody()}</styles.CompanyBody>
              <styles.SideSection>
                <MyCompanyNav
                  handleBodyNavClick={handleBodyNavClick}
                  body={body}
                />
                {shouldShowCompanyAccessSettings && <MyCompanyAccessSettings />}
              </styles.SideSection>
            </styles.MyCompanyBody>
          </Container>
        </styles.MyCompanyPageWrapper>
      )}
    </>
  );
};

export default CustomerPortalPageCompany;
