import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import ArrowLongRight from '../../assets/img/svg/arrows/ArrowLong_Right_White.svg';
import { transitionStandard } from '../../assets/js_mixins/transitions';

const Wrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  text-decoration: none;
  background-color: ${props => props.theme.palette.primary.main};
  width: 242px;
  min-height: 192px;
  height: 100%;
  ${transitionStandard('all')};

  &:hover {
    box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
  }

  ${(props): any => `
    ${props.theme.breakpoints.up('md')} {
      width: 282px;
      min-height: 200px;
    }
  `};
`;

const Heading = styled.h2`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${props => props.theme.spacing(3)}px;
  color: ${p => p.theme.palette.semantic.colorBackground};
  font-size: 28px; // TODO: Change when have theme
  font-weight: 800;
  margin-bottom: ${props => props.theme.spacing(2)}px;
  padding-top: ${props => props.theme.spacing(2)}px;
`;

const CTA = styled.div`
  padding: 0 ${props => props.theme.spacing(3)}px;
  flex-basis: 47px;
  max-height: 47px;
  position: relative;

  .CustomerPortalKBSectionCard__CTA {
    color: ${p => p.theme.palette.semantic.colorBackground};
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1.2rem;

    &:after {
      content: '';
      width: 16px;
      height: 16px;
      background: url('${ArrowLongRight}') 0 0/contain no-repeat;
      position: absolute;
      margin-left: ${props => props.theme.spacing(0.5)}px;
    }
  }
`;

type KBSectionCardProps = {
  cssClass?: string;
  categoryImage?: string;
  categoryHeadingText?: string;
  linkHref: string;
  linkText?: string;
};

const CustomerPortalKBSectionCard = ({
  categoryHeadingText,
  linkHref,
  linkText,
}: KBSectionCardProps) => (
  <>
    <Wrapper to={linkHref}>
      <Heading>{categoryHeadingText}</Heading>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <CTA>
        <span className="CustomerPortalKBSectionCard__CTA">{linkText}</span>
      </CTA>
    </Wrapper>
  </>
);

export default CustomerPortalKBSectionCard;
