import styled from 'styled-components';

import heroBg from '../img/Profile_Header.svg';
import { transitionStandard } from '../js_mixins/transitions';
import { ContentWrapper } from './CustomerPortalContentWrapper';

export const PageWrapper = styled.section`
  background: ${p => p.theme.palette.semantic.colorBackground};
  ${ContentWrapper}
  .CustomerPortalPage__Button {
    margin-bottom: 16px;
    padding: 16px 24px;
    cursor: pointer;
    background-color: ${p => p.theme.palette.semantic.colorBackground};
    border-radius: 8px;
    font-size: 1.6rem;
    transition: all 0.3s ease;
    outline: none;

    &:hover {
      color: #fff;
      background-color: #0067df;
      transition: all 0.3s ease;
    }
  }

  .EditProfile__Avatar {
    position: relative;
    top: -90px;
  }

  .CustomerPortalUploadForm {
    display: none;
  }

  .EditProfile__Details-Form,.EditProfile__Main-Stats-Container {
    flex: 100%;
    max-width: 690px;
  }

  .EditProfile__Details-Contact-Info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    ${p => p.theme.breakpoints.down('xs')} {
      flex-direction: column;
    }
  }

  .EditProfile__Edit-Link {
    display: flex;
    flex-direction: row;
    min-width: 90px;
    justify-content: flex-end;
    cursor: pointer;
    ${p => p.theme.breakpoints.down('xs')} {
      justify-content: flex-start;
      margin-top: 24px;
    }
  }

  .EditProfile__Edit-Link-Text {
    color: ${p => p.theme.palette.semantic.colorPrimary};
    text-transform: uppercase;
    margin-right: 8px;
    letter-spacing: 0.5px;
    font-size: 1.0rem;
    line-height: 1.6rem;
  }

  .EditProfile__Details-Rule {
    height: 1px;
    width: 100%;
    background: ${p => p.theme.palette.grey[300]};
    margin: ${p => p.theme.spacing(3)}px 0 ${p => p.theme.spacing(6)}px 0;
  }

  .EditProfile__Container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    ${p => p.theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: center;
    }
  }

  .EditProfile__Details {
    flex: 2;
    max-width: 894px;
    margin-left: ${p => p.theme.spacing(3)}px;
    padding-top: ${p => p.theme.spacing(3)}px;
    ${p => p.theme.breakpoints.down('sm')} {
      margin-left: 0;
      max-width: 100%;
      position: relative;
      top: -45px;
      padding-top: 0;
      width: 100%;
    }
  }

  .EditProfile__Email-Status {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const HomeSection = styled.section`
  position: relative;
  background-color: ${p => p.theme.palette.semantic.colorBackground};

  .CustomerPortalHome__container {
    display: flex;
    justify-content: space-between;
  }
`;

export const Hero = styled.div`
  position: relative;
  width: 100%;
  height: 320px;
  background-color: ${p => p.theme.palette.semantic.colorBackground};

  &:before {
    content: '';
    position: absolute;
    background: url(${heroBg});
    background-position: top right;
    width: 100%;
    height: 320px;
    z-index:0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    ${p => p.theme.breakpoints.down('xs')} {
      display: none;
    }
  }
`;

export const avatarImg = styled.div`
  width: 180px;
  height: 180px;
  overflow: hidden;
  border: 8px solid rgb(255, 255, 255);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  box-sizing: border-box;
  min-width: 180px;
  max-width: 180px;
  min-height: 180px;
  flex: 1;
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    min-height: 100%;
  }
`;

export const profileDetails = styled.h1`
  color: ${p => p.theme.palette.text.secondary};
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  line-height: 1.6rem;
  margin-bottom: ${p => p.theme.spacing(1)}px;
`;

export const profileName = styled.h1`
  color: ${p => p.theme.palette.text.primary};
  font-size: 3.6rem;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 4.4rem;
  margin-bottom: ${p => p.theme.spacing(1.5)}px;
`;

export const profileEmail = styled.h1`
  color: ${p => p.theme.palette.text.primary};
  font-size: 1.4rem;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 2.4rem;
  margin-right: ${p => p.theme.spacing(2)}px;
`;

export const EditProfileStats = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${p => p.theme.breakpoints.down('xs')} {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .EditProfile__Stat-Small {
    width: 100%;
    max-width: 180px;
    .EditProfile__Stat-Title-Thin {
      font-size: 1.0rem;
      color: ${p => p.theme.palette.text.secondary};
      font-weight: 400;
      letter-spacing: 0.5px;
      line-height: 1.6rem;
    }
  }
  .EditProfile__Main-Stats-Container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    ${p => p.theme.breakpoints.down('sm')} {
      flex-direction: column;
      flex: 3;
    }
    ${p => p.theme.breakpoints.down('xs')} {
      width: 100%;
      margin-top: 24px;
    }
  }

  .EditProfile__Secondary-Stats-Container {
    margin-right: auto;
    margin-left: 24px;
    min-width: 90px;
    ${p => p.theme.breakpoints.down('sm')} {
      flex: 1;
    }
    ${p => p.theme.breakpoints.down('xs')} {
      width: 100%;
      margin-left: 0;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .EditProfile__Stat {
    width: 48%;
    max-width: 282px;
    margin-bottom: 24px;
    ${p => p.theme.breakpoints.down('xs')} {
      width: 100%;
      max-width: 100%;
    }
  }

  .EditProfile__Stat-Small {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    ${p => p.theme.breakpoints.down('xs')} {
      width: 50%;
      max-width: 50%;
    }
  }

  .EditProfile__Stat-Title {
    color: ${p => p.theme.palette.text.primary} !important;
    font-size: 1.0rem;
    text-transform: uppercase;
    width: 100%;
    letter-spacing: 0.5px;
    margin-bottom: 8px;
  }
  .EditProfile__Stat-Body {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    .EditProfile__Stat-Icon {
      width: 18px;
      height: auto;
      padding: 0 22px 0 8px;
    }
    .EditProfile__Stat-Body-Text {
      color: ${p => p.theme.palette.text.primary};
      font-weight: 400;
    }
  }
`;

export const AvatarEditScreen = styled.div`
  ${transitionStandard('all')};
  background: none;
  opacity: 0;
  width: 180px;
  height: 180px;
  border-radius: 2px;
  position: absolute;
  top: 0;
  border-radius: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 8px solid white;
  &.Active {
    background: ${p => p.theme.palette.semantic.colorPrimary};
    opacity: 0.9;
    cursor: pointer;
  }
  .EditProfile__Update-Photo-Icon {
    width: 80px;
    height: auto;
  }
`;
