import React from 'react';

import {
  CustomCharCount,
  TextAreaAutosize,
} from '../assets/css/TextAreaWithCharCount';

export type TextAreaWithCharCountProps = {
  helperText?: React.ReactNode;
  ariaLabel?: string;
  label?: string;
  children?: any;
  register: any;
  rowsMin: number;
  maxLength: number;
  placeholder?: string;
  value: string;
  defaultValue?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
};

const TextAreaWithCharCount = (props: TextAreaWithCharCountProps) => (
  <>
    <TextAreaAutosize
      placeholder={props.placeholder}
      value={props.value}
      aria-label="empty textarea"
      minRows={props.rowsMin}
      maxLength={props.maxLength}
      disabled={props.disabled}
      {...props.register}
      data-testid={`${props.label}-input`}
    />
    <CustomCharCount
      className={props.value.length === props.maxLength ? 'maxChar' : ''}
    >
      <span id="current">{props.value.length}</span>
      <span id="maximum">
        {' '}
/
        {props.maxLength}
      </span>
    </CustomCharCount>
  </>
);

export default TextAreaWithCharCount;

