export const DELETE_INVITE_LABEL = {
  keyText: 'company_team_users_context_menu_delete_invite_label',
  fallbackText: 'Delete invite',
};
export const DELETE_INVITE_CLOUD_TOOLTIP = {
  keyText: 'company_team_users_context_menu_delete_invite_cloud_tooltip',
  fallbackText: 'Delete invite to Customer Portal account. This does not delete the invite to the Cloud Organization.',
};

export const ACTIVATE_USER_LABEL = {
  keyText: 'company_team_users_context_menu_activate_user_label',
  fallbackText: 'Activate user',
};
export const ACTIVATE_USER_CLOUD_TOOLTIP = {
  keyText: 'company_team_users_context_menu_activate_user_cloud_tooltip',
  fallbackText: 'Activate user in Customer Portal account.',
};

export const DISABLE_USER_LABEL = {
  keyText: 'company_team_users_context_menu_disable_user_label',
  fallbackText: 'Disable user',
};
export const DISABLE_USER_CLOUD_TOOLTIP = {
  keyText: 'company_team_users_context_menu_disable_user_cloud_tooltip',
  fallbackText: 'Disable access to Customer Portal account. This does not disable access to the Cloud Organization.',
};

export const REMOVE_USER_LABEL = {
  keyText: 'company_team_users_context_menu_remove_user_label',
  fallbackText: 'Remove user',
};
export const REMOVE_USER_CLOUD_TOOLTIP = {
  keyText: 'company_team_users_context_menu_remove_user_cloud_tooltip',
  fallbackText: 'Remove user from Customer Portal account. This does not remove the user from the Cloud Organization.',
};

export const RESEND_INVITE_LABEL = {
  keyText: 'company_team_users_context_menu_resend_invite_label',
  fallbackText: 'Resend invite',
};
export const RESEND_INVITE_CLOUD_TOOLTIP = {
  keyText: 'company_team_users_context_menu_resend_invite_cloud_tooltip',
  fallbackText: 'Resend invite to Customer Portal account.',
};

export const EDIT_PERMISSIONS_LABEL = {
  keyText: 'company_team_users_context_menu_edit_permissions_label',
  fallbackText: 'Edit permissions',
};
export const EDIT_PERMISSIONS_CLOUD_TOOLTIP = {
  keyText: 'company_team_users_context_menu_edit_permissions_cloud_tooltip',
  fallbackText: 'Edit the user\'s permissions in Customer Portal account.',
};

export const EDIT_PROFILE_LABEL = {
  keyText: 'company_team_users_context_menu_profile_label',
  fallbackText: 'Profile',
};

export const SET_USER = 'SET_USER';
export const UPDATE_GLOBAL_STATE = 'UPDATE_GLOBAL_STATE';
