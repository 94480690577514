import TextField from '@mui/material/TextField';
import styled from 'styled-components';

import ArrowSmallRightBlue50 from '../../img/svg/arrows/ArrowSmall_Right_Blue_50.svg';
// Images
import CloudUpBlue50 from '../../img/svg/file_icons/Cloud_Up_Blue_50.svg';
import { transitionStandard } from '../../js_mixins/transitions';

export const FormWrapper = styled.form`
  overflow-y: auto;
  color: ${p => p.theme.palette.text.primary};
  .EditForm__ContentSection {
    margin-top: ${p => p.theme.spacing(4)}px;
  }
  .UploadForm__TypeSelection {
    padding-bottom: ${p => p.theme.spacing(4)}px;
  }

  .UploadForm__InputLabel {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7;
  }

  .UploadForm__SubmitButton {
    margin-top: ${p => p.theme.spacing(2)}px;
    font-weight: bold !important;
    font-size: 1.6rem !important;
    line-height: 2rem !important;
    color: ${p => p.theme.palette.semantic.colorBackground};
  }

  // To make the two sections in the form
  .UploadForm__ContentSection {
    background-color: ${p => p.theme.palette.semantic.colorBackground};
    padding: ${p => p.theme.spacing(4)}px ${p => p.theme.spacing(5)}px;
  }
`;

export const FormControlWrapper = styled.div`
  width: 100%;
  margin-bottom: ${p => p.theme.spacing(2)}px;
  position: relative;

  .KnowledgeCategories__Checkbox > span.MuiFormControlLabel-label {
    font-size: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    line-height: 20px;
  }

  &.UploadForm__TwoColumnRow {
    display: flex;
    align-items: center;
  }

  .Select__Trigger {
    box-sizing: border-box;
    background-color: ${p => p.theme.palette.semantic.colorBackground};
    padding: ${p => p.theme.spacing(2)}px;
  }

  .UploadForm__Select--Disabled {
    pointer-events: none;
  }

  .UploadForm__Textarea,
  .UploadForm__Option {
    width: 100%;
    border: none;
    border-radius: 8px;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.7;
    display: block;
    box-sizing: border-box;
    padding: ${p => p.theme.spacing(2)}px;
    outline: none;
  }

  .UploadForm__OptionContainer {
    width: 100%;
    position: absolute;
    z-index: 1;
    background-color: ${p => p.theme.palette.semantic.colorBackground};
    border-radius: 8px;
    visibility: hidden;
    opacity: 0;
    height: 0;
    ${transitionStandard('all')};


    &--Active {
      height: auto;
      opacity: 1;
      visibility: visible;
      max-height: 45vh;
      overflow-y: auto;
    }

    .UploadForm__Option {
      border-radius: 0;
      cursor: pointer;
      background-color: inherit;
      padding-left: ${p => p.theme.spacing(6)}px;

      &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &:hover {
        cursor: pointer;
        color: ${p => p.theme.palette.semantic.colorPrimary};
      }

      &--Active {
        color: ${p => p.theme.palette.semantic.colorPrimary};
        background: ${p => p.theme.palette.grey[100]};
        padding-left: ${p => p.theme.spacing(2)}px;

        &:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          height: 16px;
          width: 16px;
          margin-right: ${p => p.theme.spacing(2)}px;
          background: url('${ArrowSmallRightBlue50}') no-repeat;
        }
      }
    }
  }


  .UploadForm__Textarea {
    padding: 0;

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0);
    }

    textarea {
      height: 64px !important;
    }

    // Override
    .MuiInputBase-formControl,
    .MuiInputBase-multiline {
      width: 100%;
    }

    &::placeholder {
      color: ${p => p.theme.palette.text.primary};
    }
  }

  .ErrorMessage {
    color: ${p => p.theme.palette.error.main};
    margin-top: ${p => p.theme.spacing(1)}px;
  }

  .WarningMessage {
    color: ${p => p.theme.palette.yellow[600]};
    margin-top: ${p => p.theme.spacing(1)}px;
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: ${p => p.theme.spacing(1.5)}px;
  }

  .UploadForm__SubCategoryLabel {
    font-size: 1.4rem;
    line-height: ${p => p.theme.spacing(4)}px;
  }

  .UploadForm__SortRankingLabel {
    font-size: 1.4rem;
    margin-left: ${p => p.theme.spacing(3)}px;
  }

  .UploadForm__SortRankingDropdown {
    margin-top: ${p => p.theme.spacing(1)}px;
    margin-left: ${p => p.theme.spacing(3)}px;
    margin-bottom: ${p => p.theme.spacing(4)}px;
  }
`;

export const HeaderContainer = styled.div`
  margin-top: ${p => p.theme.spacing(4)}px;
  margin-bottom: ${p => p.theme.spacing(2)}px;

  .UploadForm__HeaderText {
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    color: ${p => p.theme.palette.text.primary};
    ${transitionStandard('all')};

    & {
      margin-right: ${p => p.theme.spacing(3)}px;
    }

    &--Active {
      color: ${p => p.theme.palette.semantic.colorPrimary};
      text-decoration: underline;
    }
  }
`;

export const FileInputContainer = styled.div`
  cursor: pointer;
  align-items: center;
  padding: ${p => p.theme.spacing(3)}px;
  display: flex;
  position: relative;
  border: 1px solid ${p => p.theme.palette.semantic.colorBorder};

  .FileInputContainer__Text {

    &:before {
      content: '';
      width: 24px;
      height: 16px;
      background: url('${CloudUpBlue50}') no-repeat;
      display: inline-block;
      vertical-align: middle;
      margin-right: ${p => p.theme.spacing(2)}px;
    }
  }

  .FileInputContainer__InputFile {
    display: none;
    background-color: ${p => p.theme.palette.semantic.colorBackground};
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
  }
`;

export const HyperlinkInputContainer = styled(FileInputContainer)`
  cursor: default;
  padding: 0;
  border: none;

  .UploadForm__FormControl {
    margin-bottom: 0;
  }
`;

export const EmbeddedVideoInputContainer = styled.div`
  cursor: default;
  padding: 0;

  .UploadForm__FormControl {
    margin-bottom: 0;
  }
`;

export const TextInput = styled(TextField)`
  width: 100%;
  box-sizing: border-box;

  .MuiInput-underline {
    &:after {
      display: none;
    }
  }
  .MuiInputBase-root {
    font-size: 1.4rem;
    background-color: ${p => p.theme.palette.semantic.colorBackground};
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
    border-radius: ${p => p.theme.spacing(1)}px;
    &:before {
      display: none;
    }
  }
  .MuiFormHelperText-root {
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: 0.5px;
    color: ${p => p.theme.palette.error.main};
  }
  .MuiInputBase-input {
    font-size: 1.4rem;
    line-height: 2.4rem;
    height: auto;
    font-weight: 400;
    border-radius: ${p => p.theme.spacing(1)}px;
    padding: 8px 16px;
    min-height: 48px;
    box-sizing: border-box;
    background-color: ${p => p.theme.palette.semantic.colorBackground};
  }

  &.UploadForm__Hyperlink,
  &.UploadForm__EmbeddedVideo {
    .MuiInputBase-input {
      padding: 24px;
    }
  }

  &.UploadForm__Hyperlink {
    background-color: ${p => p.theme.palette.semantic.colorBackground} !important;
  }
`;

export const SwitchText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${p => p.theme.spacing(2)}px;
`;

export const ErrorMessage = styled.p`
  color: ${p => p.theme.palette.error.main};

  &.UploadForm__FileError {
    position: absolute;
    top: 100%;
  }
`;
