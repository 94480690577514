import Menu from '@mui/material/Menu';
import styled from 'styled-components';

import { transitionStandard } from '../../js_mixins/transitions';

// Card for each product on Product downloads page
export const Card = styled.div`
  margin-bottom: ${p => p.theme.spacing(3)}px;
  border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
  border-radius: ${p => p.theme.spacing(1)}px;
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  padding:0 16px 0 16px;
  min-height: 68px;

  p {
    color: ${props => props.theme.palette.text.secondary};
  }

  .ProductDetailsView {
    display: flex;
    flex-direction: column;
    height: 68px;
    justify-content: center;

    h6 {
      font-weight: 600;
      margin-bottom: ${p => p.theme.spacing(0.5)}px;
      color: ${props => props.theme.palette.text.primary};
    }
  }

  .ProductView {
    display: flex;
    justify-content: space-between;

    &-Column--Opener-Icon {
      color: ${p => p.theme.palette.text.primary};
      cursor: pointer;
      &.dropdown-arrow {
        transition: transform 0.3s ease;
        transform: translateY(${p => p.theme.spacing(1.75)}px);
        
        &.rotate {
        transform: rotate(-180deg) translateY(${p => p.theme.spacing(-1.75)}px);

        }
      }
    }
  }
`;

// Versions list view
export const VersionsView = styled.div`
  padding-bottom: ${p => p.theme.spacing(2)}px;

  .Divider {
    height: 1px;
    background-color: #cfd8dd;
    margin: 0 0 ${p => p.theme.spacing(1)}px 0;
  }

  .ProductVersionView {

    ${transitionStandard('all')};
    &:hover {
      background-color: ${p => p.theme.palette.semantic.colorBackgroundHover};
    }

    a {
      font-weight: 600;
      font-size: 16px;
      color: ${props => props.theme.palette.semantic.colorPrimary};
    }

    &--TitleView {
      display: flex;
      flex-direction: row;
    }

    &--DownloadView {
      display: flex;
      justify-content: end;
      padding-right: ${p => p.theme.spacing(2)}px;
    }
  }

  .LatestTag {
    min-width: 47px;
    height: 16px;
    background: ${p => p.theme.palette.semantic.colorSuccessBackground};
    border-radius: ${p => p.theme.spacing(1)}px;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: ${p => p.theme.palette.semantic.colorSuccessText};
    text-align: center;
    border: 1px solid ${p => p.theme.palette.semantic.colorSuccessText};
    margin-left: ${p => p.theme.spacing(1)}px;
    align-self: center;
    padding: 0px ${p => p.theme.spacing(1)}px 0px ${p => p.theme.spacing(1)}px;
  }

  .VersionsHeader {
    p {
      color: ${props => props.theme.palette.text.secondary};
      font-weight: bold;
    }
  }

  .VersionsHeader,
  .ProductVersionView {
    display: grid;
    grid-template-columns: [first] 45% [line2] 15% [line3] 15% [line4] 15% [col4-start] 10% [end];
    padding: ${p => p.theme.spacing(1)}px 0 ${p => p.theme.spacing(1)}px
      ${p => p.theme.spacing(1.5)}px;
  }

  .DownloadIcon {
    color: ${p => p.theme.palette.text.secondary};
    cursor: pointer;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
`;

// Download menu
export const ProductVersionCardMenu = styled(Menu)`
  position: relative;
  .MuiMenu-paper {
    border-radius: 8px;
    margin-top: 12px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.14),
      0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14);
  }
  .Menu-Option {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: ${p => p.theme.palette.text.primary};
    padding: 6px 16px;
  }
  .MuiList-root {
    padding: 0;
  }
`;
