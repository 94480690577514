import {
  PUBLIC_SUPPORT_CSRF_COOKIE_NAME,
  PUBLIC_SUPPORT_FORM_CSRF_POLLING_INTERVAL_MS,
  PUBLIC_SUPPORT_FORM_RATE_LIMIT_RETRY_AFTER_MS,
} from '@customer-portal/constants';
import Cookies from 'js-cookie';
import type { PropsWithChildren } from 'react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPublicGet } from '../client/axios';
import { PUBLIC_GET_CSRF_TOKEN_URL } from '../constants/network.constants';
import { StoreContext } from '../store';

export const PublicAuthContext = createContext({
  isCsrfTokenSet: false,
  setIsCsrfTokenSet: (isSet: boolean) => {},
  isRateLimited: false,
  setIsRateLimited: (isSet: boolean) => {},
});
export const usePublicAuth = () => useContext(PublicAuthContext);

export const PublicAuthContextProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { dispatch } = useContext(StoreContext);
  const { t } = useTranslation('common');

  const [ isCsrfTokenSet, setIsCsrfTokenSet ] = useState(false);
  const [ isRateLimited, setIsRateLimited ] = useState(false);

  const getCsrfToken = useCallback(async () => {
    try {
      const csrfResult = await axiosPublicGet(PUBLIC_GET_CSRF_TOKEN_URL);
      if (csrfResult.status === 200) {
        setIsCsrfTokenSet(true);
      }
    } catch (e) {
      if (e.response?.status === 429) {
        setIsRateLimited(true);
        return;
      }
      console.error(e);
    }
  }, []);

  const showRateLimitErrorMessage = useCallback(() => {
    dispatch({
      type: 'setBannerIsCloseEnabled',
      payload: false,
    });
    dispatch({
      type: 'setBannerType',
      payload: 'error',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: t(
        'support_form_rate_limited_message',
        'You\'ve made too many requests in a short period of time. Please wait one minute before trying again.'
      ),
    });
    dispatch({
      type: 'setBannerAutoHide',
      payload: false,
    });
  }, [ t, dispatch ]);

  const hideRateLimitErrorMessage = useCallback(() => {
    dispatch({
      type: 'setBannerAutoHide',
      payload: true,
    });
    dispatch({
      type: 'setBannerMsg',
      payload: '',
    });
    dispatch({
      type: 'setBannerType',
      payload: 'error',
    });
    dispatch({
      type: 'setBannerIsCloseEnabled',
      payload: true,
    });
  }, [ dispatch ]);

  // Clear the rate limit error message when page loads
  useEffect(() => {
    hideRateLimitErrorMessage();
  }, [ hideRateLimitErrorMessage ]);

  // Show rate limit error message if rate
  // limited and hide after 1 minute
  useEffect(() => {
    if (isRateLimited) {
      showRateLimitErrorMessage();
      setTimeout(() => {
        setIsRateLimited(false);
        hideRateLimitErrorMessage();
      }, PUBLIC_SUPPORT_FORM_RATE_LIMIT_RETRY_AFTER_MS);
    }
  }, [ isRateLimited, showRateLimitErrorMessage, hideRateLimitErrorMessage ]);

  // Fetch CSRF token if not set and only when not rate limited
  useEffect(() => {
    if (!isCsrfTokenSet && !isRateLimited) {
      getCsrfToken();
    }
  }, [ isCsrfTokenSet, isRateLimited, getCsrfToken ]);

  // Set listener to check every 5 seconds if CSRF
  // token is set in cookies to re-fetch if needed
  useEffect(() => {
    const interval = setInterval(() => {
      if (!Cookies.get(PUBLIC_SUPPORT_CSRF_COOKIE_NAME)) {
        setIsCsrfTokenSet(false);
      }
    }, PUBLIC_SUPPORT_FORM_CSRF_POLLING_INTERVAL_MS);
    return () => clearInterval(interval);
  }, []);

  return (
    <PublicAuthContext.Provider value={{
      isCsrfTokenSet,
      setIsCsrfTokenSet,
      isRateLimited,
      setIsRateLimited,
    }}>
      {children}
    </PublicAuthContext.Provider>
  );
};
