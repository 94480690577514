import styled from 'styled-components';

const customLG = '1679px';
const customMD = '1279px';
const customSM = '1023px';
const customXS = '767px';

export const FooterOuter = styled.footer`
  background: ${(p) => p.theme.palette.grey[900]};
  padding: 0 48px 48px;

  .Footer__Text--Bold {
    font-weight: 700;
  }

  a:hover {
    text-decoration: underline;
    transition: none;
    color: ${p => p.theme.palette.common.white};
  }

  a,
  p {
    color: ${p => p.theme.palette.common.white};
  }

  @media screen and (max-width: ${customLG}) {
    padding: 0 40px 48px;
  }

  @media screen and (max-width: ${customMD}) {
    padding: 0 32px 48px;
  }

  @media screen and (max-width: ${customSM}) {
    padding: 0 24px 48px;
  }

  @media screen and (max-width: ${customXS}) {
    padding: 0 0 48px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    line-height: 1.6;
  }
`;

export const FooterInner = styled.div`
  display: grid;
  max-width: 1584px;
  margin: 0 auto;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 24px;
  align-items: flex-start;

  @media screen and (max-width: ${customLG}) {
    max-width: 100%;
  }

  @media screen and (max-width: ${customSM}) {
    grid-template-columns: repeat(8, minmax(0, 1fr));
    grid-column-gap: 16px;
  }
`;

export const FooterTopHat = styled.div`
  padding: 40px 0 24px;
  grid-column: 1 / 13;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.palette.grey[800]};

  .Footer__UIPathLogo {
    svg {
      vertical-align: middle;
    }
    &:hover svg path {
      fill: ${(p) => (p.theme.palette as any).orange[500]};
    }
  }

  > div:first-of-type {
    display: flex;
    align-items: center;
    margin-left: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .Footer__TopHatLink {
    color: ${p => p.theme.palette.text.primary};
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
      color: inherit:
    }

    > div {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      width: 24px;
      height: 24px;
      border-radius: 30px;
    }
  }

  .Footer__TopHatLink:hover {
    div:before {
      content: '';
      width: 40px;
      height: 40px;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      position: absolute;
      border-radius: 30px;
      background: rgba(255, 255, 255, 0.1);
    }
  }

  > div > a:not(:first-child) {
    height: 32px;
    margin-left: 24px;
    padding-left: 24px;
    position: relative;
    border-left: 1px solid ${(p) => p.theme.palette.grey[800]};
  }

  @media screen and (max-width: ${customSM}) {
    grid-column: 1 / 9;
  }

  @media screen and (max-width: ${customXS}) {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 16px;
    column-gap: 54px;
    align-items: center;
    padding-bottom: 16px;

    .Footer__TopHatLink {
      font-size: 12px;
      line-height: 16px;

      > div {
        width: 16px;
        height: 16px;
      }

      &:hover > div {
        &:before {
          width: 32px;
          height: 32px;
        }
      }
    }

    > div:first-of-type {
      width: 100%;
      border-top: 1px solid ${(p) => p.theme.palette.grey[800]};
      padding-top: 16px;
      justify-content: center;
      order: 3;

      > a {
        justify-content: center;
      }
    }
  }
`;

interface FooterColumnProps {
  columnStart: string;
  columnEnd: string;
  columns: number;
  menuIndex: number;
}

export const FooterColumn = styled.div<FooterColumnProps>`
  ${({
    columnStart, columnEnd, columns, menuIndex, theme,
  }) => `
      display: grid;
      grid-column: span ${columns};
      grid-template-columns: repeat(${columns}, minmax(0, 1fr));
      grid-column-gap: 24px;

      div:nth-of-type(1) {
        grid-column: 1 / ${columns + 1};
        border-bottom: 1px solid ${theme.palette.grey[800]};
        padding-top: 32px;
        padding-bottom: 16px;
        margin-bottom: 28px;

        p {
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 1px;
          margin: 0;
          color: ${theme.palette.grey[600]};
        }

        span {
          display: none;
          border-bottom: 1px solid ${theme.palette.grey[500]};
          width: 100%;
          margin-left: 16px;
        }
      }

      div:nth-of-type(2) {
        grid-column: 1 / 3;
      }

      div:nth-of-type(3) {
        grid-column: 3 / 5;
      }

      @media screen and (max-width: ${customSM}) {
        grid-column: ${menuIndex % 2 != 0 ? `1 / 5` : `5 / 9`};
        grid-template-columns: repeat(4, minmax(0, 1fr));

        div:nth-of-type(1) {
          grid-column: 1 / 5;
        }
      }

      @media screen and (max-width: ${customXS}) {
        ${columns === 4 ? `grid-column: 1 / 9;` : ``}

        div:nth-of-type(1) {
          display: flex;
          align-items: center;
          border-bottom: none;
          margin-bottom: 0px;

          span {
            display: block;
          }
        }
      }
    `}

  li + li {
    margin-top: 16px;
  }

  a {
    font-size: 14px;

    &.Footer__Text--Bold {
      font-size: 16px;
    }
  }

  ul + h6 {
    margin-top: 24px;
  }

  h6 + ul {
    margin-top: 16px;
  }

  .Footer__Group-Title p,
  h6 {
    line-height: 24px;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    color: ${(p) => p.theme.palette.grey[600]};
  }

  @media screen and (max-width: ${customXS}) {
    grid-column: 1 / 9;
    padding: 0 24px;

    .Footer__Group-Title p,
    a.Footer__Text--Bold,
    h6 {
      font-size: 14px;
    }

    .Footer__Group-Title p {
      white-space: nowrap;
    }
  }
`;

export const FooterSitemap = styled.div`
  display: grid;
  grid-column: 1 / 13;
  padding: 0 0 32px;
  border-bottom: 1px solid ${(p) => p.theme.palette.grey[800]};
  align-items: start;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 24px;

  @media screen and (max-width: ${customSM}) {
    grid-column: 1 / 9;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    grid-column-gap: 16px;
  }

  @media screen and (max-width: ${customXS}) {
    grid-column: 1 / 9;
    padding: 0 0 24px;
  }
`;

export const FooterSocial = styled.div`
  grid-column: 1 / 13;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  border-bottom: 1px solid ${(p) => p.theme.palette.grey[800]};
  padding: 16px 0;
  grid-column-gap: 24px;

  .Footer__SocialNetwork {
    grid-column: 1 / 4;
    display: flex;

    > div {
      display: flex;
    }
  }

  .Footer__SocialNetwork-IntroText {
    letter-spacing: 4px;
    padding: 8px 0;
    white-space: nowrap;
    margin: 0;
  }

  svg {
    width: 24px;
    height: 24px;
    object-fit: contain;
    vertical-align: middle;
  }

  ul {
    display: flex;
    gap: 32px;
    margin-left: 32px;
    align-items: center;

    li a:hover svg path {
      fill: white;
    }
  }

  @media screen and (max-width: ${customMD}) {
    display: flex;
    flex-direction: column;

    .Footer__SocialNetwork {
      width: 100%;
      justify-content: center;
      margin-top: 0;
    }

    .Footer__SocialNetwork-IntroText {
      display: none;
    }

    ul {
      margin-left: 0;
      gap: 96px;
    }
  }

  @media screen and (max-width: ${customSM}) {
    grid-column: 1 / 9;
  }

  @media screen and (max-width: ${customXS}) {
    ul {
      gap: 48px;
      flex-wrap: wrap;
      justify-content: center;
    }

    border-top: 1px solid ${(p) => p.theme.palette.grey[900]};
    margin-top: 32px;
  }
`;

export const FooterLegal = styled.div`
  display: grid;
  grid-column: 1 / 13;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 24px;
  padding: 24px 24px 0;

  // one trust
  #ot-sdk-btn {
    background: none !important;
    border: none !important;
    padding: 0 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: #595a5c !important;

    &:hover {
      color: #fff !important;
    }
  }

  p {
    text-align: center;
    grid-column: 1 / 13;
    font-size: 12px;
    line-height: 16px;
    margin: 16px 0 0;
  }

  p,
  a {
    color: #595a5c;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    grid-column: 1 / 13;
    column-gap: 36px;

    > li:not(:last-of-type) {
      a {
        position: relative;

        &:before {
          content: '\\2022';
          position: absolute;
          font-size: 12px;
          line-height: 16px;
          color: #595a5c;
          width: 36px;
          text-align: center;
          left: 100%;
          top: 0;
        }
      }
    }
  }

  a {
    font-size: 12px;
    line-height: 16px;
  }

  @media screen and (max-width: ${customSM}) {
    grid-column: 1 / 9;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    grid-column-gap: 16px;

    > p,
    ul {
      grid-column: 1 / 9;
    }
  }

  @media screen and (max-width: ${customXS}) {
    ul {
      column-gap: 18px;
      > li:not(:last-of-type) {
        a {
          &:before {
            width: 18px;
          }
        }
      }
    }
  }
`;
