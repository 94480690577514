import styled from 'styled-components';

export default styled.main`
  ${props => `
    margin-bottom: 0px !important;
    ${props.theme.breakpoints.up('md')} {
      margin-bottom: 0px !important;
    }
    background: ${props.theme.palette.semantic.colorBackground};

    // Styling for list of containers
    .CustomerPortalKBDocumentsContainer {
      display: flex;
      flex-direction: column;

      ${props.theme.breakpoints.up('md')} {
        flex-direction: row;
      }

      @media screen and (min-width: '1920px') {
        padding: 0 !important;
      }
    }

    // Styling for support kb containers
    .CustomerPortalSupportKBContainer {
      display: flex;
      flex-direction: column; 
      align-items: center;
      text-align: center;

      .CustomerPortalSupportKBContainer__findSolution {
        border-radius: 8px;
        outline: none;
        text-decoration: none;
        margin-top: ${props.theme.spacing(2)}px;
      }
      .CustomerPortalSupportKBContainer__Header {
        color: ${props => props.theme.palette.text.primary};
      } 
      .CustomerPortalSupportKBContainer__SubHeader {
        color: ${props => props.theme.palette.text.secondary};
      }      
    }    
  `};
`;
