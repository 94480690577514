import Button from '@mui/material/Button';
import React, {
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import * as styles from '../assets/css/CustomerPortal-LandingPage';
import { axiosPublicPost } from '../client/axios';
import BasicHero from '../components/BasicHero/CustomerPortal-BasicHero';
import {
  PUBLIC_VALIDATE_USER_EMAIL_DOMAIN_URL,
  UNREGISTERED_PATH_PREFIX,
} from '../constants/network.constants';
import { useAuth } from '../contexts/auth';
import { usePublicAuth } from '../contexts/public-auth';
import AuthUserUtil from '../lib/auth.util';
import { onPublicLogin } from '../utils/publicExperience';

export type CustomerPortalLandingProps = {
  text: any;
  isPublicLandingPage: boolean;
  setShowSupportForm?: (value: boolean) => void;
};

const CustomerPortalLanding = (props: CustomerPortalLandingProps) => {
  const {
    text,
    isPublicLandingPage,
    setShowSupportForm,
  } = props;
  const {
    setIsCsrfTokenSet,
    isCsrfTokenSet,
    setIsRateLimited,
    isRateLimited,
  } = usePublicAuth();
  const { user } = useAuth();
  const email = AuthUserUtil.getEmail(user);
  const { t } = useTranslation('common');
  const history = useHistory();
  const baseClass = 'LandingPage';
  const isNavigationDisabled = !isCsrfTokenSet || isRateLimited;
  const [ isEmailDomainValid, setIsEmailDomainValid ] = useState(false);

  // need to block the registration button if the email domain is blacklisted
  useEffect(() => {
    const checkEmailDomainValidity = async () => {
      try {
        const emailResult = await axiosPublicPost(
          PUBLIC_VALIDATE_USER_EMAIL_DOMAIN_URL,
          { email }
        );
        setIsEmailDomainValid(emailResult.data.isValid);
      } catch (e) {
        if (e.response?.status === 401 && setIsCsrfTokenSet) {
          setIsCsrfTokenSet(false);
          return;
        }
        if (e.response?.status === 429 && setIsRateLimited) {
          setIsRateLimited(true);
          return;
        }
        console.log(e);
      }
    };
    if (!isPublicLandingPage) {
      checkEmailDomainValidity();
    }
  }, [ email, isPublicLandingPage ]);

  return (
    <>
      <BasicHero
        title={isPublicLandingPage ? t('support_form_new_technical_support_request_title', 'New Technical Support Request') : ''}
        smallFont />
      <styles.LandingPageContainer>
        <div className={`${baseClass}__Welcome-Text`}>
          <div className={`${baseClass}__Heading`}>
            {t('support_form_welcome_heading', 'Welcome to the UiPath Customer Portal')}
          </div>
          <div className={`${baseClass}__Description`}>
            {t(text.description1.textKey, text.description1.fallbackText)}
          </div>
          <div
            className={`${baseClass}__Description`}
            dangerouslySetInnerHTML={{ __html: t(text.description2.textKey, text.description2.fallbackText) }} />
          <div className={`${baseClass}__Button-Container`}>
            {(isPublicLandingPage || isEmailDomainValid) && (
              <Button
                variant='contained'
                color='secondary'
                onClick={() => isPublicLandingPage ? onPublicLogin() : history.push(`${UNREGISTERED_PATH_PREFIX}/activate-account`)}
                className={`${baseClass}__Button`}
                data-testid='register-button'
              >
                {t(text.button1.textKey, text.button1.fallbackText)}
              </Button>
            )}
            <Button
              variant='contained'
              color='secondary'
              onClick={() => isPublicLandingPage && setShowSupportForm ? setShowSupportForm(true) : history.push(`${UNREGISTERED_PATH_PREFIX}/support/add-case`)}
              className={`${baseClass}__Button`}
              data-testid='continue-button'
              disabled={isNavigationDisabled}
            >
              {t(text.button2.textKey, text.button2.fallbackText)}
            </Button>
          </div>
        </div>
      </styles.LandingPageContainer>
    </>
  );
};

export default CustomerPortalLanding;
