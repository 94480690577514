import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Trans,
  useTranslation,
} from 'react-i18next';

import { CloudOutageBannerContainer } from '../assets/css/CloudOutageBanner';
import { StoreContext } from '../store';
import {
  getWebRedirectOrigin,
  isCloudEnv,
} from '../utils/cloud';

const CloudOutageBanner = () => {
  const { t } = useTranslation('common');
  const { state } = useContext(StoreContext);
  const [ showCloudOutageBanner, setShowCloudOutageBanner ] = useState(false);

  useEffect(() => {
    const isCompanyOnCloud = !!(state.currentUserCompany?.additionalCompanyInfo?.cloudOrgId);
    setShowCloudOutageBanner(!isCloudEnv() && isCompanyOnCloud);
  }, [
    state.currentUserCompany?.additionalCompanyInfo?.cloudOrgId,
  ]);

  const onHide = async () => {
    try {
      setShowCloudOutageBanner(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      {showCloudOutageBanner && (
        <CloudOutageBannerContainer showBanner={showCloudOutageBanner}>
          <div>
            <Trans
              t={t}
              i18nKey="cloud_outage_banner_message"
              defaults={'Automation Cloud is currently having issues, so your Customer Portal account is currently accessible from <a href=\'{{webUrl}}\' target=\'_blank\'>{{webURL}}</a>. See <a href=\'https://status.uipath.com\' target=\'_blank\'>status.uipath.com</a> for more information.'}
              components={{ a: <a /> }}
              values={{ webURL: getWebRedirectOrigin() }}
            />
            <button onClick={onHide}>{t('cloud_outage_banner_hide_button', 'Hide this message')}</button>
          </div>
        </CloudOutageBannerContainer>
      )}
    </div>
  );
};

export default CloudOutageBanner;
