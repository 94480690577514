import styled from 'styled-components';

export const HapoAddGroupModalWrapper = styled.section`
 
  .No-Groups-Text {
    color: ${p => p.theme.palette.text.primary};
    text-align: center;
    background: ${p => p.theme.palette.grey[100]};
    border-radius: ${p => p.theme.spacing(1)}px;
    padding: ${p => p.theme.spacing(2)}px;
  }

  .Table-Row {
    cursor: pointer;
    &:hover {
        background-color: ${p => p.theme.palette.semantic.colorPrimaryDarker};
      }
  }
`;
