// Styles
import { ADMIN_USER_ROLE_TO_DISPLAY_NAME } from '@customer-portal/constants';
import {
  Popover,
  TableCell,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';

// Icons
import type { AdminUser } from '../../../pages/Admin/user-management';
import ApolloIcon from '../../ApolloIcon';

type AdminUserRowProps = {
  index: number;
  admin: AdminUser;
  handleEditClick: (admin: any) => void;
  handleInactivateClick: (userEmail: string, isActive: boolean) => void;
};

const OptionsPopover = styled(Popover)`
  .MuiPopover-paper {
    background: ${p => p.theme.palette.semantic.colorBackgroundRaised};
    width: 200px;
    box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
  }
`;

const PopoverOption = styled.p`
  padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(3)}px;
  color: ${p => p.theme.palette.text.primary} !important;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.palette.semantic.colorBackgroundHover}};
  }
`;

const AdminUserRow = ({
  index,
  admin,
  handleEditClick,
  handleInactivateClick,
}: AdminUserRowProps) => {
  const [ popoverAnchorElem, setPopoverAnchorElem ] = useState(null);
  const roles = admin.roles
    .filter((r) => r.name in ADMIN_USER_ROLE_TO_DISPLAY_NAME)
    .map((r) => ADMIN_USER_ROLE_TO_DISPLAY_NAME[r.name]);

  /* Events */
  // Popover actions
  const handleRowOptionClick = (e: any) => {
    if (e.currentTarget === popoverAnchorElem || !e) {
      return;
    }

    setPopoverAnchorElem(e.currentTarget);
  };
  const handleRowOptionClose = () => {
    setPopoverAnchorElem(null);
  };

  return (
    <TableRow
      key={index}
      data-testid="AdminUserRow">
      <TableCell align="left">{admin.name}</TableCell>
      <TableCell
        align="left"
        data-testid="AdminRowEmail">
        {admin.userEmail}
      </TableCell>
      <TableCell align="left">
        {roles.map((role) => (
          <p key={role}>{`• ${role}`}</p>
        ))}
      </TableCell>
      <TableCell align="left">
        {admin.isActive ? 'Active' : 'Inactive'}
      </TableCell>
      <TableCell align="left">
        {admin.createdAt
          ? moment(admin.createdAt).format('MM/DD/YYYY')
          : ''}
      </TableCell>
      <TableCell align="left">{admin.addedBy}</TableCell>
      <TableCell align="left">
        <p
          className={`action-pill ${admin.isActive ? 'inactivate' : 'activate'}`}
          onClick={() => handleInactivateClick(admin.userEmail, admin.isActive)}
        >
          {admin.isActive ? 'Inactivate' : 'Reactivate'}
        </p>
      </TableCell>
      <TableCell>
        <ApolloIcon
          icon='more_vert'
          fontSize='inherit'
          className='icon'
          data-testid="MenuIcon"
          onClick={(e: any) => {
            handleRowOptionClick(e);
          }}
        />
      </TableCell>
      <OptionsPopover
        open={Boolean(popoverAnchorElem)}
        elevation={0}
        anchorEl={popoverAnchorElem}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleRowOptionClose}
      >
        <PopoverOption
          onClick={() => {
            handleEditClick(admin);
            handleRowOptionClose();
          }}
        >
          Edit Roles
        </PopoverOption>
      </OptionsPopover>
    </TableRow>
  );
};

export default AdminUserRow;
