import styled from 'styled-components';

import MoreHorizontal from '../../../img/svg/action_icons/more_horiz_24px_ffffff.svg';

export { ShortDescription } from './RequestForQuoteLicenseDetailPopUp';
export const ShortDescriptionHeader = styled.p`
  font-size: 1.4rem;
  color: ${p => p.theme.palette.text.secondary};
  line-height: 1.7;
`;

export const ShortDescriptionWrapper = styled.div`
  margin-top: ${p => p.theme.spacing(1)}px;
  flex-grow: 1;
  overflow: hidden;
  padding: 0 16px;
`;

export const ShortDescriptionViewMore = styled.i`
  background-image: url(${MoreHorizontal});
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${p => p.theme.palette.semantic.colorPrimary};
  border-radius: 42px;
  bottom: 52px;
  right: 16px;
  cursor: pointer;
  position: absolute;
  padding: 8px 16px;
`;
