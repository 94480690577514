import styled from 'styled-components';

export const ProductSelect = styled.div`

    ${(props) => `
      padding: 24px 0;
      border-bottom: 3px solid transparent;

      .RequestForQuote__onPremExistingOrchestratorOptions,
      .RequestForQuote__onPremNewOrchestratorOptions {
        margin-left: -9px;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        label {
          font-size: 1.4rem;
          line-height: 20px;
          
          cursor: pointer;
        }

      }

      .RequestForQuote__onPremExistingOrchestratorOptions__selectWrapper{
        position: relative;
      }

      .ProductSelect__radioWrapper {
        display: none;

        &--active {
          display: flex;
          justify-content: flex-start;
          align-items: centes;

          ${props.theme.breakpoints.down('xs')} {
            flex-wrap: wrap;
          }
        }
      }

      .RequestForQuote__onPremExistingOrchestratorOptions__selectList {
        margin: 16px 0 16px 20px;
        padding: 16px;
        background-color: ${props.theme.palette.semantic.colorBackground};
        border: 1px solid ${props.theme.palette.semantic.colorBorder};
        border-radius: 8px;

        width: 400px;

        display: none;

        cursor: pointer;

        &--active {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }

      .RequestForQuote__onPremExistingOrchestratorOptions__selectedItem__arrowIcon {
        color: ${props.theme.palette.text.primary};
      }
      .dropdown-arrow {
        transition: transform 0.3s ease;
      }

      .dropdown-arrow.rotate {
        transform: rotate(-180deg);
      }

      .RequestForQuote__onPremExistingOrchestratorOptions__selectedItem__title {
        color: ${props.theme.palette.text.primary};
        font-size: 1.4rem;
        line-height: 20px;
      }

      .RequestForQuote__onPremExistingOrchestratorOptions__selectedItem__licenceCode {
        color: ${props.theme.palette.text.secondary};
        font-size: 1rem;
        line-height: 16px;
      }

      .RequestForQuote__onPremExistingOrchestratorOptions__dropdown {
        position: absolute;
        top: 80px;
        left: 20px;

        z-index: 1;

        max-height: 250px;
        overflow-y: auto;

        background-color: ${props.theme.palette.semantic.colorBackground};
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1), 0px 4px 10px rgba(0, 0, 0, 0.05);
        border-radius: 8px;

        visibility: hidden;
        opacity: 0;

        transition: all .5s ease;

        &--active {
          visibility: visible;
          opacity: 1;

          transition: all .5s ease;
        }

        &::-webkit-scrollbar-track {
          border-radius: 0;
          background-color: #F7F7F7;
        }

        &::-webkit-scrollbar {
          width: 6px;
          background-color: #6a6a6a;
        }

        &::-webkit-scrollbar-thumb{
          border-radius: 0;
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
          background-color: #555;
        }

      }

      .RequestForQuote__onPremExistingOrchestratorOptions__dropdownItem {
        padding: 16px 16px 16px 10px;
        background-color: ${props.theme.palette.semantic.colorBackgroundRaised};
        border-bottom: 1px solid transparent;

        width: 400px;

        cursor: pointer;

        &:hover {
          ${'' /* border-bottom: 1px solid #000; */}
          background-color: ${props.theme.palette.semantic.colorBackgroundHover};
        }
      }

      .ProductSelect__box {
        margin: 16px 0 16px 8px;
        padding: 16px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        background-color: ${props.theme.palette.semantic.colorBackground};
        border: 1px solid #CFD8DD;
        box-sizing: border-box;
        border-radius: 3px;
      
        max-width: 300px;
        width: 260px;

        cursor: pointer;

        &--active {
          max-width: 460px;
          width: 430px;

          &:first-child {
            width: 400px;
          }

          &:last-child {
            width: 460px;
          }

        }

        &--hide {
          display: none;
        }

        p {
          color: ${props.theme.palette.text.secondary};
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }

        a {
          color: ${props.theme.palette.semantic.colorPrimary} !important;
        }

        .ProductSelect__box__CTA{
          color: ${props.theme.palette.text.secondary};

          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }
      }

    `};

`;
