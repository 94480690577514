import styled from 'styled-components';

export const RequestForQuoteLicenseDetailPopUp = styled.section`
  position: fixed;
  top: 0;
  left: 0;

  z-index: 1000;

  background-color: rgba(24, 32, 39, 0.3);

  visibility: hidden;
  opacity: 0;

  transition: all 0.5s ease;

  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  .RequestForQuote__BrowseProducts__licenseDetailContainer {
    position: relative;
    box-sizing: border-box;
    padding: 32px;
    width: 565px;

    background-color: ${p => p.theme.palette.semantic.colorBackgroundRaised};
    color: ${p => p.theme.palette.text.secondary};
    border-radius: 2px;

    ${p => p.theme.breakpoints.down('xs')} {
      width: 80%;
      padding: 24px;
      max-height: 100vh;
      overflow-y: auto;
    }

    .RequestForQuote__BrowseProducts__licenseDetailClosePopUp {
      position: absolute;
      top: 24px;
      right: 24px;

      width: 10px;
      height: 10px;

      padding: 8px;

      cursor: pointer;
    }

    .RequestForQuote__BrowseProducts__licenseDetailTitle {
      padding-bottom: 16px;
      padding-right: 60px;

      color: ${p => p.theme.palette.text.primary};
      font-size: 24px;
      font-weight: normal;
      line-height: 29px;

      ${p => p.theme.breakpoints.down('xs')} {
        padding-right: 24px;
      }
    }

    .RequestForQuote__BrowseProducts__licenseDetailFullDesc {
      padding-bottom: 16px;
      padding-right: 60px;

      color: ${p => p.theme.palette.text.secondary};
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;

      ${p => p.theme.breakpoints.down('xs')} {
        padding-right: 24px;
      }
    }

    .RequestForQuote__BrowseProducts__licenseDetailShortDesc {
      padding-right: 60px;

      color: #526069;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;

      ${p => p.theme.breakpoints.down('xs')} {
        padding-right: 24px;
      }
    }

    ${'' /* PopUp Footer */}
    .RequestForQuote__BrowseProducts__licenseDetailFooter {
      padding-top: 60px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      ${p => p.theme.breakpoints.down('xs')} {
        flex-direction: column;
        align-items: flex-start;
      }

      .RequestForQuote__BrowseProducts__licenseDetailAddToCart {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        cursor: pointer;

        ${p => p.theme.breakpoints.down('xs')} {
          margin-bottom: ${p => p.theme.spacing(2)}px;
        }

        p {
          margin-left: 12px;
          color: ${p => p.theme.palette.semantic.colorPrimary};
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }

        .RequestForQuote__BrowseProducts__licenseDetailAddToCartIcon {
            fill: ${p => p.theme.palette.semantic.colorPrimary};
          }

        &--disabled {
          p {
            color: #e3e3e3;
          }

          .RequestForQuote__BrowseProducts__licenseDetailAddToCartIcon {
            fill: #e3e3e3;
          }
        }

        ${'' /* Select Quantity */}
        .RequestForQuote__BrowseProducts__licenseDetailQuantity {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .RequestForQuote__BrowseProducts__QuantityLabel {
            margin: 0;
            color: #526069;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
          }

          .RequestForQuote__BrowseProducts__QuantityInput {
            width: 74px;
            margin-left: 8px;
          }

          span {
            margin-left: 16px;
            color: #526069;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
          }
        }
      }

      .RequestForQuote__BrowseProducts__licenseDetailClose {
        padding: 8px 32px;
        background-color: ${p => p.theme.palette.semantic.colorBackground};

        color: #0067df;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;

        border: 1px solid #a4b1b8;
        box-sizing: border-box;
        border-radius: 3px;

        cursor: pointer;
        outline: none;
      }
    }
  }
`;

export const ShortDescriptionHeader = styled.p`
  font-weight: bold;
`;

export const ShortDescription = styled.div`
  font-size: 1.4rem;
  color: ${p => p.theme.palette.text.secondary};
  line-height: 1.7;
  /* DO NOT APPLY ANY MORE FORMATTING AS IT IS RICH TEXT */
`;
