import styled from 'styled-components';

// Images and Icons
import HeroBGSrc from '../../img/svg/kb/KB_BG.svg';
import HeroBGSrcDarkTheme from '../../img/svg/kb/KB_BG_DarkTheme.svg';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: url(${p => p.theme.palette.type === 'light' ? HeroBGSrc : HeroBGSrcDarkTheme });
  background-repeat: no-repeat;
  ${props => `
    margin-bottom: ${props.theme.spacing(6)}px;
    ${props.theme.breakpoints.up('md')} {
      background-size: cover;
    }
    @media screen and (min-width: 1920px) {
      margin-bottom: ${props.theme.spacing(8)}px;
    }
    // For the Container
    .CustomerPortalProductDownloads__HeadingContainer {
      margin-top: ${props.theme.spacing(8)}px;
      ${props.theme.breakpoints.up('md')} {
        margin-top: ${props.theme.spacing(20)}px;
      }
    }

  `};

  .CustomerPortalProductDownloads__SearchSortContainer {
    display: flex;
    justify-content: space-between;
    ${props => props.theme.breakpoints.down('sm')} {
      flex-direction: column;
    }

    &__productSort {
      align-self: center;
    }
  }

`;
