import { Popover } from '@mui/material';
import styled from 'styled-components';

export const ModalFormWrapper = styled.form`
  margin-top: ${p => p.theme.spacing(2)}px;

  .NewFolderButton {
    margin-top: ${p => p.theme.spacing(4)}px;
  }
  
  .SubmitButton {
    color: ${p => p.theme.palette.semantic.colorBackground};
  }
`;

export const SelectOption = styled.div`
  .ExpandOptions__Button {
    padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(3)}px;
    cursor: pointer;
    outline: none;
    border: 0;
    background-color: inherit;
    font-size: 1.4rem;
    font-weight: 400 !important;
    line-height: 1.7;
    width: 160px;
    text-align: left;
    color: ${p => p.theme.palette.text.primary};

    &:hover {
      background: ${p => p.theme.palette.semantic.colorBackgroundHover};
    }
  }

  &:last-child:not(:first-child) {
    .ExpandOptions__Button {
      color: ${p => p.theme.palette.semantic.colorErrorText};
    }
  }
`;

export const OptionsPopover = styled(Popover)`
  cursor: pointer;

  .MuiPopover-paper {
    background-color: ${p => p.theme.palette.semantic.colorBackgroundRaised};
  }
`;

export const UploadFileTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${p => p.theme.palette.text.primary};
  
  .UploadFile__SectionTitle {
    display: flex;
    align-items: center;

    p {
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 24px;
    }

    img {
      padding-left: ${p => p.theme.spacing(1)}px;
      cursor: pointer;
    }
  }

  .UploadFile__Template {
    a {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 24px;

      color: ${p => p.theme.palette.semantic.colorPrimary};
    }
  }
`;

export const UploadFile = styled.div`
  margin-bottom: 40px;
  margin-top: ${p => p.theme.spacing(1)}px;
  padding: 30px;
  background-color: ${p => p.theme.palette.semantic.colorBackground};
  border-radius: ${p => p.theme.spacing(1)}px;
  border: 1px solid ${p => p.theme.palette.semantic.colorBorder};

  .CustomerPortalSupportUploadFile__content {
    display: flex;
    align-items: center;
    p {
      font-size: 1.4rem;
      line-height: ${p => p.theme.spacing(2.5)}px;

      color: ${p => p.theme.palette.text.primary};
    }

    span {
      padding-left: ${p => p.theme.spacing(1)}px;
      
      button {
        background: none;
        border: 0;

        font-weight: 600;
        font-size: 1.4rem;
        line-height: ${p => p.theme.spacing(2.5)}px;
        color: ${p => p.theme.palette.semantic.colorPrimary};

        cursor: pointer;
      }
    }
  }

  .CustomerPortalSupportUploadFile__fileNotUploaded {
    display: flex;
    align-items: center;
    p {
      font-size: 1.4rem;
      line-height: ${p => p.theme.spacing(2.5)}px;

      color: ${p => p.theme.palette.text.primary};
    }

    span {
      padding-left: ${p => p.theme.spacing(1)}px;
      
      button {
        background: none;
        border: 0;

        font-weight: 600;
        font-size: 1.4rem;
        line-height: ${p => p.theme.spacing(2.5)}px;
        color: ${p => p.theme.palette.semantic.colorPrimary};

        cursor: pointer;
      }
    }
  }

  .CustomerPortalSupportUploadFile__fileUploaded {
    position: relative;

    .CustomerPortalSupportUploadFile__fileInfo { 
      display: flex;
      justify-content: space-between;
      width: 80%;

      @media only screen and (max-width: 425px) {
        flex-direction: column;
        width: auto;
      }
    }

    .CustomerPortalSupportUploadFile__fileName {
      display: flex;

      .icon {
        color: ${p => p.theme.palette.semantic.colorPrimary};
      }

      p {
        margin-left: ${p => p.theme.spacing(3)}px;
        font-size: 1.4rem;
        line-height: ${p => p.theme.spacing(2.5)}px;
        
        color: ${p => p.theme.palette.semantic.colorPrimary};
        cursor: pointer;
      }
    }

    .CustomerPortalSupportUploadFile__updatedAt {
      p {
        color: ${p => p.theme.palette.text.primary};
        font-size: 1.4rem;
        line-height: ${p => p.theme.spacing(2.5)}px;
      }

      @media only screen and (max-width: 425px) {
        p {
          padding-top: ${p => p.theme.spacing(2.5)}px;
        }
      }
    }

    .CustomerPortalSupportUploadFile__optionsContainer {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      .CustomerPortalSupportUploadFile__options {
        border: none;
        outline: none;
        background: inherit;
        cursor: pointer;
        transform-origin: 50% 50%;
        transform: rotate(90deg);

        @media only screen and (max-width: 425px) {
          width: auto;
          top: calc(50% - 14px);
        }

        i {
          width: 3px;
          height: 3px;
          background: ${p => p.theme.palette.grey[750]}};
          border-radius: 50%;
          display: inline-block;
          margin-right: ${p => p.theme.spacing(0.5)}px;
        }
      }

      .icon {
        background-color: ${p => p.theme.palette.semantic.colorBackground};
        font-size: 24px;
        color: ${p => p.theme.palette.text.primary};
      }

    }
  }
`;
