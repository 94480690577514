import styled from 'styled-components';

// images
import DownwardArrow from '../../img/svg/arrows/Downward_Arrow_Grey.svg';

export const CompaniesAndPermissions = styled.div`
  ${p => p.theme.breakpoints.up('lg')} {
    width: 50%;
    margin-left: 340px;
  }

  .headerTitle {
    margin: ${p => p.theme.spacing(2.5)}px 0;

    font-size: 1.2rem;
    font-weight: bold;
    line-height: 16px;

    color: ${p => p.theme.palette.text.primary};
    text-transform: uppercase;
  }

  .CompaniesListItem {
    margin-bottom: ${p => p.theme.spacing(2)}px;
    padding: ${p => p.theme.spacing(2)}px;

    background: ${p => p.theme.palette.semantic.colorBackground};
    border: 1px solid ${p => p.theme.palette.semantic.colorBorder};
    border-radius: 4px;
  }

  .CompaniesListItem__header {
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;

      transform: translateY(-50%) rotate(180deg);

      background: url(${DownwardArrow});
      background-size: 100%;
      background-repeat: no-repeat;

      width: 12px;
      height: 8px;
    }

    .CompanyName {
      width: 60%;

      font-size: 1.4rem;
      font-weight: bold;
      line-height: 16px;

      color: ${p => p.theme.palette.text.primary};
    }

    .CompanyRole {
      padding-right: ${p => p.theme.spacing(4)}px;

      flex: 0 0 auto;
      width: 30%;

      ${p => p.theme.breakpoints.up('md')} {
        width: 20%;
      }

      font-size: 1.4rem;
      line-height: 20px;

      color: ${p => p.theme.palette.text.secondary};
      text-align: right;
    }

    cursor: pointer;
  }

  .CompaniesListItem--active .CompaniesListItem__header {
    &:after {
      transform: translateY(-50%) rotate(0deg);
    }
  }

  .CompaniesListItem__body {
    display: none;
  }

  .CompaniesListItem--active .CompaniesListItem__body {
    display: block;
    margin-top: ${p => p.theme.spacing(1.5)}px;
  }

  .CompaniesListItem__bodyHeader {
    margin-bottom: ${p => p.theme.spacing(1.5)}px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: ${p => p.theme.palette.text.secondary};
    font-size: 1.4rem;
    line-height: 20px;

    .CompaniesListItem__bodyPermissions {
      width: 40%;

      ${p => p.theme.breakpoints.up('md')} {
        width: 45%;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }

    .CompaniesListItem__bodyPermissionsType {
      flex: 0 0 auto;
      width: 50%;

      ${p => p.theme.breakpoints.up('md')} {
        width: 30%;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        width: 50%;
        text-align: center;
      }
    }

    &--content {
      color: ${p => p.theme.palette.text.primary};
    }
  }

  .ContactAccountAdmin {
    padding-bottom: ${p => p.theme.spacing(2)}px;

    span {
      font-size: 1.4rem;
      line-height: 20px;
      color: ${p => p.theme.palette.text.secondary};

      a {
        text-decoration: underline;
        color: ${p => p.theme.palette.semantic.colorPrimary};
      }
    }
  }
`;
