import { FeatureFlags } from '@customer-portal/constants';
import {
  Icon,
  IconButton,
  MenuItem,
  Switch,
} from '@mui/material';
import {
  DesktopDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import Helmet from 'react-helmet';

import {
  ActionButton,
  Column,
  DebugPageView,
  JobSelect,
  JSONView,
  Row,
} from '../../assets/css/Debug/DebugPage';
import {
  axiosGet,
  axiosPost,
} from '../../client/axios';
import ConfirmationModal from '../../components/ConfirmationModal';
import TextLabel from '../../components/TextLabel';
import {
  BATCH_JOB_URL,
  CLEAR_CACHE_URL,
  RECALCULATE_CONSUMED_QUANTITY_URL,
  USER_COMPANIES_URL,
} from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
import {
  getJobMessage,
  isBatchJobAllowed,
} from '../../lib/debug.util';
import { getStringEnv } from '../../lib/index.util';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { StoreContext } from '../../store';
import { featureFlag } from '../../utils/featureFlag';

const keysToAvoid = [
  'rpaRole',
  'myCompanies',
  'teamUsers',
  'companyLogo',
  'isLoading',
  'uiPathContacts',
  'companyLicenses',
  'accountQuestionnaire',
];

const AllowedJobsToRun = {
  0: 'None',
  3: 'Update All Technical Solution Articles',
  5: 'Update All Customers',
  6: 'Update Boon Customers',
  7: 'Sync HAPO/ELA/UTO Deals',
  9: 'Update Support Form Data',
  10: 'Process DeadLetter Queue',
  11: 'Remove Deleted Customers',
  13: 'Relete Deleted Technical Solution Articles',
  14: 'Preload Users from SFDC',
  15: 'Index Forums',
  16: 'Sync ETM',
  17: 'Remove Pending Users',
  18: 'Remove Inactive Collab Docs',
  19: 'Fetch Support Users',
  20: 'Add Support Groups to Users',
  21: 'Remove Support Files',
  22: 'Update All Jira Known Issues',
  23: 'Fetch Coveo Visits Data',
  24: 'Fetch Past Coveo Visit Data',
  25: 'Sync SFDC System Entities',
  26: 'Fetch Status Updates Incidents',
};

enum ConfirmationActionType {
  none,
  clearCache,
  recalculateConsumedQuantity,
  runBatchJob,
  toggleFeatureFlag,
}

export const DebugPage = () => {
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const isDebugPageManagementAllowed = UserPermissionsHelper.isDebugPageManagementAllowed();
  const { getAccessToken } = useAuth();
  const [ jobNumber, setJobNumber ] = React.useState(0);
  const [ startDateForFetchVisitsData, setStartDateForFetchVisitsData ] = React.useState(null);
  const [ endDateForFetchVisitsData, setEndDateForFetchVisitsData ] = React.useState(null);
  const [ selectedFeatureFlag, setSelectedFeatureFlag ] = React.useState<{ key: string; value: boolean }>({
    key: '',
    value: false,
  });
  const [ featureFlags, setFeatureFlags ] = React.useState<{ [flag: string]: boolean }>(
    Object.values(FeatureFlags).reduce((obj, flag) => ({
      ...obj,
      [flag]: false,
    }), {})
  );
  const [ userCompanies, setUserCompanies ] = React.useState<{
    counts: { [key: string]: number };
    data: any[];
  }>({
    counts: {},
    data: [],
  });
  const [ openConfirmationModal, setOpenConfirmationModal ] = useState<
  ConfirmationActionType
  >(ConfirmationActionType.none);

  const getAllFeatureFlagValues = async () => {
    const accessToken = await getAccessToken();
    const object: { [flag: string]: boolean } = {};
    for (const flag of Object.values(FeatureFlags)) {
      object[flag] = await featureFlag.isFeatureEnabled(
        flag, state.companyId, accessToken
      );
    }

    setFeatureFlags(object);
  };

  const getAllUserCompanies = async () => {
    const accessToken = await getAccessToken();
    const { data } = await axiosGet(
      `${USER_COMPANIES_URL}?isDebugMode=true`,
      state.companyId,
      accessToken,
    );
    setUserCompanies({
      counts: data.counts ?? {},
      data: data.data ?? [],
    });
  };

  const getConfirmationMessage = () => {
    let message = '';
    switch (openConfirmationModal) {
      case ConfirmationActionType.clearCache:
        message =
          'Are you sure you want to clear the cache for this region?';
        break;
      case ConfirmationActionType.recalculateConsumedQuantity:
        message =
          `Are you sure you want to recalculate the consumed quantity for ${state.companyName}?`;
        break;
      case ConfirmationActionType.runBatchJob:
        message = `Are you sure you want to run batch job ${jobNumber}?`;
        message += getJobMessage(jobNumber, {
          startDate: startDateForFetchVisitsData,
          endDate: endDateForFetchVisitsData,
        });
        break;
      case ConfirmationActionType.toggleFeatureFlag:
        message = `Are you sure you want to toggle the feature flag ${selectedFeatureFlag.key} to ${!selectedFeatureFlag.value}?`;
        break;
      default: break;
    }
    return message;
  };

  useEffect(() => {
    getAllFeatureFlagValues();
  }, []);

  useEffect(() => {
    const t = setTimeout(() => {
      getAllUserCompanies();
    }, 2000);
    return () => clearTimeout(t);
  }, [ state ]);

  const stateVariables = Object.entries(state)
    .filter(([ key, _value ]) => !keysToAvoid.includes(key))
    .reduce((acc, [ key, value ]) => ({
      ...acc,
      [key]: value,
    }), {});

  const handleRunBatchJob = async () => {
    try {
      const accessToken = await getAccessToken();
      const body = {
        startDate: startDateForFetchVisitsData,
        endDate: endDateForFetchVisitsData,
      };
      const result: any = await axiosPost(
        `${BATCH_JOB_URL}/${jobNumber}`,
        state.companyId,
        accessToken,
        body
      );
      if (result.status === 202) {
        showBanner(`Job ${jobNumber} ran successfully`, 'success');
      } else {
        throw new Error(result.data.message);
      }
    } catch (e) {
      showBanner(`Error: ${JSON.stringify(e.message)}`, 'error');
    }
  };

  const handleClearCache = async () => {
    try {
      const accessToken = await getAccessToken();
      const result: any = await axiosPost(
        `${CLEAR_CACHE_URL}`,
        state.companyId,
        accessToken
      );
      if (result.status === 200) {
        showBanner(`Cleared all the redis cache!`, 'success');
      } else {
        throw new Error(result);
      }
    } catch (e) {
      showBanner(`Error: ${JSON.stringify(e)}`, 'error');
    }
  };

  const handleRecalculateConsumedQuantity = async () => {
    try {
      const accessToken = await getAccessToken();
      const result: any = await axiosPost(
        `${RECALCULATE_CONSUMED_QUANTITY_URL}/${state.companyId}`,
        state.companyId,
        accessToken
      );
      if (result.status === 200) {
        showBanner(`Success: ${result.data}`, 'success');
      } else {
        throw new Error(result);
      }
    } catch (e) {
      showBanner(`Error: ${JSON.stringify(e)}`, 'error');
    }
  };

  const toggleFeatureFlag = async () => {
    if (!selectedFeatureFlag.key) {
      return;
    }
    try {
      const accessToken = await getAccessToken();
      const result: any = await featureFlag.toggleFeatureFlag(
        selectedFeatureFlag.key, state.companyId, accessToken
      );
      setFeatureFlags({
        ...featureFlags,
        [selectedFeatureFlag.key]: result,
      });
    } catch (e) {
      showBanner(`Error: ${e.message}`, 'error');
    }
  };

  const showBanner = (message: string, payload: 'error' | 'success') => {
    dispatch({
      type: 'setBannerType',
      payload,
    });
    dispatch({
      type: 'setBannerMsg',
      payload: message,
    });
  };

  const showFeatureFlags = () =>
    Object.entries(featureFlags).map(([ key, value ]) => (
      <div key={key}>
        {key}
        {isDebugPageManagementAllowed && (
          <Switch
            checked={value}
            onClick={async () => {
              setSelectedFeatureFlag({
                key,
                value,
              });
              setOpenConfirmationModal(ConfirmationActionType.toggleFeatureFlag);
            }}
          />
        )}
      </div>
    ));

  const handleConfirmModal = async () => {
    switch (openConfirmationModal) {
      case ConfirmationActionType.clearCache:
        await handleClearCache();
        break;
      case ConfirmationActionType.recalculateConsumedQuantity:
        await handleRecalculateConsumedQuantity();
        break;
      case ConfirmationActionType.runBatchJob:
        await handleRunBatchJob();
        break;
      case ConfirmationActionType.toggleFeatureFlag:
        await toggleFeatureFlag();
        break;
      default: break;
    }
    setOpenConfirmationModal(ConfirmationActionType.none);
  };

  const handleCloseConfirmationModal = () => {
    setOpenConfirmationModal(ConfirmationActionType.none);
  };

  const batchDOMByJob: any = {
    24: (
      <div>
        <TextLabel label="Start Date" />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            view='day'
            views={[ 'day' ]}
            openTo='day'
            slots={{
              openPickerButton: (props: any) => (
                <IconButton
                  {...props}
                  data-testid='StartDatePicker' />
              ),
              openPickerIcon: () => (
                <Icon className='material-icons-outlined'>
                      calendar_today
                </Icon>
              ),
            }}
            slotProps={{
              textField: {
                id: 'date-picker-inline',
                className: 'Date-Picker',
                required: true,
                InputProps: { className: 'Tall' },
              },
              field: {
                className: 'Date-Picker',
                readOnly: true,
              },
              toolbar: { hidden: true },

            }}
            format="MM/dd/yyyy"
            className="Date-Picker"
            maxDate={new Date()}
            value={startDateForFetchVisitsData}
            onChange={(date: any) => {
              setStartDateForFetchVisitsData(date);
            }}
          />
        </LocalizationProvider>

        <TextLabel label="End Date" />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            view='day'
            views={[ 'day' ]}
            openTo='day'
            slots={{
              openPickerButton: (props: any) => (
                <IconButton
                  {...props}
                  data-testid='StartDatePicker' />
              ),
              openPickerIcon: () => (
                <Icon className='material-icons-outlined'>
                      calendar_today
                </Icon>
              ),
            }}
            slotProps={{
              textField: {
                id: 'date-picker-inline',
                className: 'Date-Picker',
                required: true,
                InputProps: { className: 'Tall' },
              },
              field: {
                className: 'Date-Picker',
                readOnly: true,
              },
              toolbar: { hidden: true },

            }}
            format="MM/dd/yyyy"
            className="Date-Picker"
            maxDate={new Date()}
            value={endDateForFetchVisitsData}
            onChange={(date: any) => {
              setEndDateForFetchVisitsData(date);
            }}
          />
        </LocalizationProvider>
      </div>
    ),
  };

  return (
    <>
      <Helmet>
        <title>Debug Page</title>
      </Helmet>
      <ConfirmationModal
        modalHeading="Warning"
        primaryButtonText="Confirm"
        secondaryButtontext="Cancel"
        message={getConfirmationMessage()}
        open={openConfirmationModal !== ConfirmationActionType.none}
        handleConfirm={handleConfirmModal}
        handleClose={handleCloseConfirmationModal}
      />
      <DebugPageView>
        <h1>Debug Page</h1>
        <Row>
          <Column>
            <h4>
              Frontend Deployment Build ID:
              {` ${getStringEnv(process.env.REACT_APP_BUILD_ID)}`}
            </h4>
            <h4>
              Frontend Version:
              {` ${require('../../../../package.json').version}`}
            </h4>
            <h3>State Variables</h3>
            <JSONView>{JSON.stringify(stateVariables, null, 2)}</JSONView>
            <h3>Feature Flags (FE)</h3>
            <JSONView>{showFeatureFlags()}</JSONView>
            <h3>User Companies</h3>
            <JSONView>{JSON.stringify(userCompanies, null, 2)}</JSONView>
          </Column>
          {isDebugPageManagementAllowed && (
            <Column>
              <ActionButton
                text="Clear Redis Cache"
                onClick={() => setOpenConfirmationModal(ConfirmationActionType.clearCache)}
              />
              <ActionButton
                text="Recalculate Consumed Quantity"
                disabled={state.companyId === ''}
                isValid={state.companyId !== ''}
                onClick={() => setOpenConfirmationModal(ConfirmationActionType.recalculateConsumedQuantity)}
              />
              <Row>
                <ActionButton
                  text="Run Batch Job :"
                  disabled={isBatchJobAllowed(jobNumber, {
                    startDate: startDateForFetchVisitsData,
                    endDate: endDateForFetchVisitsData,
                  }).jobDisabled}
                  isValid={isBatchJobAllowed(jobNumber, {
                    startDate: startDateForFetchVisitsData,
                    endDate: endDateForFetchVisitsData,
                  }).jobValid}
                  onClick={() => setOpenConfirmationModal(ConfirmationActionType.runBatchJob)}
                />
                <JobSelect
                  value={jobNumber}
                  label="Job Number"
                  onChange={(event: any) => {
                    setJobNumber(event.target.value as number);
                  }}
                >
                  {Object.entries(AllowedJobsToRun).map(([ key, value ]) => (
                    <MenuItem
                      value={Number(key)}
                      key={key}
                    >
                      {`${key}: ${value}`}
                    </MenuItem>
                  ))}
                </JobSelect>
              </Row>
              {batchDOMByJob[jobNumber]}
            </Column>
          )}
        </Row>
      </DebugPageView>
    </>
  );
};
