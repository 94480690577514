import styled from 'styled-components';

import { SubCategoryText } from '../../Knowledge/Document';

export const DocumentAdminWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DocInfoWrapper = styled.div`
  flex-basis: 100%;

  ${props => `
    ${props.theme.breakpoints.up('md')} {
      display: flex;
      justify-content: space-between;
    }
  `};

  .SupportKBDocument__KnowledgeBaseTitle {
    word-break: break-word;
    color: ${p => p.theme.palette.semantic.colorPrimary};
  }
`;

export const DocumentStats = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DocumentInfo = styled.div`
  ${props => props.theme.breakpoints.up('md')} {
    width: 65.53%;
    margin-right: ${props => props.theme.spacing(3)}px;
  }
`;

export const StatText = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.35;
  text-transform: uppercase;
  color: ${props => props.theme.palette.grey[500]};
  margin-bottom: ${props => props.theme.spacing(1)}px;
  margin-right: 24px;
`;

export const KBTitle = styled.a`
  // TODO: Replace when in theme
  font-size: 2rem;
  line-height: 1.2;
  font-weight: 700;
  color: ${props => props.theme.palette.semantic.colorPrimary};
  margin-bottom: ${props => props.theme.spacing(2)}px;
  display: block;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const DocInfoContainer = styled.div`
  flex-basis: 62.92%;
  max-width: 62.92%;
  flex-grow: 0;
`;

export const DocExtension = styled.p`
  /* TODO: Replace when in design system */
  color: ${p => p.theme.palette.text.primary};
`;

export const DocFileSize = styled.p`
  font-size: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  /* TODO: Replace when in design system */
  color: ${p => p.theme.palette.text.primary};
`;

export const ProductDocumentInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  .ProductDocument__Meta-Info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  ${SubCategoryText} {
    text-transform: inherit;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.palette.text.secondary};
  }
  ${KBTitle} {
    margin-bottom: ${props => props.theme.spacing(4)}px;
    max-width: 661px;
  }
`;
