export const JIRA_HOST_NAME = 'uipath.atlassian.net';
export const JIRA_SRE_SERVICE_USER_USERNAME = 'jira.sre.serviceuser@gmail.com';

export enum JiraStatus {
  OPEN = 'Open',
  IN_PROGRESS = 'In Progress',
  CLOSED = 'Closed',
  FIXED = 'Fixed',
  WONT_FIX = 'Won\'t Fix',
  BY_DESIGN = 'By Design',
}

export const JiraStatusLocalization: any = {
  [JiraStatus.OPEN]: {
    textKey: 'known_issues_status_open',
    fallbackText: 'Open',
  },
  [JiraStatus.IN_PROGRESS]: {
    textKey: 'known_issues_status_in_progress',
    fallbackText: 'In Progress',
  },
  [JiraStatus.CLOSED]: {
    textKey: 'known_issues_status_closed',
    fallbackText: 'Closed',
  },
  [JiraStatus.FIXED]: {
    textKey: 'known_issues_status_fixed',
    fallbackText: 'Fixed',
  },
  [JiraStatus.WONT_FIX]: {
    textKey: 'known_issues_status_wont_fix',
    fallbackText: 'Won\'t Fix',
  },
  [JiraStatus.BY_DESIGN]: {
    textKey: 'known_issues_status_by_design',
    fallbackText: 'By Design',
  },
};

export const JIRA_RESOLVED_STATUS = [ 'Fixed', 'Won\'t Fix', 'By Design' ];

export enum JiraAction {
  ADD_OR_UPDATE = 'Add or Update Jira Known Issue',
  REMOVE = 'Remove Jira Known Issue',
}

export const JIRA_MAX_RESULTS = 50;
