import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import {
  Link,
  Redirect,
} from 'react-router-dom';

// Styles
import * as styled from '../../assets/css/Admin/HAPOManagement';
import { CPTableContainer } from '../../assets/css/Table';
// Translations
import { axiosGet } from '../../client/axios';
import AddNewHapoAccountModal from '../../components/admin/hapoEntitlements/Hapo-AddNewHapoAccountModal';
import ApolloIcon from '../../components/ApolloIcon';
import BasicHero from '../../components/BasicHero/CustomerPortal-BasicHero';
// Components
import Button from '../../components/Button/Button';
import Container from '../../components/CustomerPortal-New-Container';
import { DEAL_TYPE_HAPO } from '../../constants/hapo.constants';
import { BACKEND_HOST_NAME } from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
import { StoreContext } from '../../store';
import Loader from './../../components/CustomerPortal-Loader';
import Pagination from './../../components/CustomerPortal-Pagination';

const CustomerPortalPageHAPOManagement = () => {
  // Translate method
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();

  type hapoAccountType = {
    companyId: string;
    companyName: string;
    hapoSyncEnabled: boolean;
    dealType: string;
    expirationDate: string;
    lastUpdatedDate: string;
  };

  // Constants
  const [ hapoAccounts, setHapoAccounts ] = useState<hapoAccountType[]>([]);
  const [ hapoAccountsListLength, setHapoAccountsListLength ] = useState(0);
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ loading, setLoading ] = useState(true);
  const [ addingNewAccount, setAddingNewAccount ] = useState(false);
  const [ selectedCompanyId, setSelectedCompanyId ] = useState('');

  // Search
  const [ searchInputText, setSearchInputText ] = useState('');
  const [ searchInput, setSearchInput ] = useState(false);
  const [ searchTextChanged, setSearchTextChanged ] = useState(false);
  const limit = 5;
  const searchInputActiveClass: string =
    ' HapoClientsSelection__clientsSearchInput--active';
  const closeSearchInputClass: string =
    ' HapoClientsSelection__clientsSearchIcon--close';

  // Hero
  const heroTitle = 'Manage License Dashboards';
  const tableTitle = 'HAPO / ELA / UTO Configurations';

  useEffect(() => {
    fetchHapoCustomers();
  }, [ pageNumber ]);

  // called when searching for company name
  useEffect(() => {
    if (searchTextChanged) {
      setSearchTextChanged(false);
      fetchHapoCustomers();
    }
  }, [ searchTextChanged ]);

  const handlePaginateClick = (page: number) => {
    setPageNumber(page);
  };

  const showError = (errorMessage: string) => {
    dispatch({
      type: 'setBannerType',
      payload: 'error',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: errorMessage,
    });
  };

  const fetchHapoCustomers = async () => {
    // loading
    setLoading(true);

    // fetch for new page
    // Fetch CompanyData

    try {
      const hapoAccountsRes: any = await axiosGet(
        `${BACKEND_HOST_NAME}/api/v1/hapo/companies`,
        state.companyId,
        await getAccessToken(),
        {
          params: {
            pageNum: pageNumber,
            limit,
            keyword: searchInputText,
          },
        }
      );
      if (hapoAccountsRes.status === 200) {
        const newHapoAccounts = hapoAccountsRes.data.hapoAccounts.map(
          (account: any) => ({
            companyId: account.id,
            companyName: account.name,
            hapoSyncEnabled: account.hapo_sync_enabled ?? false,
            dealType: account.hapo?.deal_type ?? DEAL_TYPE_HAPO,
            expirationDate: moment(
              account.hapo?.summed_config?.effective_start_date
            ).format('L'),
            lastUpdatedDate: moment(account.hapo?.last_updated_on).format(
              'L'
            ),
          })
        );
        setHapoAccounts(newHapoAccounts);
        if (pageNumber === 1) {
          setHapoAccountsListLength(hapoAccountsRes.data.totalAccounts);
        }
      }
    } catch (e) {
      showError(`Something went wrong ${e}`);
    } finally {
      // loading false
      setLoading(false);
    }
  };

  const getConfigTable = () => (
    <CPTableContainer
      className="Table__Normal"
      data-testid="Hapo_Accounts_Table">
      <Table
        size="medium"
        aria-label="Config Table">
        <TableHead>
          <TableRow>
            <TableCell>Company ID</TableCell>
            <TableCell>Company Name</TableCell>
            <TableCell>Deal Type</TableCell>
            <TableCell>Expiration</TableCell>
            <TableCell>Last Updated On</TableCell>
            <TableCell>SFDC Sync Status</TableCell>
            <TableCell>Entitlement</TableCell>
          </TableRow>
        </TableHead>
        {hapoAccounts.length > 0 && (
          <TableBody data-testid="Companies_TableBody">
            {hapoAccounts.map((account: hapoAccountType) => (
              <TableRow
                key={account.companyId}
                data-testid={account.companyId}
              >
                <TableCell>{account.companyId}</TableCell>
                <TableCell>{account.companyName}</TableCell>
                <TableCell>
                  {account.dealType.toUpperCase()}
                </TableCell>
                <TableCell>{account.expirationDate}</TableCell>
                <TableCell>{account.lastUpdatedDate}</TableCell>
                <TableCell>
                  {account.hapoSyncEnabled ? 'Enabled' : 'Disabled'}
                </TableCell>
                <TableCell>
                  <Link
                    to={{
                      pathname: '/admin/hapo/entitlement',
                      search: `?company=${account.companyId}`,
                    }}
                  >
                    Edit Entitlement
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {hapoAccounts.length == 0 && (
        <p
          className="No-Licenses-Text"
          data-testid="EmptyState">
          No Account found!!
        </p>
      )}
      {hapoAccounts.length > 0 && (
        <Pagination
          activePage={pageNumber}
          handleClick={handlePaginateClick}
          numResults={hapoAccountsListLength}
          numPagesBeforeEllipses={5}
          numResultsPerPage={limit}
        />
      )}
    </CPTableContainer>
  );

  const [ debouncedCallApi ] = useState(() =>
    _.debounce(() => {
      setSearchTextChanged(true);
    }, 400)
  );

  const handleSearch = (e: any) => {
    e.preventDefault();
    if (!searchInput) {
      setSearchInput(true);
    } else {
      searchInputText == '' ? setSearchInput(false) : handleSearchInput('');
    }
  };

  const handleSearchInput = (text: string) => {
    if (text === '') {
      setSearchInput(false);
    }
    setSearchInputText(text);
    setPageNumber(1);
    debouncedCallApi();
  };

  const handleAddNewAccountModalClose = () => {
    setAddingNewAccount(false);
  };

  const handleEnablementForAccount = (companyId: string) => {
    handleAddNewAccountModalClose();
    setSelectedCompanyId(companyId);
  };

  if (selectedCompanyId) {
    return (
      <Redirect
        push
        to={`hapo/entitlement?company=${selectedCompanyId}`} />
    );
  }

  return (
    <>
      <Helmet>
        <title>Manage HAPO/ELA/UTO | Customer Portal</title>
      </Helmet>

      <styled.HapoManagementWrapper data-testid='CustomerPortalPageHAPOManagement'>
        <BasicHero title={heroTitle} />

        <Container>
          <div className="HapoManagement__Container">
            <div className="Header__Wrapper">
              <h3 className="marginBottomh3">{tableTitle}</h3>
              <div className="HapoClientsSelection__clientsActions">
                <div className="HapoClientsSelection__clientsSearch">
                  <div
                    className={`HapoClientsSelection__clientsSearchInput${
                      searchInput ? searchInputActiveClass : ''
                    }`}
                  >
                    {searchInput && (
                      <input
                        autoFocus
                        placeholder="Search for Company..."
                        type="text"
                        value={searchInputText}
                        onChange={(e: any) => {
                          handleSearchInput(e.target.value);
                        }}
                        data-testid="Search_Input"
                      />
                    )}
                  </div>
                  <div
                    className={`HapoClientsSelection__clientsSearchIcon${
                      searchInput ? closeSearchInputClass : ''
                    }`}
                    onClick={handleSearch}
                    data-testid='ClientsSearchIcon'
                  >
                    <ApolloIcon
                      icon={searchInput ? 'close' : 'search'}
                      fontSize="small"
                      className="icon"
                    />
                  </div>
                </div>
                <Button
                  className="EnableNewAccounts__Button"
                  text="Enable new Account"
                  onClick={(e: any) => setAddingNewAccount(true)}
                />
              </div>
            </div>
            {loading && (
              <div className="HapoManagement__Loader">
                <Loader />
              </div>
            )}
            {!loading && getConfigTable()}

            {addingNewAccount && (
              <AddNewHapoAccountModal
                handleEnablementForAccount={handleEnablementForAccount}
                handleClose={handleAddNewAccountModalClose}
              />
            )}
          </div>
        </Container>
      </styled.HapoManagementWrapper>
    </>
  );
};

export default CustomerPortalPageHAPOManagement;
