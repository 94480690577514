import styled from 'styled-components';

export default styled.h2`
  font-size: 2.4rem; // TODO: Replace when theme
  line-height: 1.2;
  color: ${p => p.theme.palette.text.primary}; // TODO: Replace when in Design System
  font-weight: normal;
  ${props => `
    margin-bottom: ${props.theme.spacing(4)}px;
  `};
`;
