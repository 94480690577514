import styled from 'styled-components';

export const SelectAccountModalWrapper = styled.section`
  .MuiButton-outlined {
    color: ${p => p.theme.palette.getContrastText(p.theme.palette.semantic.colorPrimary)};
  }
  .No-Licenses-Text {
    color: ${p => p.theme.palette.semantic.colorPrimary};
    text-align: center;
    background: ${p => p.theme.palette.grey[100]};
    border-radius: ${p => p.theme.spacing(1)}px;
    padding: ${p => p.theme.spacing(2)}px;
  }

  .Table-Row {
    cursor: pointer;
    &:hover {
        background-color: ${p => p.theme.palette.semantic.colorPrimaryDarker};
      }
  }
`;
