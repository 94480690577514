import type { EntityDTO } from '@customer-portal/interfaces';
import { Tooltip } from '@mui/material';
import { Parser } from 'json2csv';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Styles
import * as styles from '../../../assets/css/Company/Page';
import {
  LicenseInfoQuantityFontSize,
  LicenseInfoQuantityWidth,
} from '../../../assets/css/Company/Page';
// Images
import DownArrow from '../../../assets/img/svg/arrows/Arrow_Down_24.svg';
import ErrorIcon from '../../../assets/img/svg/status_icons/error.svg';
// Client
import {
  axiosGet,
  axiosPost,
} from '../../../client/axios';
import { fetchCompanyLicenses } from '../../../client/licenses';
import Pagination from '../../../components/CustomerPortal-Pagination';
import type { CommercialName } from '../../../constants/license.constants';
import {
  licenseBundlesMapping,
  licenseBundlesShowProducts,
  productsMapping,
  UNLIMITED_PRODUCT_QUANTITY,
} from '../../../constants/license.constants';
// Constants
import {
  BUNDLE_PRODUCTS_URL,
  ENTITIES_URL,
} from '../../../constants/network.constants';
import RFQ from '../../../constants/requestForQuote.constants';
import { COMPANY_LICENSES } from '../../../constants/telemetry.constants';
import { useAuth } from '../../../contexts/auth';
// Data
// Interfaces
import type { IDataObject } from '../../../interfaces/dataObject.interface';
import { useTrackPageViewEvent } from '../../../lib/AppInsights/AppInsights';
// Utils
import {
  getBundleName,
  getSubscriptionName,
} from '../../../lib/licenses.utils';
import { isHAPOELAUTOBundleBySubscriptionCode } from '../../../lib/requestForQuote.utils';
import { UserPermissionsHelper } from '../../../lib/UserPermissions';
import { StoreContext } from '../../../store';
import { calculateAdjustedFontSize } from '../../../utils/cp.utils';
import { featureFlag } from '../../../utils/featureFlag';
import ApolloIcon from '../../ApolloIcon';
import Button from '../../Button/Button';
import Loader from '../../CustomerPortal-Loader';
import SelectWithSearch from '../../Select-With-Search';
// Components
import AddCustomAttributeModal from '../Company-Add-Custom-Attribute';
import CompanyBodyUnauthorized from './Company-Body-Unauthorized';

interface IEditingState {
  name: string;
  displayName: string;
  note: string;
  licenseId: string;
  position: number;
  bundleCode: string;
  subscriptionCode: string;
  selectedEntity?: { _id: string; name: string };
}

export enum ModalVariant {
  CUSTOM_NAME = 'CUSTOM_NAME',
  NOTES = 'NOTES',
  UPDATE_ENTITY = 'UPDATE_ENTITY',
}

const columnTitles = [
  {
    id: 'details',
    keyText: 'company_licenses_th_license_details',
    fallbackText: 'License Details',
  },
  {
    id: 'subscription',
    keyText: 'company_licenses_th_subscription',
    fallbackText: 'Subscription',
  },
  {
    id: 'entity-name',
    keyText: 'company_licenses_th_entity_name',
    fallbackText: 'Entity Name',
  },
  {
    id: 'start-date',
    keyText: 'company_licenses_th_start_date',
    fallbackText: 'Start Date',
  },
  {
    id: 'end-date',
    keyText: 'company_licenses_th_end_date',
    fallbackText: 'End Date',
  },
  {
    id: 'opener',
    keyText: '',
    fallbackText: '',
  },
];

const CompanyBodyLicenses = () => {
  // Translate method
  const { t } = useTranslation('common');
  const baseClass = 'MyCompanyBody';
  // Global state
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const routerHistory = useHistory();
  const { getAccessToken } = useAuth();
  // Local state
  const [ notesOpen, setNotesOpen ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState(null);
  const [ bundleData, setBundleData ] = useState<any>([]);
  const [ customNameOpen, setCustomNameOpen ] = useState(false);
  const [ updateEntityOpen, setUpdateEntityOpen ] = useState(false);
  const [ modalVariant, setModalVariant ] = useState<ModalVariant | undefined>(undefined);
  const [ licenseData, setLicenseData ] = useState<{ licenses: any[]; boonLicense: any }>({
    licenses: [],
    boonLicense: {},
  });
  const [ allEntities, setAllEntities ] = useState<Array<{ _id: string; name: string }>>([]);
  const [ isEntityManagementEnabled, setIsEntityManagementEnabled ] = useState<boolean>(false);
  const [ entityFilters, setEntityFilters ] = useState<Array<{ label: string; value: string }>>([]);
  const [ selectedEntityFilter, setSelectedEntityFilter ] = useState<{ label: string; value: string } | undefined>(undefined);
  const [ editing, setEditing ] = useState<IEditingState>({
    name: '',
    displayName: '',
    note: '',
    licenseId: '',
    position: 0,
    bundleCode: '',
    subscriptionCode: '',
    selectedEntity: undefined,
  });
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ loading, setLoading ] = useState(true);

  const scrollTo = useRef<any>(null);

  // Pagination settings
  let totalResults = 0;
  const limit = 25;
  const start = (pageNumber - 1) * limit;
  const end = pageNumber * limit;

  useTrackPageViewEvent(COMPANY_LICENSES);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      setIsEntityManagementEnabled(
        await featureFlag.isEntityManagementEnabled(
          state.companyId,
          await getAccessToken()
        )
      );
    };

    fetchFeatureFlags();
  }, []);

  useEffect(() => {

    const getCompanyLicenses = async (companyId: string, token: string) => {
      try {
        setLoading(true);
        await fetchCompanyLicenses(companyId, token, state.userId, dispatch);
      } catch (e) {
        console.log(`Unable to retrieve company licenses: ${e.message}`);
      }
    };

    const updateCompanyData = async () => {
      await getCompanyLicenses(state.companyId, await getAccessToken());
    };

    // fetch all entities
    fetchAllEntities();

    if (state.companyLicenses === null || Object.keys(state.companyLicenses).length === 0) {
      updateCompanyData();
    } else {
      setLoading(false);
      setLicenseData(state.companyLicenses);
    }
  }, [ state.companyLicenses ]);

  useEffect(() => {
    let variant: ModalVariant | undefined = undefined;

    if (customNameOpen) {
      variant = ModalVariant.CUSTOM_NAME;
    }

    if (notesOpen) {
      variant = ModalVariant.NOTES;
    }

    if (updateEntityOpen) {
      variant = ModalVariant.UPDATE_ENTITY;
    }

    setModalVariant(variant);
  }, [ customNameOpen, notesOpen, updateEntityOpen ]);

  useEffect(() => {
    const entityIdToEntity: Record<string, any> = {};

    const boonLicenses = licenseData.boonLicense?.licenses ?? [];
    boonLicenses.forEach((license: any) => {
      const entity = licenseData.licenses
        .find((licenseObj) => licenseObj.boon_license_cd === license.licenseCode)
        ?.entity;
      if (!entity) {
        return;
      }
      entityIdToEntity[entity._id] = {
        name: entity.name,
        id: entity._id,
      };
    });

    setEntityFilters([
      {
        label: t('support_form_dropdown_item_none', '--- None ---'),
        value: 'None',
      },
      ...Object.keys(entityIdToEntity).map((entityId) => ({
        label: entityIdToEntity[entityId].name,
        value: entityIdToEntity[entityId].id,
      })),
    ]);
  }, [ licenseData ]);

  // Permission checking
  const isRFQEnabled = UserPermissionsHelper.isRFQEnabled();
  const isEditLicensesAllowed = UserPermissionsHelper.isEditLicenseAllowed();
  const isViewLicensesAllowed = UserPermissionsHelper.isViewLicenseAllowed();

  // Methods
  const numDaysBetween = (d1: any, d2: any) => {
    const diff = Math.abs(d1.getTime() - d2.getTime());
    return diff / (1000 * 60 * 60 * 24);
  };

  const checkForTopLineOfCSV = (csv: any) => {
    for (let i = 0; i < csv.length; i++) {
      if (csv[i].includes('Comments')) {
        csv[i] = csv[i].replace('"Comments"', '');
        return i;
      }
    }
    return -1;
  };

  const redirectToRFQ = (
    category: typeof RFQ.productTypes[keyof typeof RFQ.productTypes],
    onPremLicenseCode: string | undefined,
    onPremLicenseName: string | undefined,
    bundleDisplayName: string | undefined
  ) => {
    routerHistory.push('/request-quote', {
      existing_license_code: onPremLicenseCode,
      existing_license_name: onPremLicenseName,
      existing_license_displayName: bundleDisplayName,
      category,
    });
  };

  const handlePaginateClick = (page: number) => {
    const currentScrollOffset =
      window.pageYOffset || document.documentElement.scrollTop;
    // dont scroll if already on top
    if (currentScrollOffset === 0) {
      setPageNumber(page);
    } else {
      scrollTo.current.scrollIntoView({ behavior: 'smooth' });
      setPageNumber(page);
    }
  };

  const handleToggle = (e: any) => {
    const position = e.target.dataset.position;
    const licenseCode = e.target.dataset.license;
    const newBundleData = [ ...bundleData ];
    newBundleData[position].isOpen = !newBundleData[position].isOpen;

    if (newBundleData[position].isOpen) {
      // check to make sure the call has not already been fired for this bundle.
      if (bundleData[position].products === null) {
        newBundleData[position].isLoading = true;
        fetchBundleData(licenseCode, position);
      }
    }

    setBundleData(newBundleData);
  };

  const handleKebabClose = () => {
    setAnchorEl(null);
  };

  const handleCustomNameOpen = () => {
    setCustomNameOpen(true);
  };

  const handleNotesOpen = () => {
    setNotesOpen(true);
  };

  const handleUpdateEntityOpen = () => {
    setUpdateEntityOpen(true);
  };

  const handleAddNewLicense = (
    name: string,
    license: string,
    bundleCode: string,
    displayName: string
  ) => {
    if (bundleCode === 'UIP') {
      // If on prem orchestrator
      redirectToRFQ(RFQ.productTypes.onPrem, license, name, displayName);
    } else {
      // Else, direct to cloud
      redirectToRFQ(RFQ.productTypes.cloud, undefined, undefined, undefined);
    }
  };

  const getProductName = (
    productCode: string,
    bundleCode: string,
    subscriptionName: keyof CommercialName['default']
  ) => {
    let productCommercialName = '-';

    if (productsMapping[productCode]) {
      const bundleType = bundleCode === 'CRPA' ? 'CRPA' : 'default';
      const subscriptionKey = /NonProduction|Production/.test(subscriptionName)
        ? subscriptionName
        : 'default';
      productCommercialName =
        productsMapping[productCode]?.commercialName[bundleType]?.[
          subscriptionKey
        ] ||
        productsMapping[productCode]?.commercialName[bundleType]?.['default'] ||
        productCommercialName;
    }

    return productCommercialName;
  };

  const handleAttributeParentClick = (event: any) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
    // Set data of editing license associated with the one that's been clicked.
    const license = bundleData[event.currentTarget.dataset.position];
    if (license) {
      const editingObj = {
        name: license.bundleName,
        displayName: license.displayName,
        note: license.note,
        licenseId: license.boonId,
        position: event.currentTarget.dataset.position,
        bundleCode: license.bundleCode,
        subscriptionCode: license.subscriptionCode,
        selectedEntity: license.entity,
      };
      setEditing(editingObj);
      if (event.target.dataset.parent === 'note') {
        setNotesOpen(true);
      }
    }
  };

  const handleCustomAttributesClose = (
    newAttribute?: string | { _id: string; name: string },
    isChanged?: boolean
  ) => {
    if (modalVariant === ModalVariant.NOTES) {
      setNotesOpen(false);
    } else if (modalVariant === ModalVariant.CUSTOM_NAME) {
      setCustomNameOpen(false);
    } else if (modalVariant === ModalVariant.UPDATE_ENTITY) {
      setUpdateEntityOpen(false);
    }

    if (isChanged) {
      const attribute = modalVariant === ModalVariant.CUSTOM_NAME ? 'displayName' :
        modalVariant === ModalVariant.NOTES ? 'note' :
          modalVariant === ModalVariant.UPDATE_ENTITY ? 'entity' :
            '';

      const newBundleData = [ ...bundleData ];
      newBundleData[editing.position][attribute] = newAttribute;
      setBundleData(newBundleData);

      if (modalVariant === ModalVariant.UPDATE_ENTITY) {
        setLicenseData({
          ...licenseData,
          licenses: licenseData.licenses.map((license) => {
            if (license.boon_license_cd === editing.licenseId) {
              const entity = typeof newAttribute !== 'string'
                ? allEntities.find((e) => e._id === newAttribute?._id)
                : undefined;
              return {
                ...license,
                entity,
              };
            }
            return license;
          }),
        });

        // Re-fetch licenses to update persisted state
        getAccessToken().then(token => {
          fetchCompanyLicenses(state.companyId, token, state.userId, dispatch);
        });
      }
    }
  };

  const getQuantity = (product: IDataObject) => {
    let quantity = 0;
    if (product.type === 'CONSUMPTION_INTERVAL') {
      // check if today's date is between start and end date i.e not expired quantities
      const today = new Date();
      product.consumptionDistributions?.forEach(
        (distribution: any) => {
          const startDate = new Date(distribution.startDate);
          const endDate = new Date(distribution.endDate);
          if (today >= startDate && today <= endDate) {
            quantity += distribution.quantity;
          }
        }
      );
    } else {
      quantity =
        product.quantity === UNLIMITED_PRODUCT_QUANTITY
          ? 'Unlimited'
          : product.quantity;
    }
    return quantity;
  };

  const handleDownloadCSVClick = async () => {
    // Filter out the expired bundles
    dispatch({
      type: 'setBannerType',
      payload: '',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: t(
        'company_licenses_export_excel_info_msg',
        'Generating file...please remain in Customer Portal until the download is complete.'
      ),
    });
    const sumOfCSVs = [ '\ufeff' ];
    for (let i = 0; i < bundleData.length; i++) {
      const licenseCode = bundleData[i].boonId;
      const displayName = bundleData[i].displayName;
      const bundleName = bundleData[i].bundleName.replace(
        /\s*<[^>]*>?\s*/gm,
        ' '
      );
      const ownerEmail = bundleData[i].ownerEmail;
      const subscriptionName = bundleData[i].subscriptionName;
      const entityName = bundleData[i].entity?.name ?? '';
      const startDate = bundleData[i].startDate;
      const endDate = bundleData[i].endDate;
      const note = bundleData[i].note;
      const bundleCode = bundleData[i].bundleCode;
      const licenseExpirationDate: Date = new Date(endDate);
      const currentDate = new Date();
      const isExpired = currentDate > licenseExpirationDate;

      // Only download non expired bundles
      if (isExpired) {
        continue;
      }

      // Skip products that don't need to be shown, Get missing products
      const showProducts = licenseBundlesShowProducts.includes(
        bundleData[i].bundleCode
      );
      if (showProducts) {
        const bundleRes = await fetchBundlePromise(bundleData[i].boonId);
        if (bundleRes.status === 200 && bundleRes.data) {
          // Success
          if (
            bundleRes.data?.license?.bundle?.products
          ) {
            const newBundleData = [ ...bundleData ];
            newBundleData[i].products = bundleRes.data.license.bundle.products;
            setBundleData(newBundleData);
          }
        }
      }

      const json2csvParser = new Parser({
        // item is an individual product
        transforms: [
          item => {
            let quantity;
            if (item === null || item === undefined) {
              quantity = '0';
            } else {
              quantity = getQuantity(item);
            }
            return {
              ...item,
              licenseCode: `"${licenseCode}"`,
              displayName: `${displayName}`,
              bundleName: `${bundleName}`,
              ownerEmail: `${ownerEmail}`,
              subscriptionName: `${subscriptionName}`,
              ...(isEntityManagementEnabled && { entityName: `${entityName}` }),
              startDate: `${startDate}`,
              endDate: `${endDate}`,
              note: `${note}`,
              products: `${
                item === null || item === undefined
                  ? 'No Products'
                  : getProductName(item.code, bundleCode, subscriptionName)
              }`,
              quantity: `${quantity}`,
            };
          },
        ],
        fields: [
          {
            label: 'Custom Name',
            value: 'displayName',
          },
          {
            label: 'License Type',
            value: 'bundleName',
          },
          {
            label: 'License Code',
            value: 'licenseCode',
          },
          {
            label: 'Owner Email',
            value: 'ownerEmail',
          },
          {
            label: 'Subscription',
            value: 'subscriptionName',
          },
          ...(isEntityManagementEnabled ? [ {
            label: 'Entity Name',
            value: 'entityName',
          } ] : []),
          {
            label: 'Start Date',
            value: 'startDate',
          },
          {
            label: 'End Date',
            value: 'endDate',
          },
          {
            label: 'Associated Products',
            value: 'products',
          },
          {
            label: 'Quantity of Associated Product',
            value: 'quantity',
          },
          {
            label: 'Comments',
            value: 'note',
          },
        ],
      });

      const filteredProd =
        bundleData[i].products == null
          ? [ null ]
          : bundleData[i].products.filter((prod: any) => {
            if (
              productsMapping[prod.code] &&
                productsMapping[prod.code].display &&
                getQuantity(prod) > 0
            ) {
              return true;
            }
            return false;
          });

      let csv = json2csvParser.parse(filteredProd).split(',');
      if (sumOfCSVs.length > 1) {
        const indOfTitles = checkForTopLineOfCSV(csv);
        csv = csv.slice(indOfTitles);
      }
      sumOfCSVs.push(csv.join(','));
    }
    const element = document.createElement('a');
    const file = new Blob(sumOfCSVs, { type: 'text/csv' });
    element.href = URL.createObjectURL(file);
    element.download = `${state.companyName}_UiPath_Licenses.csv`;
    document.body.appendChild(element);
    element.click();
    dispatch({
      type: 'setBannerType',
      payload: 'success',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: t(
        'company_licenses_export_excel_success_msg',
        'Download complete!'
      ),
    });
  };

  const fetchBundleData = async (licenseCode: string, position: number) => {
    (async () => {
      try {
        const bundleRes: any = await fetchBundlePromise(licenseCode);
        if (bundleRes.status === 200 && bundleRes.data) {
          // Success
          if (
            bundleRes.data?.license?.bundle?.products
          ) {
            const newBundleData = [ ...bundleData ];
            newBundleData[position].products =
              bundleRes.data.license.bundle.products;
            setBundleData(newBundleData);
          }
        }
      } catch (e) {
        console.log(e.toString());
      }

      const newBundleData = [ ...bundleData ];
      newBundleData[position].isLoading = false;
      setBundleData(newBundleData);
    })();
  };

  // Load bundle data from Boon
  const fetchBundlePromise: any = async (licenseCode: any) => {
    const body = { id: licenseCode };
    return axiosPost(
      BUNDLE_PRODUCTS_URL,
      state.companyId,
      await getAccessToken(),
      body
    ).catch(error => error);
  };

  const fetchAllEntities = async () => {
    try {
      const entitiesResponse = await axiosGet<EntityDTO[]>(
        ENTITIES_URL,
        state.companyId,
        await getAccessToken()
      );
      setAllEntities(
        entitiesResponse.data.map((entity) => ({
          _id: entity._id,
          name: entity.name,
        }))
      );
    } catch (e) {
      console.log(`Unable to retrieve entities: ${e.message}`);
    }
  };

  const renderProducts = (i: number) => {
    let productsToShow = 0;
    const noBundles = (
      <p
        className={`${baseClass}__No-Licenses-Text`}
        data-testid="EmptyProductsState"
      >
        {t('company_licenses_no_bundle_results', 'No products for bundle.')}
      </p>
    );
    if (bundleData?.length && bundleData[i]) {
      if (bundleData[i].isOpen) {
        if (bundleData[i].isLoading) {
          return (
            <div className={`${baseClass}__Loader`}>
              <Loader />
            </div>
          );
        }
        if (
          bundleData[i].products !== null &&
            bundleData[i].products.length !== 0
        ) {
          const product = bundleData[i].products.map(
            (product: IDataObject, j: number) => {
              const productCode: string = product.code;
              const quantity = getQuantity(product);
              if (
                productsMapping[productCode] &&
                  productsMapping[productCode].display &&
                  quantity > 0
              ) {
                productsToShow++;
                const productStatus = '';
                const productName = getProductName(
                  productCode,
                  bundleData[i].bundleCode,
                  bundleData[i].subscriptionName
                );
                const fontSize = calculateAdjustedFontSize(
                  quantity,
                  LicenseInfoQuantityFontSize,
                  LicenseInfoQuantityWidth
                );
                return (
                  <div
                    className={`${baseClass}__License-Card-Info`}
                    key={j}
                    data-testid="LicenseCardInfo"
                  >
                    <div className={`${baseClass}__License-Card-Sub-License`}>
                      <div className="MyCompanyBody__License-Column--details">
                        <div
                          className={`${baseClass}__License-Info-Quantity auto-shrink`}
                        >
                          <p style={{ fontSize }}>{quantity}</p>
                        </div>
                        <div
                          className={`${baseClass}__License-Column--Details-Text`}
                        >
                          <h5
                            className={`${baseClass}__License-Card-Info-Name Regular ${productStatus}`}
                          >
                            {productName}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              return undefined;
            }
          );
          if (productsToShow > 0) {
            return product;
          }
          return noBundles;

        }
        return noBundles;

      }
    }
  };

  const generateLicenseBundles = () => {
    let bundlesToShow = 0;
    const noBundles = (
      <p
        className={`${baseClass}__No-Licenses-Text`}
        data-testid="EmptyState">
        {t(
          'company_licenses_no_results',
          `No licenses for ${state.companyName}`,
          { company_name: state.companyName }
        )}
      </p>
    );
    if (loading) {
      return (
        <div className={`${baseClass}__Loader`}>
          <Loader />
        </div>
      );
    }
    const boonLicenses = (licenseData.boonLicense.licenses ?? [])
      .filter((license: IDataObject) => {
        const entity = licenseData.licenses
          .find((licenseObj) => licenseObj.boon_license_cd === license.licenseCode)
          ?.entity;
        return !selectedEntityFilter || entity?._id === selectedEntityFilter.value;
      });
    if (boonLicenses?.length > 0) {
      const bundles: any = [];
      const bundle = boonLicenses.map((license: IDataObject, i: number) => {

        const currentDate: Date = new Date();
        const licenseExpirationDate: Date = new Date(license.endDate);
        const isExpired = currentDate > licenseExpirationDate;

        let bundleName = '-';
        const showProducts =
            licenseBundlesMapping[license.bundleCode] !== undefined;
        const subscriptionName = getSubscriptionName(license.subscriptionCode);
        bundleName = getBundleName(license.bundleCode, subscriptionName);

        const showOwner =
            license.bundleCode === 'CRPA' || license.bundleCode === 'CEH';

        const daysToExpiration = numDaysBetween(
          currentDate,
          licenseExpirationDate
        );
        const expiringSoon = daysToExpiration <= 90;

        const entity = licenseData.licenses
          .find((licenseObj) => licenseObj.boon_license_cd === license.licenseCode)
          ?.entity;

        const bundleShell = {
          isOpen: false,
          products: null,
          isLoading: false,
          displayName: '',
          note: '',
          boonId: license.licenseCode,
          bundleName,
          ownerEmail:
              showOwner && license.owner?.email
                ? license.owner.email
                : null,
          subscriptionName,
          startDate: license.startDate,
          endDate: license.endDate,
          bundleCode: license.bundleCode,
          subscriptionCode: license.subscriptionCode,
          entity,
        };

        if (bundleData.length < boonLicenses.length) {
          if (licenseData.licenses && licenseData.licenses.length > 0) {
            const extraProperties = licenseData.licenses;
            const hasExtraProperties = extraProperties.filter(
              (propertyObj: IDataObject) =>
                propertyObj.boon_license_cd === license.licenseCode
            );

            if (hasExtraProperties.length > 0) {
              const nickName = hasExtraProperties[0].display_name;
              const noteVal = hasExtraProperties[0].comment;
              bundleShell.displayName = nickName;
              bundleShell.note = noteVal;
            }
          }
          bundles.push(bundleShell);
        }

        bundlesToShow++;

        if (!isExpired) {
          const licenseDisplayName =
              bundleData?.length && bundleData[i]?.displayName
                ? bundleData[i].displayName
                : '';

          const note =
              bundleData?.length && bundleData[i]?.note
                ? bundleData[i].note
                : '';

          const entityName = entity?.name ?? '';
          return (
            <div
              className={`${baseClass}__License-Card-Outer ${baseClass}__License-Card-V2`}
              key={i}
              data-testid="LicenseCard"
            >
              <div className={`${baseClass}__License-Card`}>
                <div className={`${baseClass}__License-Column--opener ${baseClass}__License-Column--opener-Desktop`}>
                  {showProducts && (
                    <img
                      onClick={handleToggle}
                      id={license.licenseCode}
                      data-position={i}
                      data-license={license.licenseCode}
                      className={`${baseClass}__License-Column--Opener-Icon`}
                      src={DownArrow}
                      alt="Down Arrow"
                      data-testid="ExpandLicenseButton"
                    />
                  )}
                </div>
                <div className={`${baseClass}__License-Column--details`}>
                  <div className={`${baseClass}__License-Column--opener ${baseClass}__License-Column--opener-Mobile`}>
                    {showProducts && (
                      <img
                        onClick={handleToggle}
                        id={license.licenseCode}
                        data-position={i}
                        data-license={license.licenseCode}
                        className={`${baseClass}__License-Column--Opener-Icon`}
                        src={DownArrow}
                        alt="Down Arrow"
                      />
                    )}
                  </div>
                  <div className={`${baseClass}__License-Column--Details-Text`}>
                    <p className={`${baseClass}__License--Nickname Bold`}>
                      {licenseDisplayName}
                    </p>
                    <p
                      className={`${baseClass}__License--Boon-Name Regular`}
                      dangerouslySetInnerHTML={{ __html: bundleName }}
                    />
                    <p className={`${baseClass}__License-Column-Serial Bold`}>
                      {license.licenseCode}
                    </p>
                  </div>
                </div>
                <div className={`${baseClass}__License-Column--subscription`}>
                  <p className={`${baseClass}__License-Column-Mobile-Title`}>
                    {t('company_licenses_th_subscription', 'Subscription')}
                  </p>
                  <p className={`Small ${baseClass}__License-Column-Value`}>
                    {subscriptionName}
                  </p>
                </div>
                {isEntityManagementEnabled && (
                  <div className={`${baseClass}__License-Column--entity-name`}>
                    <p className={`${baseClass}__License-Column-Mobile-Title`}>
                      {t('company_licenses_th_entity_name', 'Entity Name')}
                    </p>
                    {entityName.length > 10 ? (
                      <Tooltip title={entityName}>
                        <p
                          className={`Small ${baseClass}__License-Column-Value`}
                          style={{ fontWeight: 500 }}
                        >
                          {`${entityName.substring(0, 10)}...`}
                        </p>
                      </Tooltip>
                    ) : (
                      <p
                        className={`Small ${baseClass}__License-Column-Value`}
                        style={{ fontWeight: 500 }}
                      >
                        {entityName}
                      </p>
                    )}

                  </div>
                )}
                <div className={`${baseClass}__License-Column--start-date`}>
                  <p className={`${baseClass}__License-Column-Mobile-Title`}>
                    {t('company_licenses_th_start_date', 'Start Date')}
                  </p>
                  <p className={`Small ${baseClass}__License-Column-Value`}>
                    {license.startDate}
                  </p>
                </div>
                <div className={`${baseClass}__License-Column--end-date`}>
                  <p className={`${baseClass}__License-Column-Mobile-Title`}>
                    {t('company_licenses_th_end_date', 'End Date')}
                  </p>
                  {expiringSoon ? (
                    <Tooltip
                      title="This license is expiring soon."
                      data-testid="ExpiringSoonToolTip"
                    >
                      <div className={`${baseClass}__License-Expiring-Soon`}>
                        <img
                          className={`${baseClass}__License-Expiring-Soon-Icon`}
                          src={ErrorIcon}
                          alt="Alert Icon"
                        />
                        <p
                          className={`Small ${baseClass}__License-Expiring-Soon-Text`}
                        >
                          {license.endDate}
                        </p>
                      </div>
                    </Tooltip>
                  ) : (
                    <p className={`Small ${baseClass}__License-Column-Value`}>
                      {license.endDate}
                    </p>
                  )}
                </div>
                <div className={`${baseClass}__License-Menu`}>
                  {isEditLicensesAllowed && (
                    <styles.MenuOpener
                      className={`${baseClass}__License-Menu-Opener`}
                      data-testid="MenuButton"
                      data-position={i}
                      onClick={handleAttributeParentClick}
                    >
                      <ApolloIcon
                        icon='more_vert'
                        fontSize='inherit'
                        className='icon'
                      />
                    </styles.MenuOpener>
                  )}
                </div>
                {note && (
                  <Tooltip
                    placement="bottom"
                    title={
                      t(
                        'view_notes',
                        'View Notes'
                      ) as string
                    }
                  >
                    <div
                      onClick={handleAttributeParentClick}
                      className={`${baseClass}__License-With-Note`}
                      data-parent="note"
                      data-position={i}
                      data-testid="view-notes"
                    />
                  </Tooltip>
                )}
              </div>
              {showProducts && renderProducts(i)}
            </div>
          );
        }
      });

      if (bundleData.length === 0 && bundlesToShow > 0) {
        setBundleData(bundles);
      }
      if (bundlesToShow > 0) {
        const bundlesToDisplay = bundle.filter((b: any) => b !== undefined);
        totalResults = bundlesToDisplay.length;
        // return sliced bundles array for pagination
        return bundlesToDisplay.slice(start, end);
      }
      return noBundles;

    }
    return noBundles;
  };

  const displayNameMessage =
    editing.displayName === ''
      ? t('company_licenses_context_menu_add_displayName', 'Add Display Name')
      : t(
        'company_licenses_context_menu_edit_displayName',
        'Edit Display Name'
      );

  return (
    <>
      {isViewLicensesAllowed ? (
        <div
          ref={scrollTo}
          className={`${baseClass}__Licenses`}
          data-testid="CompanyBodyLicenses"
        >
          <AddCustomAttributeModal
            onClose={handleCustomAttributesClose}
            open={customNameOpen || notesOpen || updateEntityOpen}
            displayName={editing.displayName}
            licenseId={editing.licenseId}
            note={editing.note}
            entities={allEntities}
            selectedEntity={editing.selectedEntity}
            modalVariant={modalVariant}
          />
          <h3
            className={`${baseClass}__Body-Heading Regular`}
            data-testid="Heading"
          >
            {t('company_licenses_title_text', `${state.companyName} Licenses`, { company_name: state.companyName })}
          </h3>
          <div className={`${baseClass}__Body-Subheading-Outer`}>
            <h5
              className={`${baseClass}__Body-Subheading Regular`}
              data-testid="SubHeading"
            >
              {t(
                'company_licenses_description',
                'Get in touch with one of your assigned UiPath contacts for new purchases, renewal, versioning or any other license related questions.'
              )}
            </h5>

            {isEntityManagementEnabled && (
              <SelectWithSearch
                options={entityFilters
                  .filter((entity, i) => i || !!selectedEntityFilter)
                  .map((entity) => ({
                    label: entity.label,
                    value: entity.value,
                  }))}
                value={selectedEntityFilter}
                className="Custom-Select--White CustomerPortalClientsSelection__clientsFilterEntity"
                onChange={(option: { label: string; value: string }) => setSelectedEntityFilter(option.value === 'None' ? undefined : option)}
                searchable
                placeholder={
                  <div data-testid="filter-by-entity">
                    {t('no_entity_selected', 'No entity selected')}
                  </div>
                }
              />
            )}

            {licenseData.boonLicense?.licenses?.length > 0 && (
              <Button
                onClick={handleDownloadCSVClick}
                text={t('company_licenses_download_csv_button', 'Download CSV')}
                className={`${baseClass}__Body-ButtonCSV`}
              />
            )}
          </div>

          <div className={`${baseClass}__List`}>
            {isEditLicensesAllowed && (
              <styles.OptionsPopover
                id="OptionsPopover"
                open={Boolean(anchorEl)}
                className="Select__Outer"
                anchorEl={anchorEl}
                onClose={handleKebabClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                disableRestoreFocus
                data-testid="LicenseCardMenu"
              >
                <styles.SelectOption
                  onClick={handleCustomNameOpen}
                  className="UserCard__Menu-Option Select__Option"
                  data-testid="DisplayNameMenuItem"
                >
                  <button className="ExpandOptions__Button">
                    {displayNameMessage}
                  </button>
                </styles.SelectOption>
                {isRFQEnabled &&
                  !isHAPOELAUTOBundleBySubscriptionCode(
                    editing.subscriptionCode
                  ) &&
                  // Is an onprem orch or a cloud orch
                  (editing.bundleCode === 'UIP' ||
                    editing.bundleCode === 'CRPA') && (
                  <styles.SelectOption
                    onClick={(e: any) =>
                      handleAddNewLicense(
                        editing.name,
                        editing.licenseId,
                        editing.bundleCode,
                        editing.displayName
                      )}
                    className="UserCard__Menu-Option Select__Option"
                    data-testid="AddNewLicenseMenuItem"
                  >
                    <button className="ExpandOptions__Button">
                      {t(
                        'company_licenses_context_menu_add_new_license',
                        'Add New License'
                      )}
                    </button>
                  </styles.SelectOption>
                )}
                <styles.SelectOption
                  onClick={handleNotesOpen}
                  className="UserCard__Menu-Option Select__Option"
                  data-testid="AddNotesMenuItem"
                >
                  <button className="ExpandOptions__Button">
                    {t('company_licenses_context_menu_add_notes', 'Add Notes')}
                  </button>
                </styles.SelectOption>
                {isEntityManagementEnabled && (
                  <styles.SelectOption
                    onClick={handleUpdateEntityOpen}
                    className="UserCard__Menu-Option Select__Option"
                    data-testid="UpdateEntityMenuItem"
                  >
                    <button className="ExpandOptions__Button">
                      {t('company_licenses_context_menu_update_entity', 'Update Entity')}
                    </button>
                  </styles.SelectOption>
                )}
              </styles.OptionsPopover>
            )}

            <div
              className={`${baseClass}__License-Table-Column-Title`}
              data-testid="LicenseTableHeaders"
            >
              {columnTitles
                .filter((title) => title.id !== 'entity-name' || isEntityManagementEnabled)
                .map((title, i) => (
                  <div
                    className={`${baseClass}__License-Column--${title.id}`}
                    key={i}
                  >
                    <p>{t(title.keyText, title.fallbackText)}</p>
                  </div>
                ))}
            </div>
            {generateLicenseBundles()}
            {totalResults > limit && (
              <Pagination
                activePage={pageNumber}
                handleClick={handlePaginateClick}
                numResults={totalResults}
                numPagesBeforeEllipses={5}
                numResultsPerPage={limit}
                className="CustomerPortalPagination--noLeftMargin"
              />
            )}
          </div>
        </div>
      ) : (
        <CompanyBodyUnauthorized />
      )}
    </>
  );
};

export default CompanyBodyLicenses;
